import axios from "axios";

const FetchFaqDetails = async (azureId) => {
  const Documentdata = new FormData();
  Documentdata.append("method", "faqs_data");
  const response = axios.post(
    process.env.REACT_APP_WORDPRESS_URL,
    Documentdata
  );
  return response;
};

export default {
  FetchFaqDetails,
};
