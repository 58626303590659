import {
  Calendar,
  Filter,
  LicenseMaintenanceDraft,
  Time,
  UpdateNow,
} from "@carbon/icons-react";
import {
  Pagination,
  Button,
  Column,
  DataTable,
  Row,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Dropdown,
  SkeletonPlaceholder,
} from "@carbon/react";
import useWindowSize from "hooks/useWindowSize";
import "./plainedmaintenance.css";

import { Planned_header, dropdown_items2 } from "Component/Data/DataJson";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { capitalizeString } from "utils";
import axiosInstance from "axiosintance";
import { GlobalContext } from "ContextApi/GlobalContext";
const activeRowStyles = {
  backgroundColor: "#e8e8e8", // Light grey background for the active row
  // border: "2px solid #0F62FE ",
};
const PlainedMaintanence = () => {
  const { width } = useWindowSize();
  const [maintenencedata, setmaintenencedata] = useState([]);
  const [activeServiceData, setActiveServiceData] = useState({});
  const [systemdata, setsystemdata] = useState("");
  const [serviceItems, setServiceItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedService, setSelectedService] = useState("");
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const context = useContext(GlobalContext);

  const handleRowClick = (rowIndex) => {
    setActiveRowIndex(rowIndex);
    setactive(rowIndex);
  };
  const getRowStyle = (rowIndex) => {
    return rowIndex === activeRowIndex ? { ...activeRowStyles } : {};
  };
  useEffect(() => {
    axiosInstance
      .get(process.env.REACT_APP_INCIDENTS_URL)
      .then((res) => {
        const finalData = [];
        const filterArray = res?.data?.filter((data) => {
          return data.impact == "maintenance";
        });
        filterArray.map((item, index) => {
          let itemNames = [];
          let incidentDataBody = item.incident_updates[0].body;
          item.components.map((itemNameItem) => {
            itemNames.push(itemNameItem.name);
          });
          finalData.push({
            ...item,
            components_name: itemNames.join(" , "),
            incidentDataBody,
          });
        });
        setmaintenencedata(finalData);
        // setactive();
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    axiosInstance.get(process.env.REACT_APP_COMPONENTS_URL).then((res) => {
      const items = [];
      res?.data?.map((item) => {
        items.push({
          id: item.name,
          label: item.name,
        });
      });
      setServiceItems(items, activeServiceData);
    });
  }, []);

  const setactive = (index = 0) => {
    setActiveServiceData(maintenencedata[index]);
  };
  const calculateMinutes = (start_time, end_time) => {
    let start_time_minutes = new Date(start_time).getTime();
    let end_time_minutes = new Date(end_time).getTime();

    return Math.round((end_time_minutes - start_time_minutes) / 60000);
  };

  useEffect(() => {
    setactive();
  }, [maintenencedata]);

  return (
    <>
      <div className="indicate ">
        <Row>
          <Column lg={16}>
            <div className="sub_heading my-5">
              <div className="indicate_icon ">
                <LicenseMaintenanceDraft size={24} />
              </div>
              <h5>Planned Maintenance</h5>
            </div>
          </Column>
        </Row>

        <Row>
          <Column lg={10}>
            <TableContainer
              className="dark:!border-[#474747]"
              style={{
                height: "100%",
                minHeight: "100%",
                boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <DataTable
                style={{ padding: "0" }}
                rows={maintenencedata.filter((item) => {
                  if (!systemdata || !selectedService) {
                    if (!systemdata && !selectedService) {
                      return true;
                    } else if (!systemdata) {
                      if (item.components_name.includes(selectedService)) {
                        return true;
                      }
                    } else if (!selectedService) {
                      if (systemdata == item.status) {
                        return true;
                      }
                    }
                  }

                  if (
                    systemdata == item.status &&
                    item.components_name.includes(selectedService)
                  ) {
                    return true;
                  }
                })}
                headers={Planned_header}
              >
                {({
                  rows,
                  headers,
                  getHeaderProps,
                  getRowProps,
                  getTableProps,
                  onInputChange,
                }) => (
                  <TableContainer
                    className="planned_table"
                    style={{
                      position: "relative",
                      minHeight: "100%",
                      paddingBottom: "40px",
                      background:
                        context?.globalTheme === "white" ? "#fff" : "#262626",
                    }}
                  >
                    <TableToolbar>
                      <TableToolbarContent
                        style={{
                          marginBottom: width <= 768 ? "48px" : "0",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: width <= 768 ? "column" : "row",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              width: width <= 768 ? "100%" : "50%",
                            }}
                          >
                            <TableToolbarSearch
                              onChange={onInputChange}
                              expanded
                              placeholder="Search input text"
                              style={{ width: "100%" }}
                              className="dark:!bg-[#161616]"
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: width <= 768 ? "100%" : "50%",
                            }}
                          >
                            <Dropdown
                              className="dark:!border-[#474747]"
                              style={{
                                width: "60%",
                                borderLeft: "1px solid #C6C6C6",
                              }}
                              size="lg"
                              items={dropdown_items2}
                              label="Status"
                              onChange={(e) => {
                                setsystemdata(e.selectedItem?.id);
                              }}
                            />
                            <Dropdown
                              className="dark:!border-[#474747]"
                              size="lg"
                              style={{
                                width: "40%",
                                borderLeft: "1px solid #C6C6C6",
                              }}
                              items={[
                                {
                                  id: "",
                                  label: "All",
                                },
                                ...serviceItems,
                              ]}
                              label="Filter"
                              onChange={(e) => {
                                setSelectedService(e.selectedItem?.id);
                              }}
                            />
                          </div>
                        </div>
                      </TableToolbarContent>
                    </TableToolbar>
                    <div>
                      <Table size="lg" {...getTableProps()}>
                        <TableHead size="lg">
                          <TableRow>
                            {headers.map((header) => (
                              <TableHeader
                                className="dark:!text-[#fff]"
                                key={header.key}
                                {...getHeaderProps({ header })}
                              >
                                {header.header}
                              </TableHeader>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, rowIndex) => (
                            <TableRow
                              key={row.id}
                              // className="!bg-[white] cursor-pointer"
                              className={
                                rowIndex === activeRowIndex
                                  ? "active"
                                  : "!bg-[white] cursor-pointer"
                              }
                              onClick={() => handleRowClick(rowIndex)}
                              // style={getRowStyle(rowIndex)}
                              {...getRowProps({ row })}
                            >
                              {row.cells.map((cell) => (
                                <TableCell
                                  // className="!bg-[transparent] dark:!text-white"
                                  className="p-3 !bg-white dark:!text-white "
                                  key={cell.id}
                                >
                                  {cell.info.header === "created_at" ? (
                                    moment(cell.value).format(
                                      "dddd, MMMM - Do - YYYY (hh:mm) A"
                                    )
                                  ) : cell.info.header === "status" ? (
                                    cell.value === "completed" ? (
                                      <span
                                        style={{
                                          color: "var(--cds-support-success)",
                                        }}
                                      >
                                        {capitalizeString(cell.value)}
                                      </span>
                                    ) : (
                                      capitalizeString(cell.value)
                                    )
                                  ) : (
                                    cell.value || <NotAvailable size={16} />
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "0",
                        width: "100%",
                      }}
                    >
                      <Pagination
                        pagesUnknown={true}
                        backwardText="Previous page"
                        forwardText="Next page"
                        itemsPerPageText=""
                        page={1}
                        pageSize={10}
                        pageNumberText="Page Number"
                        pageSizes={[10, 15]}
                        totalItems={0}
                        size="md"
                        onChange={() => {}}
                      />
                    </div>
                  </TableContainer>
                )}
              </DataTable>
            </TableContainer>
          </Column>

          <Column lg={6}>
            {!loading ? (
              <div
                className="service_heading dark:!text-white "
                style={{ marginTop: width <= 768 ? "3%" : 0 }}
              >
                <Row>
                  <Column lg={16}>
                    <div
                      className="mainpara_graph dark:!text-white"
                      style={{ margin: "10px 0 38px 0" }}
                    >
                      <h6 className="dark:!text-white">
                        {activeServiceData &&
                          activeServiceData?.incidentDataBody}
                      </h6>
                    </div>
                  </Column>
                  <Column lg={6}>
                    <div
                      className="single_status_page"
                      style={{
                        borderBottom:
                          width <= 479 ? "1px solid #e0e0e0" : "unset",
                        paddingBottom: width <= 479 ? "50px" : "0px",
                      }}
                    >
                      <ul>
                        <li>
                          <h6 className="dark:!text-white"> Service</h6>
                          {activeServiceData &&
                            activeServiceData?.components_name && (
                              <span className="dark:!text-white">
                                {activeServiceData?.components_name}{" "}
                              </span>
                            )}
                        </li>
                        <li>
                          <h6>Status</h6>
                          <span className="dark:!text-white">
                            {activeServiceData?.status}
                          </span>
                        </li>
                        <li>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <Time size={16} />
                            <h6>Duration</h6>
                          </div>
                          <span className="dark:!text-white">
                            {calculateMinutes(
                              activeServiceData?.scheduled_for,
                              activeServiceData?.scheduled_until
                            )}
                            &nbsp; minutes
                          </span>
                        </li>
                        <li>
                          <h6>ID</h6>
                          <span className="dark:!text-white">
                            {activeServiceData && activeServiceData?.id}{" "}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Column>

                  <Column lg={10}>
                    <div className="last_updates">
                      <ul>
                        <li>
                          <div className="update_icon">
                            <Calendar size={16} />
                            <h6>Start Time</h6>
                          </div>
                          <span className="dark:!text-white">
                            {activeServiceData &&
                              moment(activeServiceData?.scheduled_for).format(
                                "dddd, MMMM - Do - YYYY (hh:mm) A"
                              )}
                          </span>
                        </li>
                        <li>
                          <div className="update_icon">
                            <Calendar size={16} />
                            <h6>End Time</h6>
                          </div>
                          <span className="dark:!text-white">
                            {activeServiceData &&
                              moment(activeServiceData?.scheduled_until).format(
                                "dddd, MMMM - Do - YYYY (hh:mm) A"
                              )}
                          </span>
                        </li>
                        <li>
                          <div className="update_icon">
                            <UpdateNow size={16} />
                            <h6>Updates</h6>
                          </div>
                          {activeServiceData &&
                            !!activeServiceData?.incident_updates?.length &&
                            activeServiceData?.incident_updates
                              ?.reverse()
                              ?.map((item, index) => (
                                <>
                                  <p className="dark:!text-white">
                                    {index + 1 + ". "} {item?.body} Updated at -
                                    {item?.updated_at &&
                                      moment(item?.updated_at).format(
                                        "dddd, MMMM - Do - YYYY (hh:mm) A"
                                      )}
                                  </p>
                                  <br />
                                </>
                              ))}
                        </li>
                      </ul>
                    </div>
                  </Column>
                </Row>
              </div>
            ) : (
              <SkeletonPlaceholder style={{ width: "100%" }} />
            )}
          </Column>
        </Row>
      </div>
    </>
  );
};

export default PlainedMaintanence;
