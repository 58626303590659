import styled from "styled-components";
const GlobalSideNavWrapper = styled.div`
  .heading {
    display: flex;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin: 2rem 1rem 1rem 1rem;
    letter-spacing: 0.1599999964237213px;
    text-align: left;
    gap: 1rem;
    align-items: center;
  }
  a {
    overflow: hidden;
    color: var(--text-text-secondary, var(--text-text-secondary, #525252));
    text-overflow: ellipsis;
    white-space: nowrap;

    /* Fixed heading styles/heading-compact-01 */
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
  }

  .Dashboard_data {
    color: #fff;
    li.cds--side-nav__item.cds--side-nav__item--large {
      .sidenav_link {
        .cds--side-nav__icon {
          svg {
            fill: #fff;
          }
        }
      }
    }

    /* .Training {
      .cds--side-nav__submenu-title {
        color: #fff !important;
      }

      .cds--side-nav__menu {
        .cds--side-nav__item {
          .cds--side-nav__link {
            .cds--side-nav__link-text {
              color: #fff !important;
            }
          }
        }
      }
    } */

    .Profile_user {
      color: #fff;

      .cds--side-nav__submenu-title {
        color: #fff !important;
      }

      button.cds--side-nav__submenu {
        .cds--side-nav__menu .li.cds--side-nav__item,
        .cds--side-nav__icon {
          svg {
            fill: #fff;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .cds--side-nav__item.cds--side-nav__item--icon a.cds--side-nav__link {
      margin: 0;
      padding-inline-start: 3.5rem;
      block-size: 2rem;
    }
    .cds--switcher__item--divider {
      margin: 0.5rem 0rem;
      inline-size: 16rem;
      background-color: #474747;
    }
  }
`;
export default GlobalSideNavWrapper;
