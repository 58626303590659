import styled from "styled-components";
export const RequestTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  /* height: inherit; */

  &::-webkit-scrollbar {
    display: none;
  }

  .cds--pagination__left {
    padding: 0 !important;
    display: none;

    @media screen and (max-width: 768px) {
      display: flex;
    }
  }

  .cds--pagination__left > .cds--form-item,
  .cds--pagination__right > .cds--form-item {
    min-width: 195px;
  }

  .cds--data-table.cds--data-table--sort th > .cds--table-header-label {
    padding-inline-end: 0 !important;
  }

  .cds--data-table-content {
    overflow-x: hidden;
  }

  .no_data {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    /* align-items: center; */
    padding-top: 40px;
    padding-inline: 16px;

    .request_quote_div {
      width: 56px;
      height: 56px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4px;
      background-color: ${(props) =>
        props.theme.colorScheme === "white" ? "#f4f4f4" : "#161616"};
      svg {
        color: #a8a8a8;
      }
    }
    h6 {
      color: ${(props) =>
        props.theme.colorScheme == "white" ? "#a8a8a8" : "#525252"};

      font-family: "IBM Plex Sans";
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 35px; /* 159.091% */
    }

    span {
      color: ${(props) =>
        props.theme.colorScheme == "white" ? "#d1d1d1" : "#525252"};

      /* Fixed heading styles/heading-reg-07 */
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
    }
  }

  .top-row {
    .support-tab-content {
      margin: 32px 16px;
      border: 1px solid #e0e0e0;
      border: ${(props) =>
        props.theme.colorScheme == "white"
          ? "1px solid #e0e0e0"
          : "1px solid #474747"};

      tbody {
        &::-webkit-scrollbar {
          display: none;
        }

        @-moz-document url-prefix() {
          overflow: auto;
          -ms-overflow-style: none; /* IE 11 */
          scrollbar-width: none !important;
        }

        tr {
          background-color: #fff;
          height: 56px;
          /* &:nth-child(10) {
            height: 25px;
          } */
          td {
            max-width: 124px;
          }
        }
      }

      .table_row:hover {
        border: 2px solid #2570fe;
        background-color: ${(props) =>
          props.theme.colorScheme == "white" ? "#e0e0e0" : "#161616"};
        cursor: pointer;
      }

      .table_column:hover {
        border-top: 2px solid #2570fe;
        border-bottom: 2px solid #2570fe;
      }

      .pagination {
        /* position: sticky; */
        /* bottom: 38px; */
        /* border: 0.5px solid #e0e0e0; */
        /* overflow: hidden; */
      }
    }

    .request-header {
      padding: 0.5rem 1rem;

      h3 {
        color: var(--text-text-primary, var(--text-text-primary, #161616));
        font-family: IBM Plex Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }
    }
    .request-status-row {
      display: flex;
      justify-content: space-between;
      padding: 8px 1rem;

      @media screen and (max-width: 768px) {
        padding: 0rem 1rem 1rem 1rem;
      }

      .active {
        border: 1px solid var(--border-border-subtle-01, #257afe);
      }

      gap: 0.25rem;

      .box {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        width: 49%;
        gap: 0.25rem;
        /* border: 1px solid var(--border-border-subtle-01, #c6c6c6); */
        padding-inline: 16px;
        background: ${(props) =>
          props.theme.colorScheme == "white" ? "#fff" : "#262626"};
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);

        &.active {
          border: 2px solid var(--border-border-subtle-01, #257afe);
        }

        span {
          color: var(--Text-text-primary, #161616);
          font-family: "IBM Plex Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.16px;
          b {
            /* Fixed heading styles/heading-semi-07 */
            color: var(--Text-text-primary, #161616);
            font-family: "IBM Plex Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 128.571% */
            letter-spacing: 0.16px;
          }
        }
      }
    }
  }
  .bottom-row {
    width: 100%;
    position: absolute;
    padding: 1rem;
    bottom: 0;
    left: 0;
  }
`;

export const RequestCompleteWrapper = styled.div`
  padding-top: 24px;
  .header_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100%;
    svg {
      fill: #2570fe;
    }

    h4 {
      color: ${(props) =>
        props.theme.colorScheme == "white" ? "#161616" : "white"};
      font-family: "IBM Plex Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      padding-bottom: 8px;
    }
  }

  .sub_heading {
    /* padding-inline: 34px; */
    h4 {
      color: var(--Text-text-secondary, #525252);
      /* color: ${(props) =>
        props.theme.colorScheme == "white" ? "#525252" : " #474747"}; */
      text-align: center;
      font-family: Arial;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.16px;
    }

    .font-semibold {
      color: var(--Text-text-secondary, #525252);
      /* color: ${(props) =>
        props.theme.colorScheme == "white" ? "#525252" : " #474747"}; */
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.014px;
    }
  }
`;
