export const intitialState = {
  userinfo: {},
  MsAccessToken: "",
  siginlogs: "",
  TablePagination: {
    totalrow: "",
    page: 1,
    per_page: 8,
    widget_page: 1,
    widget_per_page: 9,
  },
  Alldata: [
    {
      id: "",
      ticket: "",
      date: "",
      subject: "",
      status: "",
      datesubject: "",
    },
  ],
  AlldataWidget: [
    {
      id: "",
      datesubject: "",
    },
  ],
  GlobalState: {
    loading: true,
    onInputChange: "",
    openCount: "",
    closedCount: "",
    pendingCount: "",
    newCount: "",
    fetchopen: "",
    fetchclosed: "",
    fetchnew: "",
    fetchpending: "",
  },
  Fillter: {
    applyFilter: false,
    startDate: "",
    endDate: "",
    search: "",
  },
  notificationuser: [],
  UserallNotification: "",
  photo: null,
  base64Prefix: "data:image/png;base64,",
  fetchdata: {},
  contactinfodata: {},
  contactsalesforce: {},
  socketdata: "",
  Getstatus: null,
  isLoading: false,
  ticket_id: null,
  ticketData: {},
};
export const datareducer = (state = intitialState, { type, payload }) => {
  switch (type) {
    case "USER_SIGNIN_LOGS":
      return { ...state, userinfo: { ...state.userinfo, ...payload } };
    case "USER_ACCESSTOKEN":
      return { ...state, MsAccessToken: payload };
    case "SIGN_IN_LOGS":
      return { ...state, siginlogs: payload };
    case "TABLE_PAGINATION":
      return {
        ...state,
        TablePagination: { ...state.TablePagination, ...payload },
      };
    case "ALL_DATA":
      return { ...state, Alldata: payload };
    case "ALL_DATA_WIDTH":
      return { ...state, AlldataWidget: payload };
    case "GLOBAL_STATE":
      return {
        ...state,
        GlobalState: { ...state.GlobalState, ...payload },
      };
    case "FILLTER_DATA":
      return { ...state, Fillter: { ...state.Fillter, ...payload } };
    case "USER_ALL_NOTIFICATIONAPI":
      return { ...state, notificationuser: payload };
    case "USER_All_NOTIFICATION":
      return { ...state, UserallNotification: payload };

    case "USER_SALESFORCE_DATA":
      return {
        ...state,
        fetchdata: payload,
      };
    case "GET_CONTACTINFO":
      return { ...state, contactinfodata: payload };
    case "GET_SALESFORCEINFO":
      return {
        ...state,
        contactsalesforce: { ...state.contactsalesforce, ...payload },
      };
    case "GET_USERIMAGE":
      return { ...state, photo: payload };
    case "GET_BASE64PREFIX":
      return { ...state, base64Prefix: payload };
    case "SOCKETDATA_SINGEL_SIGNOUT":
      return { ...state, socketdata: payload };
    case "USER_SYSTEM_NOTIFICATION_GETSTATUS":
      return { ...state, Getstatus: payload };
    case "USERS_ISLOADING":
      return { ...state, isLoading: payload };
    case "USERS_ISLOADING_SETTICKET":
      return { ...state, ticket_id: payload };
    case "TICKET_DATA_AFTER_SUCCESS":
      return { ...state, ticketData: payload };
    default:
      return state;
  }
};
