import React from "react";
import { Loading } from "@carbon/react";

/**
 * @param {{ visible: boolean }} props
 * @returns JSX.Element
 */
function LoadingOverlay({ visible }) {
  if (!visible) return null;

  return (
    <div className="absolute top-0 left-0 flex justify-center items-center w-full h-full">
      <Loading />
    </div>
  );
}

export default LoadingOverlay;
