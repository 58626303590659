import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import axios from "axios";
import axiosInstance from "axiosintance";
import { getErrorMessageAndCode } from "getErrorFromResponse";

export const setTicketinfo = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_BACKENDURL_SUPPORT}/ticket_detatil_show`,
      {
        ticketId: payload,
      }
    );

    return {
      id: response?.data?.ticket?.id,
      subject: response?.data?.ticket?.subject,
      time: response?.data?.ticket?.created_at,
      status: response?.data?.ticket?.status,
      requester_id: response?.data?.ticket?.requester_id,
      assignee_id: response?.data?.ticket?.assignee_id,
      name: response?.data?.ticket?.custom_fields[1].value,
      email: response?.data?.ticket?.custom_fields[10].value,
      phone: response?.data?.ticket?.custom_fields[3].value,
      service: response?.data.ticket?.custom_fields[5].value,
      serverity: response?.data?.ticket?.custom_fields[9].value,
      timeZone: response?.data?.ticket?.custom_fields[6].value,
      casetype:
        response?.data?.ticket?.ticket_form_id == "360001970193"
          ? "Technical Problem"
          : " Custom Problem",
    };
  } catch (err) {
    let error = getErrorMessageAndCode(err);
    throw error;
  }
};
export const setTicketattachment = async (payload, updateProgress) => {
  try {
    const file = payload.target.files[0];

    const response = await axios.post(
      `${process.env.REACT_APP_ZENDESKURL}/api/v2/uploads?filename=${file.name}`,
      file,
      {
        headers: {
          Authorization: `Basic ${btoa(
            `${process.env.REACT_APP_USERINFO}:${process.env.REACT_APP_PASSWORDINFO}`
          )}`,
          "Content-Type": "application/binary",
        },
        onUploadProgress: (progress) => {
          const { loaded, total } = progress;
          const percentageProgress = Math.floor((loaded / total) * 99);
          updateProgress(percentageProgress);
        },
      }
    );

    return response.data.upload.token;
  } catch (err) {
    console.log(err);
    let error = getErrorMessageAndCode(err);
    throw error;
  }
};
export const Updatecommentdata = async (payload) => {
  try {
    const body1 = {
      ticket: {
        comment: {
          body: payload?.values?.messages,
          uploads: [`${payload?.Ticketattachment.data}`],
          author_id: payload?.Ticketinfo?.data?.requester_id,
        },
      },
    };
    const updatebodydata = new FormData();
    updatebodydata.append("body1", JSON.stringify(body1));
    updatebodydata.append("ticketId", payload?.ticketId);

    const response = await axiosInstance.post(
      `${process.env.REACT_APP_BACKENDURL_SUPPORT}/ticket_image`,
      updatebodydata
    );

    return response.data.ticket.updated_at;
  } catch (err) {
    console.log(err);
    let error = getErrorMessageAndCode(err);
    throw error;
  }
};
