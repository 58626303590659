import { Forum } from "@carbon/icons-react";
import {
  SideNav,
  SideNavDivider,
  SideNavItems,
  SideNavLink,
  SideNavMenu,
  SideNavMenuItem,
  OrderedList,
  ListItem,
  UnorderedList,
} from "@carbon/react";
import SwitcherTab from "components/header/tabs/switcher";
import useWindowSize from "hooks/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  handleMobileSideNav,
  headerState,
  reset as resetHeaderState,
} from "redux/slices/headerSlice";
import { sideNavRoutes } from "utils/routes/sideNavRoutes";
import SideNavWrapper from "./styles";
import { useEffect, useRef, useState } from "react";

const SideNavBar = () => {
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const { switcher, sideNav, mobileNav } = useSelector(headerState);
  const navigate = useNavigate();
  const [activeRowIndex, setActiveRowIndex] = useState(null);

  function resetAndNavigate(path) {
    navigate(path);
    dispatch(resetHeaderState());
  }

  const activeRowStyles = {
    backgroundColor: "#e8e8e8", // Light grey background for the active row
    borderLeft: "2px solid #0F62FE ",
  };

  const getListStles = (rowIndex) => {
    return rowIndex === activeRowIndex ? { ...activeRowStyles } : {};
  };

  const TrainingRoutes = [
    {
      linkNames: "Training For Admins",
      path: "/training/Training-For-admins",
    },
    {
      linkNames: "Knowledge Base",
      path: "/training/knowledge-base",
    },
    {
      linkNames: "FAQ & Guides",
      path: "/training/Faq-Guide",
    },
  ];

  return width > 1024 ? (
    <SideNav
      className="dark:!bg-[#262626]"
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      }}
      aria-label="Side navigation"
      expanded={true}
      isFixedNav={true}
      isChildOfHeader={true}
      isRail
    >
      <SideNavWrapper className="dark:!text-[#fff] ">
        <SideNavItems className="dark:!text-[#fff] ">
          {sideNavRoutes.map((link) => {
            if (link.name !== "Support Dashboard") {
              return (
                <>
                  {link.name !== "Training & Guides" ? (
                    <SideNavLink
                      className="dark:!text-[#fff]"
                      isActive={pathname === link.path}
                      key={link.id}
                      as={Link}
                      to={link.path}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        resetAndNavigate(link.path);
                        setActiveRowIndex(null);
                      }}
                    >
                      {link.name}
                    </SideNavLink>
                  ) : (
                    <SideNavMenu title="Training & Guides">
                      <SideNavLink
                        as={Link}
                        to="/training/Training-For-admins"
                        isActive={pathname == "/training/Training-For-admins"}
                      >
                        Training For Admins
                      </SideNavLink>
                      <SideNavLink
                        as={Link}
                        to="/training/knowledge-base"
                        isActive={pathname == "/training/knowledge-base"}
                      >
                        Knowledge base
                      </SideNavLink>
                      <SideNavLink
                        as={Link}
                        to="/training/Faq-Guide"
                        isActive={pathname == "/training/Faq-Guide"}
                      >
                        FAQ & Guides
                      </SideNavLink>
                    </SideNavMenu>
                  )}
                </>
              );
            } else {
              return (
                <SideNavLink
                  className="dark:!text-[#fff]"
                  isActive={pathname === link.path}
                  key={link.id}
                  as={Link}
                  to={link.path}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    resetAndNavigate(link.path);
                    setActiveRowIndex(null);
                  }}
                  style={{
                    borderBottom: "1px solid #8d8d8d33",
                  }}
                >
                  <div className="heading dark:!text-white ">
                    <Forum size={24} />{" "}
                    <Link to="/">
                      <span className="dark:!text-white">Support Center</span>
                    </Link>
                  </div>
                </SideNavLink>
              );
            }
          })}
        </SideNavItems>
      </SideNavWrapper>
    </SideNav>
  ) : (
    <SideNav
      className="dark:!bg-[#262626]"
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      }}
      aria-label="Side navigation"
      expanded={mobileNav}
      isPersistent={true}
      isFixedNav={false}
      isChildOfHeader={true}
      onOverlayClick={() => dispatch(handleMobileSideNav())}
    >
      <SideNavWrapper className="dark:!text-[#fff] ">
        <SideNavItems className="dark:!text-[#fff] ">
          {sideNavRoutes.map((link) => {
            if (link.name !== "Support Dashboard") {
              return (
                <>
                  {link.name !== "Training & Guides" ? (
                    <SideNavLink
                      className="dark:!text-[#fff]"
                      isActive={pathname === link.path}
                      key={link.id}
                      as={Link}
                      to={link.path}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        resetAndNavigate(link.path);
                        setActiveRowIndex(null);
                      }}
                    >
                      {link.name}
                    </SideNavLink>
                  ) : (
                    <SideNavMenu title="Training & Guides">
                      <SideNavLink
                        as={Link}
                        to="/training/Training-For-admins"
                        isActive={pathname == "/training/Training-For-admins"}
                      >
                        Training For Admins
                      </SideNavLink>
                      <SideNavLink
                        as={Link}
                        to="/training/knowledge-base"
                        isActive={pathname == "/training/knowledge-base"}
                      >
                        Knowledge base
                      </SideNavLink>
                      <SideNavLink
                        as={Link}
                        to="/training/Faq-Guide"
                        isActive={pathname == "/training/Faq-Guide"}
                      >
                        FAQ & Guides
                      </SideNavLink>
                    </SideNavMenu>
                  )}
                </>
              );
            } else {
              return (
                <SideNavLink
                  className="dark:!text-[#fff]"
                  isActive={pathname === link.path}
                  key={link.id}
                  as={Link}
                  to={link.path}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    resetAndNavigate(link.path);
                    setActiveRowIndex(null);
                  }}
                  style={{
                    borderBottom: "1px solid #8d8d8d33",
                  }}
                >
                  <div className="heading dark:!text-white ">
                    <Forum size={24} />{" "}
                    <Link to="/">
                      <span className="dark:!text-white">Support Center</span>
                    </Link>
                  </div>
                </SideNavLink>
              );
            }
          })}
        </SideNavItems>
      </SideNavWrapper>
    </SideNav>
  );

  // return width >= 1024 && pathname !== "/training" ? (
  //   <SideNav
  //     className="dark:!bg-[#262626]"
  //     style={{
  //       boxShadow:
  //         "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  //     }}
  //     aria-label="Side navigation"
  //     expanded={true}
  //     isFixedNav={true}
  //     isChildOfHeader={true}
  //     isRail
  //   >
  //     <SideNavWrapper className="dark:!text-[#fff] ">
  //       <SideNavItems className="dark:!text-[#fff] ">
  //         {sideNavRoutes.map((link) => {
  //           if (link.name !== "Support Dashboard") {
  //             return (
  //               <>
  //                 {/* {link.name !== "Training & Guides" ? ( */}
  //                 <SideNavLink
  //                   className="dark:!text-[#fff]"
  //                   isActive={pathname === link.path}
  //                   key={link.id}
  //                   as={Link}
  //                   to={link.path}
  //                   onClick={(e) => {
  //                     e.preventDefault();
  //                     e.stopPropagation();
  //                     resetAndNavigate(link.path);
  //                     setActiveRowIndex(null);
  //                   }}
  //                 >
  //                   {link.name}
  //                 </SideNavLink>
  //                 {/* )  */}
  //                 {/* // : (
  //                 //   <SideNavMenu title="Training & Guides">
  //                 //     <SideNavLink as={Link} to="/training/Training-For-admins">
  //                 //       Training For Admins
  //                 //     </SideNavLink>
  //                 //     <SideNavLink as={Link} to="/training/knowledge-base">
  //                 //       Knowledge base
  //                 //     </SideNavLink>
  //                 //     <SideNavLink as={Link} to="/training/Faq-Guide">
  //                 //       FAQ & Guides
  //                 //     </SideNavLink>
  //                 //   </SideNavMenu>
  //                 // ) */}
  //                 {/* } */}
  //               </>
  //             );
  //           } else {
  //             return (
  //               <SideNavLink
  //                 className="dark:!text-[#fff]"
  //                 isActive={pathname === link.path}
  //                 key={link.id}
  //                 as={Link}
  //                 to={link.path}
  //                 onClick={(e) => {
  //                   e.preventDefault();
  //                   e.stopPropagation();
  //                   resetAndNavigate(link.path);
  //                   setActiveRowIndex(null);
  //                 }}
  //                 style={{
  //                   borderBottom: "1px solid #8d8d8d33",
  //                 }}
  //               >
  //                 <div className="heading dark:!text-white ">
  //                   <Forum size={24} />{" "}
  //                   <Link to="/">
  //                     <span className="dark:!text-white">Support Center</span>
  //                   </Link>
  //                 </div>
  //               </SideNavLink>
  //             );
  //           }
  //         })}
  //         {/* <OrderedList className="ordered_list_sidenav">
  //           <ListItem className="ordered_listItem">
  //             <Link
  //               to="/training"
  //               className="order_link"
  //               onClick={() => setActiveRowIndex(null)}
  //             >
  //               Training & Guides
  //             </Link>
  //             <OrderedList nested className="order_nested_link">
  //               <ListItem
  //                 style={getListStles(1)}
  //                 onClick={() => setActiveRowIndex(1)}
  //               >
  //                 <Link to="/training/Training-For-admins">
  //                   Training For Admins
  //                 </Link>
  //               </ListItem>
  //               <ListItem
  //                 style={getListStles(2)}
  //                 onClick={() => setActiveRowIndex(2)}
  //               >
  //                 {" "}
  //                 <Link to="/training/Training-For-admins">Knowledge Base</Link>
  //               </ListItem>
  //               <ListItem
  //                 style={getListStles(3)}
  //                 onClick={() => setActiveRowIndex(3)}
  //               >
  //                 {" "}
  //                 <Link to="/training/Faq-Guide">FAQ & Guides</Link>
  //               </ListItem>
  //             </OrderedList>
  //           </ListItem>
  //         </OrderedList> */}
  //       </SideNavItems>
  //     </SideNavWrapper>
  //   </SideNav>
  // ) : width >= 1024 && pathname === "/training" ? (
  //   <SideNav
  //     className="dark:!bg-[#262626]"
  //     style={{
  //       boxShadow:
  //         "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  //     }}
  //     aria-label="Side navigation"
  //     expanded={true}
  //     isFixedNav={true}
  //     isChildOfHeader={true}
  //     isRail
  //   >
  //     <SideNavWrapper className="dark:!text-[#fff] ">
  //       <SideNavItems className="dark:!text-[#fff] ">
  //         <div
  //           className="heading dark:!text-white "
  //           style={{
  //             borderBottom: "1px solid #8d8d8d33",
  //             padding: "24px 16px 16px 16px",
  //           }}
  //         >
  //           <Forum size={24} />{" "}
  //           <Link to="#">
  //             <span className="dark:!text-white">Training & Guides</span>
  //           </Link>
  //         </div>
  //         {/* <SideNavMenu title="Training & Guides"> */}
  //         {TrainingRoutes.map((link) => {
  //           return (
  //             <SideNavLink
  //               className="dark:!text-[#fff]"
  //               isActive={pathname === link.path}
  //               key={link.id}
  //               as={Link}
  //               to={link.path}
  //               onClick={(e) => {
  //                 e.preventDefault();
  //                 e.stopPropagation();
  //                 resetAndNavigate(link.path);
  //                 setActiveRowIndex(null);
  //               }}
  //             >
  //               {link.linkNames}
  //             </SideNavLink>
  //           );
  //         })}
  //         {/* </SideNavMenu> */}
  //       </SideNavItems>
  //       {/* <SideNavDivider /> */}
  //       <SideNavItems className="dark:!text-[#fff] ">
  //         {/* <div className="heading dark:!text-white ">
  //           <Forum size={24} />{" "}
  //           <Link to="/">
  //             <span className="dark:!text-white">Support Center</span>
  //           </Link>
  //         </div> */}
  //         <SideNavMenu title="Support Center">
  //           {sideNavRoutes.map((link) => {
  //             if (link.name !== "Training & Guides") {
  //               return (
  //                 <SideNavLink
  //                   className="dark:!text-[#fff]"
  //                   isActive={pathname === link.path}
  //                   key={link.id}
  //                   as={Link}
  //                   to={link.path}
  //                   onClick={(e) => {
  //                     e.preventDefault();
  //                     e.stopPropagation();
  //                     resetAndNavigate(link.path);
  //                     setActiveRowIndex(null);
  //                   }}
  //                 >
  //                   {link.name}
  //                 </SideNavLink>
  //               );
  //             }
  //           })}
  //         </SideNavMenu>
  //       </SideNavItems>
  //     </SideNavWrapper>
  //   </SideNav>
  // ) : (
  //   <div>
  //     <SideNav
  //       className="dark:!text-[#fff]"
  //       style={{
  //         boxShadow:
  //           "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  //       }}
  //       isPersistent={false}
  //       aria-label="Side navigation"
  //       expanded={sideNav}
  //       isFixedNav={false}
  //       isChildOfHeader={true}
  //       onOverlayClick={() => dispatch(resetHeaderState())}
  //     >
  //       {sideNav && (
  //         <SideNavWrapper>
  //           <SideNavItems>
  //             {sideNavRoutes.map((link) => (
  //               <SideNavLink
  //                 isActive={pathname === link.path}
  //                 key={link.id}
  //                 // href={link.path}
  //                 to={link.path}
  //                 as={Link}
  //                 onClick={(e) => {
  //                   e.preventDefault();
  //                   e.stopPropagation();
  //                   resetAndNavigate(link.path);
  //                 }}
  //               >
  //                 {link.name}
  //               </SideNavLink>
  //             ))}
  //           </SideNavItems>
  //         </SideNavWrapper>
  //       )}
  //     </SideNav>
  //   </div>
  // );
};

export default SideNavBar;
