import { GlobalTheme, Theme } from "@carbon/react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const ThemeContext = createContext([]);

export function useTheme() {
  return useContext(ThemeContext);
}

export const ThemeProvider = (props) => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") ?? "light");

  // useEffect(() => {
  //   const isDark = window.matchMedia("(prefers-color-scheme: dark)");

  //   if (isDark.matches && !localStorage.getItem("theme")) {
  //     setTheme("dark");
  //   }
  // }, []);

  // useEffect(() => {
  //   const html = document.querySelector("html");

  //   html.classList.remove(theme === "dark" ? "light" : "dark");
  //   html.classList.add(theme);

  //   localStorage.setItem("theme", theme);
  // }, [theme]);

  const carbonTheme = "light";

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <GlobalTheme theme={carbonTheme}>
        <Theme theme={carbonTheme}>{props.children}</Theme>
      </GlobalTheme>
    </ThemeContext.Provider>
  );
};
