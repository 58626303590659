import styled from "styled-components";
const ChatTabWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  max-height: calc(100vh - 200px);
  color: #0f62fe;

  .message.belongsToCurrentUser {
    background-color: #474747;
  }

  .message-input-container div[data-paste-element="MESSAGE_INPUT_BOX"] {
    border: 1px solid transparent;
    border-bottom-color: #8d8d8d;
    background: ${(props) => (props.isDarkTheme ? "#393939" : "#f4f4f4")};
    border-radius: 0;

    textarea {
      background: ${(props) =>
        props.isDarkTheme ? "#393939" : "#f4f4f4"} !important;

      &::placeholder {
        color: ${(props) => (props.isDarkTheme ? "#fff" : "#000")};
        opacity: 0.5;
      }
    }

    &:hover {
      box-shadow: none;
    }

    &:focus-within {
      border: 1px solid #0f62fe;
      box-shadow: none;
    }
  }
`;

export default ChatTabWrapper;
