import React from "react";
import ChatTabWrapper from "./style";
import { useContext, useMemo } from "react";
import { GlobalContext } from "ContextApi/GlobalContext";
import WebchatWidget from "components/chat/WebchatWidget";

function ChatTab() {
  const globalContext = useContext(GlobalContext);

  const isDarkTheme = useMemo(
    () => globalContext.globalTheme === "g100",
    [globalContext.globalTheme]
  );

  return (
    <ChatTabWrapper isDarkTheme={isDarkTheme}>
      <WebchatWidget />
    </ChatTabWrapper>
  );
}

export default ChatTab;
