import { Dashboard, Forum } from "@carbon/icons-react";
import {
  SideNav,
  SideNavItems,
  SideNavLink,
  SideNavMenu,
  SwitcherDivider,
} from "@carbon/react";
import { Link, useLocation } from "react-router-dom";
import { GlobalsideNavRoutes, sideNavRoutes } from "utils/routes/sideNavRoutes";
import useWindowSize from "../../../hooks/useWindowSize";
import GlobalSideNavWrapper from "./style";
import { useSelector } from "react-redux";
import { headerState } from "redux/slices/headerSlice";
import { privateRoutes } from "utils/routes/privateRoutes";

const GlobalSideNav = () => {
  const { width } = useWindowSize();
  const location = useLocation();
  const { globalMenu, switcher, sideNav } = useSelector(headerState);
  const currentRoute = privateRoutes.find(
    (route) => route.path === location.pathname
  );

  return (
    width > 1024 && (
      <>
        <SideNav
          isPersistent={width > 1024}
          aria-label="Side navigation"
          isRail
          style={{ backgroundColor: "#000", color: "#fff" }}
          expanded={
            (currentRoute?.path == "/" ||
              currentRoute?.path == "/TicketCreate" ||
              currentRoute?.path == "/notification" ||
              location?.pathname ==
                `/ticketdetails/${
                  location?.pathname.split("/ticketdetails/")[1]
                }`) &&
            sideNav
          }
        >
          <GlobalSideNavWrapper>
            <SideNavItems className="Dashboard_data">
              <SideNavLink
                className="sidenav_link"
                aria-current={location.pathname == "/" ? "page" : "false"}
                as={Link}
                to="/"
                renderIcon={Dashboard}
                large
              >
                <span className="text-white"> Dashboard</span>
              </SideNavLink>

              <SideNavMenu
                aria-current={location.pathname == "/" ? "page" : "false"}
                renderIcon={Forum}
                title="Support Center"
                className="!text-white fill-white Profile_user"
                as={Link}
                to="/account"
                large
              >
                {GlobalsideNavRoutes.map((privateRouteDetail) => {
                  if (privateRouteDetail.name !== "Support Dashboard") {
                    return (
                      <>
                        <SideNavLink
                          className="text-white"
                          isActive={
                            privateRouteDetail.path === location.pathname
                          }
                          key={privateRouteDetail.id}
                          element={Link}
                          to={privateRouteDetail.path}
                          // renderIcon={Dashboard}
                        >
                          <span className="text-white">
                            {privateRouteDetail.name}
                          </span>
                        </SideNavLink>
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
              </SideNavMenu>
            </SideNavItems>
          </GlobalSideNavWrapper>
        </SideNav>
      </>
    )
  );
};

export default GlobalSideNav;
