import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "authConfig";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "redux/store";
import App from "./App";
import "swiper/css";
import { GlobalProvider } from "ContextApi/GlobalContext";
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <GlobalProvider>
          <App />
        </GlobalProvider>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);
