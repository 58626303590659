import styled from "styled-components";
const FullScreenModalWrapper = styled.div`
  .cds--search {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "white" : "#161616"} !important;
  }

  .no_data {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 87px 0px 88px 120px;
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "white" : "#161616"};

    .request_quote_div {
      border-radius: 100%;
      display: flex;

      svg {
        color: #a8a8a8;
      }
    }
    h6 {
      color: ${(props) =>
        props.theme.colorScheme == "white" ? "#a8a8a8" : "#525252"};

      font-family: "IBM Plex Sans";
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 35px; /* 159.091% */
    }

    span {
      color: ${(props) =>
        props.theme.colorScheme == "white" ? "#d1d1d1" : "#525252"};

      /* Fixed heading styles/heading-reg-07 */
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
    }
  }

  .support-tab-content {
    border: 1px solid #e0e0e0;
    margin-top: 16px;

    .table_row {
      background-color: #fff;
    }

    .table_row:hover {
      border: 2px solid #2570fe;
      background-color: #e0e0e0;
      cursor: pointer;
    }

    .table_column:hover {
      border-top: 2px solid #2570fe;
      border-bottom: 2px solid #2570fe;
    }
  }
  min-height: 100%;
  .fullscree_modaldata {
    .cds--modal-container {
      width: 100%;
      height: 100%;
      max-height: 100%;

      .cds--side-nav__items {
        border-right: ${(props) =>
          props.theme.colorScheme == "white"
            ? "1px solid #d1d1d1"
            : "1px solid #474747"};
        background: ${(props) =>
          props.theme.colorScheme == "white" ? "#f4f4f4" : "#161616"};
        box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.05);
        width: 256px;
      }

      .cds--modal-header,
      .cds--modal-footer {
        display: none;
      }
      .cds--tab--list[aria-label="Support tabs"] {
        flex-direction: column;
        width: 100%;
        height: 100%;

        .cds--tabs__nav-item {
          padding: 1rem;
          border-bottom: 0;
          border-left: 4px solid transparent;
          &:hover {
            border-bottom: 0 !important;
          }
          .cds--tabs__nav-item-label-wrapper {
            gap: 1rem;
            width: 100%;
            span {
              font-family: IBM Plex Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 22px;
            }
          }
        }
        .cds--tabs__nav-item--selected {
          border-left: ${(props) =>
            props.theme.colorScheme == "white"
              ? "4px solid var(--background-background-brand, #2570fe)"
              : "4px solid var(--background-background-brand, #75A6fe)"} !important;
          background-color: #8d8d8d33;

          .cds--tabs__nav-item-label-wrapper {
            span {
              color: ${(props) =>
                props.theme.colorScheme == "white" ? "#2570fe" : "#75A6fe"};
            }
          }
        }
      }
      .cds--tab--overflow-nav-button {
        display: none;
      }
      .support-modal-content {
        height: 100%;
        background-color: ${(props) =>
          props.theme.colorScheme == "white" ? "#f4f4f4" : "#161616"};
        overflow: auto;

        .button-box {
          display: flex;
          justify-content: end;
          background-color: ${(props) =>
            props.theme.colorScheme == "white" ? "#f4f4f4" : "#161616"};
          position: sticky;
          top: -32px;
          z-index: 50;

          button {
            display: flex !important;
          }
        }
        .create-ticket-desktop-form {
          h3 {
            font-family: IBM Plex Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 0.5rem;
          }
          p {
            color: var(--accessibility-black, #000);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 138.462% */
            letter-spacing: -0.013px;
          }
          .support-type-tile-group {
            display: flex;
            margin: 1.5rem 0;
            div {
              width: 100%;
              &:only-child {
                display: flex;
                gap: 10px;

                div {
                  width: calc(100% / 2);
                  .cds--tile {
                    border: 1px solid var(--border-subtle, #e0e0e0);
                    .cds--tile__checkmark {
                      top: 50%;
                      transform: translate(0, -50%);
                    }
                  }
                }
              }
            }
            label {
              display: flex;
              align-items: center;
            }
          }
          .service {
            padding: 1rem 0 1.5rem 0;
            .service-inputs {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              margin-top: 1rem;
            }
          }
          .subject {
            padding: 1rem 0 1.5rem 0;
            .subject-inputs {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              margin-top: 1rem;
            }
          }
          .contact {
            padding: 1rem 0 1.5rem 0;
            .contact-inputs {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              margin-top: 1rem;
              .contact-tile-group {
                display: flex;
                div {
                  width: 100%;
                  &:only-child {
                    display: flex;
                    gap: 10px;

                    div {
                      width: calc(100% / 3);
                      .cds--tile {
                        border: 1px solid var(--border-subtle, #e0e0e0);
                        .cds--tile__checkmark {
                          top: 50%;
                          transform: translate(0, -50%);
                        }
                      }
                    }
                  }
                }
                label {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
          .attachments {
            padding: 1rem 0 1.5rem 0;
            .attachments-insider {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              margin-top: 1rem;
              .cds--file-browse-btn {
                width: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
      .side-panel-heading {
        padding: 0.5rem 1rem 1.5rem 1rem;
        color: var(--text-text-primary, var(--text-text-primary, #161616));
        font-family: IBM Plex Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 140% */
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
          display: flex;
          width: 24px;
          height: 24px;
          max-width: 24px;
        }
      }

      //Slink Css
      .scroll-spy-side-nav-col {
        padding-left: 0;
        @media screen and (max-width: 1055px) {
          display: none;
        }
        .scroll-spy-link-box {
          display: flex;
          flex-direction: column;
          position: fixed;
          .spy-box-content {
            border-left: 2px solid var(--border-border-subtle-01, #c6c6c6);
            display: flex;
            padding: 7px 16px;
            align-items: center;
            gap: 24px;
            align-self: stretch;
            overflow: hidden;
            color: var(
              --text-text-secondary,
              var(--text-text-secondary, #525252)
            );
            text-overflow: ellipsis;
            white-space: nowrap;
            /* Fixed heading styles/heading-compact-01 */
            font-family: IBM Plex Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 128.571% */
            letter-spacing: 0.16px;
          }
          a {
            color: #2570fe;
          }
        }
      }
      //Slink Css
    }
    .active {
      .spy-box-content {
        border-left-color: #2570fe;
      }
    }
    .cds--text-input,
    .cds--text-area,
    .cds--search-input,
    .cds--pagination,
    .cds--pagination__control-buttons,
    .cds--select-input {
      background-color: var(--cds-field);
    }
    .request-tab {
      background-color: white;
      .table-toolbar {
        display: flex;
        margin-top: 1.5rem;
        .status-selector {
          min-width: 250px;
        }
      }
    }
  }
  .support_content {
    p {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.013px;
    }
    h4 {
      font-family: IBM Plex Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .chose_number {
    .country_code {
      display: flex;
      align-items: center;
    }
  }

  .call_centerdata {
    height: 100%;
    border: 1px solid var(--Border-border-subtle-00, #e0e0e0);
    height: 100%;
    border: 1px solid var(--Border-border-subtle-00, #e0e0e0);

    .call_centerdataee {
      background: #fff;
      padding: 20px;

      .choose_country {
        display: flex;
        flex-direction: column;
        gap: 5px;
        h5 {
          font-family: IBM Plex Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }
        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: -0.014px;
        }
      }
      .country_code {
        display: flex;
        align-items: center;
        margin-top: 20px;
        width: 50%;
      }
      .phonecall_btn {
        display: flex;
        justify-content: end;
        align-items: center;
      }
    }
    .recent_heding {
      padding: 20px 20px 10px 20px;
    }
    .support_centertime {
      border-bottom: 1px solid var(--border-subtle, #e0e0e0);
      display: flex;
      width: 100%;
      padding: 20px;
      align-items: center;
      ul {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        li {
          display: flex;
          justify-content: space-between;
          width: 100%;
          h6 {
            font-family: IBM Plex Sans;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 138.462% */
            letter-spacing: 0.16px;
          }
          span {
            color: var(
              --text-text-on-color-disabled,
              var(--Text-text--on-color-disabled, #8d8d8d)
            );
            text-align: right;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            letter-spacing: 0.32px;
          }
        }
      }
    }
  }
`;
export default FullScreenModalWrapper;
