import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TraningService, { Single_document } from "./TraningService";

export const GettAllDocuments = createAsyncThunk(
  "Documents/Documentdata",
  async (userId, thunkAPI) => {
    try {
      return await TraningService.FetchAll_document();
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const GetSingleDocuments = createAsyncThunk(
  "Documents/singledata",
  async (payload, thunkAPI) => {
    try {
      return await Single_document(payload);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const GetFilterOptions = createAsyncThunk(
  "Documents/FetchFilterOptions",
  async (payload, thunkAPI) => {
    try {
      const { data } = await TraningService.FetchFilterOptions();
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const GetFilterData = createAsyncThunk(
  "Documents/FilterData",
  async (payload, thunkAPI) => {
    try {
      const { data } = await TraningService.FilterData(payload);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const GetKnowledgeBaseData = createAsyncThunk(
  "Documents/KnowledgeFilterData",
  async (payload, thunkAPI) => {
    try {
      const { data } = await TraningService.KnowledgeFilterData(payload);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const GetFaqAndGuidesData = createAsyncThunk(
  "Documents/FaqAndGuidesData",
  async (payload, thunkAPI) => {
    try {
      const { data } = await TraningService.FaqAndGuidesData();
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getProductData = createAsyncThunk(
  "Documents/FetchProductData",
  async (payload, thunkAPI) => {
    try {
      const { data } = await TraningService.FetchProductData(payload);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getServiceData = createAsyncThunk(
  "Documents/FetchServiceData",
  async (payload, thunkAPI) => {
    try {
      const { data } = await TraningService.FetchServiceData(payload);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getTotalServiceAndProducts = createAsyncThunk(
  "Documents/FetchTotalServiceAndProducts",
  async (payload, thunkAPI) => {
    try {
      const { data } = await TraningService.FetchTotalServiceAndProducts(
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  singleData: {
    error: null,
    status: "idle",
    data: "",
    message: "",
  },

  getAllDocs: {
    error: null,
    status: "idle",
    data: "",
    message: "",
  },

  filterOptions: {
    error: null,
    status: "idle",
    data: "",
  },

  filterData: {
    error: null,
    status: "idle",
    data: "",
  },

  knowledgeFaqData: {
    error: null,
    status: "idle",
    data: "",
  },
  productData: {
    error: null,
    status: "idle",
    data: [],
    message: "",
  },
  serviceData: {
    error: null,
    status: "idle",
    data: [],
    message: "",
  },

  TotalProductAndServiceData: {
    error: null,
    status: "idle",
    data: [],
    message: "",
  },
};

export const GetDocumentslist = createSlice({
  name: "Document",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // TOTAL SERVICE AND PRODUCT DATA FOR HOMEPAGE
    builder.addCase(
      getTotalServiceAndProducts.fulfilled,
      (state, { payload }) => {
        state.TotalProductAndServiceData.data = payload;
        state.error = null;
        state.status = "succeeded";
      }
    );
    builder.addCase(getTotalServiceAndProducts.pending, (state, payload) => {
      state.status = "loading";
      state.error = null;
      state.TotalProductAndServiceData.data = [];
    });
    builder.addCase(
      getTotalServiceAndProducts.rejected,
      (state, { payload }) => {
        state.status = "failed";
        state.TotalProductAndServiceData.data = [];
        state.error = payload;
      }
    );

    // SERVICE DATA FOR HOMEPAGE

    builder.addCase(getServiceData.fulfilled, (state, { payload }) => {
      state.serviceData.data = payload;
      state.error = null;
      state.status = "succeeded";
    });
    builder.addCase(getServiceData.pending, (state, payload) => {
      state.status = "loading";
      state.error = null;
      state.serviceData.data = [];
    });
    builder.addCase(getServiceData.rejected, (state, { payload }) => {
      state.status = "failed";
      state.serviceData.data = [];
      state.error = payload;
    });

    // PORDUCT DATA FOR HOMEPAGE

    builder.addCase(getProductData.fulfilled, (state, { payload }) => {
      state.productData.data = payload;
      state.error = null;
      state.status = "succeeded";
    });
    builder.addCase(getProductData.pending, (state, payload) => {
      state.status = "loading";
      state.error = null;
      state.productData.data = [];
    });
    builder.addCase(getProductData.rejected, (state, { payload }) => {
      state.status = "failed";
      state.productData.data = [];
      state.error = payload;
    });

    //GetFaqAndGuidesData of FAQ and GUIDES
    builder.addCase(GetFaqAndGuidesData.fulfilled, (state, { payload }) => {
      state.knowledgeFaqData.data = payload;
      state.knowledgeFaqData.error = null;
      state.knowledgeFaqData.status = "succeeded";
    });
    builder.addCase(GetFaqAndGuidesData.pending, (state, payload) => {
      state.knowledgeFaqData.status = "loading";
      state.knowledgeFaqData.error = null;
      state.knowledgeFaqData.data = [];
    });
    builder.addCase(GetFaqAndGuidesData.rejected, (state, { payload }) => {
      state.knowledgeFaqData.status = "failed";
      state.knowledgeFaqData.data = [];
      state.knowledgeFaqData.error = payload;
    });

    //GetKnowledgeBaseData Of Knowlegde reducer
    builder.addCase(GetKnowledgeBaseData.fulfilled, (state, { payload }) => {
      state.filterData.data = payload;
      state.filterData.error = null;
      state.filterData.status = "succeeded";
    });
    builder.addCase(GetKnowledgeBaseData.pending, (state, payload) => {
      state.filterData.status = "loading";
      state.filterData.error = null;
      state.filterData.data = [];
    });
    builder.addCase(GetKnowledgeBaseData.rejected, (state, { payload }) => {
      state.filterData.status = "failed";
      state.filterData.data = [];
      state.filterData.error = payload;
    });

    //GetFilterData Of Admins reducer
    builder.addCase(GetFilterData.fulfilled, (state, { payload }) => {
      state.filterData.data = payload;
      state.filterData.error = null;
      state.filterData.status = "succeeded";
    });
    builder.addCase(GetFilterData.pending, (state, payload) => {
      state.filterData.status = "loading";
      state.filterData.error = null;
      state.filterData.data = [];
    });
    builder.addCase(GetFilterData.rejected, (state, { payload }) => {
      state.filterData.status = "failed";
      state.filterData.data = [];
      state.filterData.error = payload;
    });

    //GetFilterOptions reducer
    builder.addCase(GetFilterOptions.fulfilled, (state, { payload }) => {
      state.filterOptions.data = payload;
      state.filterOptions.error = null;
      state.filterOptions.status = "succeeded";
    });
    builder.addCase(GetFilterOptions.pending, (state, payload) => {
      state.filterOptions.status = "loading";
      state.filterOptions.error = null;
      state.filterOptions.data = [];
    });
    builder.addCase(GetFilterOptions.rejected, (state, { payload }) => {
      state.filterOptions.status = "failed";
      state.filterOptions.data = [];
      state.filterOptions.error = payload;
    });

    //GetAllDocuments

    builder.addCase(GettAllDocuments.fulfilled, (state, { payload }) => {
      state.getAllDocs.data = payload;
      state.error = null;
      state.status = "succeeded";
    });
    builder.addCase(GettAllDocuments.pending, (state, payload) => {
      state.status = "loading";
      state.error = null;
      state.getAllDocs.data = [];
    });
    builder.addCase(GettAllDocuments.rejected, (state, { payload }) => {
      state.status = "failed";
      state.getAllDocs.data = [];
      state.error = payload;
    });

    builder.addCase(GetSingleDocuments.fulfilled, (state, { payload }) => {
      state.singleData.data = payload;
      state.error = null;
      state.status = "succeeded";
    });
    builder.addCase(GetSingleDocuments.pending, (state, payload) => {
      state.status = "loading";
      state.error = null;
      state.singleData.data = [];
    });
    builder.addCase(GetSingleDocuments.rejected, (state, { payload }) => {
      state.status = "failed";
      state.singleData.data = [];
      state.error = payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = GetDocumentslist.actions;

export default GetDocumentslist.reducer;
