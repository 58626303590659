import styled from "styled-components";

const SupportWrapper = styled.div`
  .cds--white {
    background-color: #f4f4f4;
  }
  .cds--header-panel {
    min-width: 361px;
    height: calc(100% - 49px);

    @media screen and (max-width: 479px) and (min-width: 0px) {
      min-width: 360px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;

      h2 {
        color: var(--text-text-primary, var(--text-text-primary, #161616));
        font-family: IBM Plex Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 140% */
      }
      .button-box {
        display: flex;
        gap: 1rem;
        button {
          display: flex !important;
          width: 20px;
          height: 20px;
        }
      }
    }
    .cds--tabs {
      width: 100%;
      .cds--tab--list {
        width: 100%;
        .cds--tabs__nav-item {
          flex: 1;
        }
        .cds--tabs__nav-item-label-wrapper {
          justify-content: center;
          .cds--tabs__nav-item-label {
            display: flex;
            gap: 0.5rem;
            align-items: center;
          }
        }
      }

      .cds--tabs__nav-item--selected {
        border-block-end: ${(props) =>
          props.theme.colorScheme == "white"
            ? "2px solid var(--cds-border-interactive, #2570fe)"
            : "2px solid var(--cds-border-interactive, #77a6fe)"} !important;
        .cds--tabs__nav-item-label {
          overflow: hidden;
          color: ${(props) =>
            props.theme.colorScheme == "white" ? "#2570FE" : "#77A6FF"};

          text-overflow: ellipsis;
          font-family: IBM Plex Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px; /* 157.143% */
        }

        border-block-color: ${(props) =>
          props.theme.colorScheme === "white"
            ? "#0f61fd"
            : "#77A6FF"} !important;
      }
      .cds--tab--overflow-nav-button {
        display: none;
      }
    }
    .cds--tab-content {
      /* background-color: white; */
      height: calc(100% - 100px);
      overflow: auto;
      padding-left: 0;
      padding-right: 0;
      padding: 0;
      &::-webkit-scrollbar {
        display: none;
      }
      &:focus {
        outline: none;
      }
    }
  }
`;

export default SupportWrapper;
