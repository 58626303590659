// Note: Type annotations have been removed in the JavaScript version

export const outerContainerStyles = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "space40",
};

export const bubbleAndAvatarContainerStyles = {
  display: "flex",
  alignItems: "flex-end",
};

export const getAvatarContainerStyles = {
  width: "sizeIcon70",
  height: "sizeIcon70",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "space30",
  borderRadius: "borderRadiusCircle",
  overflow: "hidden",
  flex: "0 0 auto",
  background: "linear-gradient(180deg, #5E96FF 0%, #0F61FD 100%)",
  color: "white",
};

export function getInnerContainerStyles(belongToCurrentUser, isDarkTheme) {

  return {
    paddingTop: "space30",
    paddingBottom: "space30",
    paddingLeft: "space40",
    paddingRight: "space40",
    backgroundColor: isDarkTheme
      ? belongToCurrentUser
        ? "#474747"
        : "#0F62FE"
      : belongToCurrentUser
      ? "colorBackgroundPrimaryStronger"
      : "white",
    color: isDarkTheme
      ? belongToCurrentUser
        ? "white"
        : "colorText"
      : belongToCurrentUser
      ? "colorTextWeakest"
      : "colorText",
    borderRadius: "borderRadius30",
    border: isDarkTheme ? "none" : "1px solid #E0E0E0",
    marginLeft: belongToCurrentUser ? "auto" : "space0",
    marginRight: belongToCurrentUser ? "space0" : "auto",
    maxWidth: "75%",
    fontSize: 14,
    fontWeight: 400,
  };
}

export const authorStyles = {
  color: "inherit",
  fontWeight: "fontWeightBold",
  fontSize: "fontSize20",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

export const timeStampStyles = {
  fontSize: "fontSize20",
  marginLeft: "space40",
  color: "inherit",
};

export const bodyStyles = {
  color: "inherit",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
};

export const readStatusStyles = {
  textAlign: "right",
  fontSize: "fontSize10",
  marginRight: "space20",
  color: "colorText",
};
