import styled from "styled-components";
const MobileHeaderNavWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 48px;
  border-bottom: 1px solid var(--Border-border-subtle-01, #c6c6c6);

  .expandArrow {
    li {
      margin-top: 370px;
    }
  }

  .heading {
    display: flex;
    opacity: 0.7;
    gap: 1rem;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.16px;
  }
`;
export default MobileHeaderNavWrapper;
