import styled from "styled-components";
const SwitcherButtonWrapper = styled.button`
  display: flex;
  padding: 0.5rem 1rem;
  width: 100%;
  align-items: center;
  gap: 1rem;
  svg {
    color: white;
  }
  span {
    color: var(--white, #fff);
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
  }
`;
export default SwitcherButtonWrapper;
