export const containerStyles = {
    padding: "space60",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "colorBackground",
    textAlign: "center"
};

export const titleStyles = {
    fontSize: "fontSize50"
};

export const textStyles = {
    marginBottom: "space50",
    marginTop: "space50"
};
