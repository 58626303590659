import { createSlice } from "@reduxjs/toolkit";
import { addNotification, removeNotification } from "./notification.reducer";
import { notifications } from "utils/notifications";
import { sessionDataHandler } from "utils/sessionDataHandler";
import { EngagementPhase } from "./definitions";
// import {
//   ACTION_ATTACH_FILES,
//   ACTION_ADD_MESSAGE,
//   ACTION_ADD_MULTIPLE_MESSAGES,
//   ACTION_ADD_PARTICIPANT,
//   ACTION_DETACH_FILES,
//   ACTION_REMOVE_MESSAGE,
//   ACTION_REMOVE_PARTICIPANT,
//   ACTION_START_SESSION,
//   ACTION_UPDATE_CONVERSATION_STATE,
//   ACTION_UPDATE_MESSAGE,
//   ACTION_UPDATE_PARTICIPANT,
// } from "./actions/actionTypes";

const eventAssignedStatus = {
  initializeConversationListener: false,
  initializeClientListeners: false,
  initializeMessagesListener: false,
  initializeParticipantsListener: false,
};

const initialState = {
  messages: [],
  attachedFiles: [],
  participants: [],
  users: [],
  currentPhase: EngagementPhase.Loading,
};

function detachFilesFN(attachedFiles = [], filesToDetach = []) {
  return (attachedFiles || []).filter(
    (file) =>
      !filesToDetach.some(
        (fileToDetach) =>
          file.name === fileToDetach.name &&
          file.type === fileToDetach.type &&
          file.size === fileToDetach.size
      )
  );
}

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    startSession: (state, action) => {
      const {
        conversationsClient,
        conversation,
        conversationState,
        users,
        participants,
        messages,
      } = action.payload;
      const { token, conversationSid, currentPhase } = action.payload;
      // state.token = token;
      // state.conversationSid = conversationSid;
      // state.currentPhase = currentPhase;
      return {
        ...state,
        conversationsClient,
        conversation,
        conversationState,
        users,
        participants,
        messages,
        token: token,
        conversationSid: conversationSid,
        currentPhase: currentPhase,
      };
    },
    addMultipleMessages: (state, action) => {
      state.messages = [
        ...(action.payload.messages || []),
        ...(state.messages || []),
      ];
    },
    addMessage: (state, action) => {
      state.messages = [...(state.messages || []), action.payload.message];
    },
    removeMessage: (state, action) => {
      state.messages = [
        ...(state.messages || []).filter(
          (m) => m.sid !== action.payload.message.sid
        ),
      ];
    },
    updateMessage: (state, action) => {
      state.messages = [
        ...(state.messages || []).map((m) =>
          m.sid === action.payload.message.sid ? action.payload.message : m
        ),
      ];
    },
    attachFiles: (state, action) => {
      state.attachedFiles = [
        ...(state.attachedFiles || []),
        ...(action.payload || []),
      ];
    },
    detachFiles: (state, action) => {
      const filesToDetach = action.payload || [];
      state.attachedFiles = detachFilesFN(state.attachedFiles, filesToDetach);
    },
    addParticipant: (state, action) => {
      state.participants = [
        ...(state.participants || []),
        action.payload.participant,
      ];
      state.users = [...(state.users || []), action.payload.user];
    },
    removeParticipant: (state, action) => {
      state.participants = [
        ...(state.participants || []).filter(
          (p) => p.sid !== action.payload.participant.sid
        ),
      ];
      state.users = [
        ...(state.users || []).filter(
          (u) => u.identity !== action.payload.participant.identity
        ),
      ];
    },
    updateParticipant: (state, action) => {
      state.participants = [
        ...(state.participants || []).map((p) =>
          p.sid === action.payload.participant.sid
            ? action.payload.participant
            : p
        ),
      ];
    },
    updateConversationState: (state, action) => {
      state.conversationState = action.payload.conversationState;
    },
    updateSessionData: (state, action) => {
      const { token, conversationSid } = action.payload;
      state.token = token;
      state.conversationSid = conversationSid;
    },
    changeExpandedStatus: (state, action) => {
      state.expanded = action.payload.expanded;
    },
    changeEngagementPhase: (state, action) => {
      state.currentPhase = action.payload.phase;
    },
    updatePreEngagementData: (state, action) => {
      state.preEngagementData = {
        ...state.preEngagementData,
        ...action.payload,
      };
    },
  },
});

export const {
  startSession,
  addMultipleMessages,
  addMessage,
  removeMessage,
  updateMessage,
  attachFiles,
  detachFiles,
  addParticipant,
  removeParticipant,
  updateParticipant,
  updateConversationState,
  updateSessionData,
  changeExpandedStatus,
  changeEngagementPhase,
  updatePreEngagementData,
} = chatSlice.actions;

export const initializeConversationListener = (conversation) => (dispatch) => {
  if (eventAssignedStatus.initializeConversationListener) return;
  eventAssignedStatus.initializeConversationListener = true;

  conversation.addListener(
    "updated",
    ({ conversation: updatedConversation, updateReasons }) => {
      if (updateReasons?.includes("state")) {
        dispatch(
          updateConversationState({
            conversationState: updatedConversation?.state?.current,
          })
        );
      }
    }
  );
};

export const initializeClientListeners = (conversationClient) => (dispatch) => {
  if (eventAssignedStatus.initializeClientListeners) return;
  eventAssignedStatus.initializeClientListeners = true;

  const tokenAboutToExpireEvent = "tokenAboutToExpire";
  const connectionStateChangedEvent = "connectionStateChanged";
  const logger = window.Twilio.getLogger("conversationClientListener");

  // remove any other refresh handler added before and add it again
  conversationClient.removeAllListeners(tokenAboutToExpireEvent);
  conversationClient.addListener(tokenAboutToExpireEvent, async () => {
    logger.warn("token about to expire");

    const data = await sessionDataHandler.getUpdatedToken();
    if (data?.token && data?.conversationSid) {
      await conversationClient.updateToken(data.token);
      dispatch(
        updateSessionData({
          token: data.token,
          conversationSid: data.conversationSid,
        })
      );
    }
  });

  conversationClient.removeAllListeners(connectionStateChangedEvent);
  conversationClient.addListener(
    connectionStateChangedEvent,
    (connectionStatus) => {
      if (connectionStatus === "connected") {
        dispatch(
          removeNotification(notifications.noConnectionNotification().id)
        );
      } else if (connectionStatus === "connecting") {
        dispatch(addNotification(notifications.noConnectionNotification()));
      }
    }
  );
};

export const initializeMessagesListener = (conversation) => (dispatch) => {
  if (eventAssignedStatus.initializeMessagesListener) return;
  eventAssignedStatus.initializeMessagesListener = true;

  conversation.addListener("messageAdded", (message) => {
    dispatch(addMessage({ message }));
  });
  conversation.addListener("messageRemoved", (message) => {
    dispatch(removeMessage({ message }));
  });
  conversation.addListener("messageUpdated", ({ message }) => {
    dispatch(updateMessage({ message }));
  });
};

// working
export const initializeParticipantsListener = (conversation) => (dispatch) => {
  if (eventAssignedStatus.initializeParticipantsListener) return;
  eventAssignedStatus.initializeParticipantsListener = true;

  conversation.addListener("participantJoined", async (participant) => {
    const user = await participant.getUser();
    dispatch(addParticipant({ participant, user }));
  });

  conversation.addListener("participantLeft", (participant) => {
    dispatch(removeParticipant({ participant }));
  });

  const dispatchParticipantUpdate = (participant) => {
    dispatch(updateParticipant({ participant }));
  };

  conversation.addListener("participantUpdated", ({ participant }) =>
    dispatchParticipantUpdate(participant)
  );
  conversation.addListener("typingStarted", dispatchParticipantUpdate);
  conversation.addListener("typingEnded", dispatchParticipantUpdate);
};

export default chatSlice.reducer;
