import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FaqService from "./FaqService";

export const GetFaqDetails = createAsyncThunk(
  "Faq",
  async (userId, thunkAPI) => {
    try {
      return await FaqService.FetchFaqDetails();
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  FaqData: {
    error: null,
    status: "idle",
    data: "",
    message: "",
  },
};

export const GetFaqList = createSlice({
  name: "Faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetFaqDetails.fulfilled, (state, { payload }) => {
      state.FaqData.data = payload;
      state.error = null;
      state.status = "succeeded";
    });
    builder.addCase(GetFaqDetails.pending, (state, payload) => {
      state.status = "loading";
      state.error = null;
      state.FaqData.data = [];
    });
    builder.addCase(GetFaqDetails.rejected, (state, { payload }) => {
      state.status = "failed";
      state.FaqData.data = [];
      state.error = payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = GetFaqList.actions;

export default GetFaqList.reducer;
