import {
  Calendar,
  IncompleteCancel,
  NotAvailable,
  Time,
  UpdateNow,
} from "@carbon/icons-react";
import {
  Column,
  DataTable,
  DataTableSkeleton,
  Dropdown,
  Pagination,
  Row,
  SkeletonPlaceholder,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from "@carbon/react";
import {
  Planned_header2,
  dropdown_items,
  renderIcon,
} from "Component/Data/DataJson";
import axios from "axios";
import useWindowSize from "hooks/useWindowSize";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import "./incident.css";
import { GlobalContext } from "ContextApi/GlobalContext";
import axiosInstance from "axiosintance";
import { components_url, incidents_url } from "Api";

const activeRowStyles = {
  backgroundColor: "#262626", // Light grey background for the active row
  border: "2px solid #0F62FE ",
};

const Incedent = () => {
  const { width } = useWindowSize();
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [maintenencedata, setmaintenencedata] = useState([]);
  const [activeServiceData, setActiveServiceData] = useState({});
  const [serviceItems, setServiceItems] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const context = useContext(GlobalContext);

  const systemdata = useMemo(
    () => searchParams.get("status") ?? "",
    [searchParams]
  );

  const setsystemdata = useCallback(
    (status) => {
      searchParams.set("status", status);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const [selectedService, setSelectedService] = useState("");
  const [loading, setLoading] = useState(true);
  const calculateMinutes = (start_time, end_time) => {
    let start_time_minutes = new Date(start_time).getTime();
    let end_time_minutes = new Date(end_time).getTime();

    return Math.round((end_time_minutes - start_time_minutes) / 60000);
  };

  useEffect(() => {
    axiosInstance
      .get(process.env.REACT_APP_INCIDENTS_URL)
      .then((res) => {
        const filterArray = res.data;
        const finalData = [];
        const affectedComponents = res?.data?.map((incident) => {
          return incident.incident_updates.flatMap((update) => {
            return update.affected_components || [];
          });
        });

        filterArray?.forEach((item) => {
          if (item?.impact !== "maintenance") {
            let itemNames = item.components
              .map((component) => `${component.name}`)
              .join(" || "); // For HTML line breaks

            let itemStatus = item.components
              .map((component) => component.status)
              .join(); // Join with a special marker

            let incidentDataBody = item.incident_updates[0]?.body;

            finalData.push({
              ...item,
              components_name: itemNames,
              status: itemStatus,
              incidentDataBody,
            });
          }
        });

        // Assuming setActiveServiceData and setMaintenanceData are React state setters
        if (finalData.length > 0) {
          setActiveServiceData(finalData[0]);
        }
        setmaintenencedata(finalData);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    axiosInstance.get(process.env.REACT_APP_COMPONENTS_URL).then((res) => {
      const items = [];
      res?.data?.map((item) => {
        items.push({
          id: item.name,
          label: item.name,
        });
      });
      setServiceItems(items);
    });
  }, []);

  const setactive = (index = 0) => {
    setActiveServiceData(maintenencedata[index]);
  };

  const handleRowClick = (rowIndex) => {
    setActiveRowIndex(rowIndex);
    setactive(rowIndex);
  };
  const getRowStyle = (rowIndex) => {
    return rowIndex === activeRowIndex ? { ...activeRowStyles } : {};
  };
  return (
    <>
      <div className="indicate">
        <Row>
          <Column lg={16}>
            <div className="sub_heading my-5">
              <div className="indicate_icon ">
                <IncompleteCancel size={24} />
              </div>
              <h5>Incidents</h5>
            </div>
          </Column>
        </Row>

        <Row>
          <Column lg={10}>
            <TableContainer
              className=" incident_table"
              style={{
                minHeight: "100%",
                background:
                  context?.globalTheme === "white" ? "#fff" : "#262626",
                boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              {!loading ? (
                <DataTable
                  size="xlg"
                  rows={maintenencedata.filter((item) => {
                    if (!systemdata || !selectedService) {
                      if (!systemdata && !selectedService) {
                        return true;
                      } else if (!systemdata) {
                        if (item.components_name.includes(selectedService)) {
                          return true;
                        }
                      } else if (!selectedService) {
                        if (systemdata == item.status) {
                          return true;
                        }
                      }
                    }

                    if (
                      systemdata == item.status &&
                      item.components_name.includes(selectedService)
                    ) {
                      return true;
                    }
                  })}
                  headers={Planned_header2}
                >
                  {({
                    rows,
                    headers,
                    getHeaderProps,
                    getRowProps,
                    getTableProps,
                    onInputChange,
                  }) => (
                    <TableContainer className="incident_table_cont">
                      <TableToolbar>
                        <TableToolbarContent className="incident_toolbar_content">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: width <= 768 ? "column" : "row",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{ width: width <= 768 ? "100%" : "47%" }}
                            >
                              <TableToolbarSearch
                                className="dark:!bg-[#161616]"
                                onChange={onInputChange}
                                style={{ width: "100%" }}
                                expanded
                                placeholder="Search input text"
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: width <= 768 ? "100%" : "53%",
                              }}
                            >
                              <Dropdown
                                className="dark:!bg-[#161616] dark:!border-[#474747]"
                                size="lg"
                                items={dropdown_items}
                                label="Status"
                                style={{
                                  width: "60%",
                                  borderLeft: "1px solid #C6C6C6",
                                }}
                                selectedItem={systemdata
                                  .replace("_", " ")
                                  .toUpperCase()}
                                onChange={(e) => {
                                  setsystemdata(e.selectedItem?.id ?? "");
                                }}
                              />
                              <Dropdown
                                className="dark:!bg-[#161616] dark:!border-[#474747]"
                                size="lg"
                                style={{
                                  width: "40%",
                                  borderLeft: "1px solid #C6C6C6",
                                }}
                                items={[
                                  {
                                    id: "",
                                    label: "All",
                                  },
                                  ...serviceItems,
                                ]}
                                label="Filter"
                                onChange={(e) => {
                                  setSelectedService(e.selectedItem?.id);
                                }}
                              />
                            </div>
                          </div>

                          {/* <Button renderIcon={Filter}>Filter</Button> */}
                        </TableToolbarContent>
                      </TableToolbar>
                      <Table size="lg" {...getTableProps()}>
                        <TableHead size="lg">
                          <TableRow>
                            {headers.map((header) => (
                              <TableHeader
                                className="dark:!text-[#fff]"
                                key={header.key}
                                {...getHeaderProps({ header })}
                              >
                                {header.header}
                              </TableHeader>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, rowIndex) => (
                            <TableRow
                              key={row.id}
                              onClick={() => handleRowClick(rowIndex)}
                              className={
                                rowIndex === activeRowIndex ? "active" : ""
                              }
                              {...getRowProps({ row })}
                            >
                              {row.cells.map((cell) => (
                                <TableCell
                                  className="p-3 !bg-white dark:!text-white "
                                  key={cell.id}
                                  style={{
                                    padding:
                                      (cell.info.header == "status" ||
                                        cell.info.header == "name") &&
                                      width <= 768 &&
                                      "0px 2.6rem 0px 1.5rem",
                                  }}
                                >
                                  {cell.info.header == "status"
                                    ? cell.value
                                        ?.split(",")
                                        .map((val, index, array) => (
                                          <React.Fragment key={index}>
                                            {renderIcon(val.trim(), context)}
                                            {index < array.length - 1 && <br />}
                                          </React.Fragment>
                                        ))
                                    : cell.info.header === "created_at"
                                    ? moment(cell.value).format(
                                        "dddd, MMMM - Do - YYYY (hh:mm) A"
                                      )
                                    : cell.info.header == "components_name"
                                    ? cell.value
                                        .split("||")
                                        .map((val, index, array) => (
                                          <React.Fragment key={index}>
                                            {val.trim()}
                                            {index < array.length - 1 && (
                                              <>
                                                <br />
                                                <br />
                                              </>
                                            )}
                                          </React.Fragment>
                                        ))
                                    : cell.value || <NotAvailable size={16} />}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </DataTable>
              ) : (
                <DataTableSkeleton headers={Planned_header2} />
              )}{" "}
              <div style={{ position: "absolute", bottom: "0", width: "100%" }}>
                <Pagination
                  pagesUnknown={true}
                  backwardText="Previous page"
                  forwardText="Next page"
                  itemsPerPageText=""
                  page={1}
                  pageSize={10}
                  pageNumberText="Page Number"
                  pageSizes={[10, 15]}
                  totalItems={0}
                  onChange={() => {}}
                />
              </div>
            </TableContainer>
          </Column>

          <Column lg={6}>
            {!loading ? (
              <div
                className="service_heading dark:!border-[#474747] dark:!text-white"
                style={{ marginTop: width <= 768 ? "16px" : 0 }}
              >
                <Row>
                  <Column lg={16}>
                    <div
                      className="mainpara_graph dark:!text-white"
                      style={{ margin: "10px 0 38px 0" }}
                    >
                      <h6>{activeServiceData?.incidentDataBody}</h6>
                    </div>
                  </Column>
                  <Column lg={6}>
                    <div
                      className="single_status_page"
                      style={{
                        borderBottom:
                          width <= 479 ? "1px solid #e0e0e0" : "unset",
                        paddingBottom: width <= 479 ? "50px" : "0px",
                      }}
                    >
                      <ul>
                        <li>
                          <h6>Service</h6>
                          <span className="dark:!text-white">
                            {activeServiceData?.components_name || (
                              <NotAvailable size={16} />
                            )}
                          </span>
                        </li>
                        <li>
                          <h6>Status</h6>
                          <span
                            className="status_data pt-2 dark:!text-white flex !justify-start !items-start "
                            style={{
                              color: "#fff",
                              flexDirection: "column",
                            }}
                          >
                            {activeServiceData?.status
                              ?.split(",")
                              .map((val, index, array) => (
                                <React.Fragment key={index}>
                                  {renderIcon(val.trim(), context)}

                                  {index < array.length + 1 && <br />}
                                </React.Fragment>
                              ))}
                          </span>
                        </li>
                        <li>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <Time size={16} />
                            <h6>Duration</h6>
                          </div>
                          {activeServiceData && (
                            <span className="dark:!text-white">
                              {calculateMinutes(
                                activeServiceData?.monitoring_at,
                                activeServiceData?.resolved_at
                              )}
                              &nbsp; minutes
                            </span>
                          )}
                        </li>
                        <li>
                          <h6>ID</h6>
                          <span className="dark:!text-white">
                            {activeServiceData?.id}{" "}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Column>

                  <Column lg={10}>
                    <div className="last_updates">
                      <ul>
                        <li>
                          <div className="update_icon">
                            <Calendar size={16} />
                            <h6>Created On</h6>
                          </div>
                          <span className="dark:!text-white">
                            {moment(activeServiceData?.created_at).format(
                              "dddd, MMMM - Do - YYYY (hh:mm) A"
                            )}
                          </span>
                        </li>
                        <li>
                          <div className="update_icon">
                            <Calendar size={16} />
                            <h6>Last Update</h6>
                          </div>
                          <span className="dark:!text-white">
                            {moment(activeServiceData?.updated_at).format(
                              "dddd, MMMM - Do - YYYY (hh:mm) A"
                            )}
                          </span>
                        </li>
                        <li>
                          <div className="update_icon">
                            <UpdateNow size={16} />
                            <h6>Updates</h6>
                          </div>

                          <p className="dark:!text-white">
                            {activeServiceData &&
                              !!activeServiceData?.incident_updates?.length &&
                              activeServiceData?.incident_updates
                                ?.reverse()
                                ?.map((item, index) => (
                                  <>
                                    {" "}
                                    <p className="dark:!text-white">
                                      {index + 1 + ". "} {item?.body} Updated at
                                      -
                                      {item?.updated_at &&
                                        moment(
                                          activeServiceData?.updated_at
                                        ).format(
                                          "dddd, MMMM - Do - YYYY (hh:mm) A"
                                        )}
                                    </p>{" "}
                                    <br />
                                  </>
                                ))}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </Column>
                </Row>
              </div>
            ) : (
              <SkeletonPlaceholder style={{ width: "100%" }} />
            )}
          </Column>
        </Row>
      </div>
    </>
  );
};

export default Incedent;
