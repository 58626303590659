import styled from "styled-components";

const ButtonWrapper = styled.span`
  width: ${(props) => (props.$fullWidth ? "100%" : "")};
  max-width: ${(props) => (props.$fullWidth ? "100%" : "")};

  .cds--btn {
    width: ${(props) => (props.$fullWidth ? "100%" : "")};
    max-width: ${(props) => (props.$fullWidth ? "100%" : "")};
    padding-right: ${(props) => (props.$center ? "15px" : "")};
    justify-content: ${(props) => (props.$center ? "center" : "")};
  }

  .cds--loading-overlay {
    position: absolute;
    background: transparent;
  }
`;
export default ButtonWrapper;
