import useWindowSize from "hooks/useWindowSize";
import React from "react";
import {
  HeaderMenuButton,
  SideNav,
  SideNavItems,
  SideNavLink,
  SideNavMenu,
} from "@carbon/react";
import {
  ArrowRight,
  ChevronRight,
  Dashboard,
  Forum,
} from "@carbon/icons-react";
import MobileHeaderNavWrapper from "./style";
import { useDispatch, useSelector } from "react-redux";
import { handleSideNav, handleMobileSideNav } from "redux/slices/headerSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { sideNavRoutes } from "utils/routes/sideNavRoutes";
import {
  headerState,
  reset as resetHeaderState,
} from "redux/slices/headerSlice";
import SwitcherTab from "components/header/tabs/switcher";

import SideNavWrapper from "../styles";
import LayoutWrapper from "components/layout/styles";
import SideNavBar from "..";

const MobileHeaderNav = () => {
  const { switcher, sideNav, mobileNav } = useSelector(headerState);

  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function resetAndNavigate(path) {
    navigate(path);
    dispatch(resetHeaderState());
  }

  return width > 1024 || switcher ? (
    <SideNav
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      }}
      className={"!bg-[#000]"}
      isPersistent={false}
      aria-label="Side navigation"
      expanded={switcher}
      isFixedNav={false}
      isChildOfHeader={true}
      onOverlayClick={() => {
        dispatch(resetHeaderState());
        // dispatch(handleMobileSideNav());
      }}
    >
      <SwitcherTab />
    </SideNav>
  ) : (
    <SideNavBar />

    // ) : width <= 1024 || mobileNav ? (
    //   <SideNav
    //     className="dark:!bg-[#262626]"
    //     style={{
    //       boxShadow:
    //         "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    //     }}
    //     aria-label="Side navigation"
    //     expanded={mobileNav}
    //     isRail
    //   >
    //     <SideNavWrapper className="dark:!text-[#fff] ">
    //       <SideNavItems className="dark:!text-[#fff] ">
    //         {sideNavRoutes.map((link) => {
    //           if (link.name !== "Support Dashboard") {
    //             return (
    //               <>
    //                 {link.name !== "Training & Guides" ? (
    //                   <SideNavLink
    //                     className="dark:!text-[#fff]"
    //                     isActive={pathname === link.path}
    //                     key={link.id}
    //                     as={Link}
    //                     to={link.path}
    //                     onClick={(e) => {
    //                       e.preventDefault();
    //                       e.stopPropagation();
    //                       resetAndNavigate(link.path);
    //                       // setActiveRowIndex(null);
    //                     }}
    //                     // renderIcon={Dashboard}
    //                   >
    //                     {link.name}
    //                   </SideNavLink>
    //                 ) : (
    //                   <SideNavMenu title="Training & Guides">
    //                     <SideNavLink
    //                       as={Link}
    //                       to="/training/Training-For-admins"
    //                       isActive={pathname == "/training/Training-For-admins"}
    //                     >
    //                       Training For Admins
    //                     </SideNavLink>
    //                     <SideNavLink
    //                       as={Link}
    //                       to="/training/knowledge-base"
    //                       isActive={pathname == "/training/knowledge-base"}
    //                     >
    //                       Knowledge base
    //                     </SideNavLink>
    //                     <SideNavLink
    //                       as={Link}
    //                       to="/training/Faq-Guide"
    //                       isActive={pathname == "/training/Faq-Guide"}
    //                     >
    //                       FAQ & Guides
    //                     </SideNavLink>
    //                   </SideNavMenu>
    //                 )}
    //               </>
    //             );
    //           } else {
    //             return (
    //               <SideNavLink
    //                 className="dark:!text-[#fff]"
    //                 isActive={pathname === link.path}
    //                 key={link.id}
    //                 as={Link}
    //                 to={link.path}
    //                 // renderIcon={Dashboard}
    //                 onClick={(e) => {
    //                   e.preventDefault();
    //                   e.stopPropagation();
    //                   resetAndNavigate(link.path);
    //                   // setActiveRowIndex(null);
    //                 }}
    //                 style={{
    //                   borderBottom: "1px solid #8d8d8d33",
    //                 }}
    //               >
    //                 <div className="heading dark:!text-white ">
    //                   <Forum size={24} />{" "}
    //                   <Link to="/">
    //                     <span className="dark:!text-white">Support Center</span>
    //                   </Link>
    //                 </div>
    //               </SideNavLink>
    //             );
    //           }
    //         })}
    //       </SideNavItems>

    //       <SideNavItems className="dark:!text-[#fff] expandArrow ">
    //         <SideNavLink
    //           className="sidenav_link "
    //           renderIcon={ChevronRight}
    //           large
    //           onClick={() => handleMobileSideNav()}
    //         ></SideNavLink>
    //       </SideNavItems>
    //     </SideNavWrapper>
    //   </SideNav>
  );
};

export default MobileHeaderNav;
