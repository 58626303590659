import {
  Attachment,
  Categories,
  Notebook,
  Phone,
  ServiceDesk,
} from "@carbon/icons-react";
import { Column, Row, Theme } from "@carbon/react";
import { GlobalContext } from "ContextApi/GlobalContext";
import useWindowSize from "hooks/useWindowSize";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as Slink, scrollSpy } from "react-scroll";
import CreateRequestFormWrapper from "./style";
import CreateTicketSideNavForm from "./withoutFullScreen";

const CreateRequestForm = () => {
  const [id, setId] = useState();
  const context = useContext(GlobalContext);
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    const elem =
      document.getElementById("fullscreen-modal")?.children[1]?.children[1]
        ?.children[1];

    elem.id = "fullscreenModal";

    function onScroll() {
      scrollSpy.update();
    }

    elem?.addEventListener("scroll", onScroll);

    setId(elem?.id);

    scrollSpy.update();

    return () => {
      elem?.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <CreateRequestFormWrapper className="dark:!bg-[#161616]">
      <Row>
        <Column lg={3} className="scroll-spy-side-nav-col">
          <div className="scroll-spy-link-box">
            <Slink
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
              to="service-request-type"
              offset={-100}
              // containerId="fullscreen-modal-content"
              containerId={id}
            >
              <div className="spy-box-content dark:!text-white ">
                <Categories /> Service Request Type
              </div>
            </Slink>

            <Slink
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
              to="service"
              offset={-100}
              // containerId="fullscreen-modal-content"
              containerId={id}
            >
              <div className="spy-box-content dark:!text-white ">
                <ServiceDesk /> Service
              </div>
            </Slink>

            <Slink
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
              to="subject"
              offset={-100}
              // containerId="fullscreen-modal-content"
              containerId={id}
            >
              <div className="spy-box-content dark:!text-white ">
                <Notebook />
                Subject
              </div>
            </Slink>

            <Slink
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
              to="contact"
              offset={-100}
              // containerId="fullscreen-modal-content"
              containerId={id}
            >
              <div className="spy-box-content dark:!text-white  ">
                <Phone />
                Contact
              </div>
            </Slink>

            <Slink
              activeClass="active"
              to="attachments"
              offset={-100}
              // containerId="fullscreen-modal-content"
              containerId={id}
              spy={true}
              smooth={true}
              duration={500}
            >
              <div className="spy-box-content dark:!text-white ">
                <Attachment />
                Attachments
              </div>
            </Slink>
          </div>
        </Column>

        <Column lg={10}>
          <Theme theme={context.globalTheme == "white" ? "#f4f4f4" : "g100"}>
            <CreateTicketSideNavForm renderCancelBtn={false} />
          </Theme>
        </Column>

        {/* <Column
          lg={3}
          className={`flex justify-end ${width > 1055 ? "" : "mt-10"} `}
        >
          <CustomButton
            text="Cancel"
            className={
              width > 1055 ? "!w-full inline-block sticky top-[70px]" : ""
            }
            fullWidth={width > 1055}
            handleClick={() => {
              dispatch(handleCreateTicketTab(false));
            }}
          />
        </Column> */}
      </Row>
    </CreateRequestFormWrapper>
  );
};

export default CreateRequestForm;
