import styled from "styled-components";

export const BrowsercallWrapper = styled.section`
  position: relative;
  height: 100%;
  background: var(--background-background-inverse, #393939);

  .main_headericon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    .header-content {
      display: flex;
      width: 136px;
      height: 136px;
      padding: 32.211px 32.21px 32.21px 32.211px;
      justify-content: center;
      align-items: center;
      border-radius: 89.474px;
      background: var(--Icon-icon-primary, #161616);
      color: #fff;
    }
    .dial-pad {
      border-radius: 20px;
      color: white;
      padding-top: 100px;
    }

    .display {
      text-align: center;
      margin-bottom: 20px;
      font-size: 2em;
    }

    .buttons {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }

    .number,
    .symbol,
    .delete-button {
      display: flex;
      width: 48px;
      height: 48px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 128.571px;
      background: var(--Button-button-secondary-active, #6f6f6f);
    }
    .call-button {
      display: flex;
      width: 48px;
      height: 48px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 128.571px;
      background: var(--Button-button-primary, #0f62fe);
    }

    .number span,
    .symbol span {
      display: block;
      font-size: 0.6em;
    }

    .call-action {
      display: flex;
      justify-content: end;
      margin-top: 20px;
      gap: 20px;
    }
  }
  .footer-content {
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: var(--Icon-icon-primary, #161616);
    padding: 20px;
    .record_icon {
      color: #d1d9e2;
    }
    ul {
      display: flex;
      justify-content: space-between;
      width: 100%;
      li {
        display: flex;
        align-items: center;
        gap: 15px;
        span {
          color: var(--Text-text-on-color, #fff);
          font-family: IBM Plex Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.16px;
        }
        .app_icon {
          cursor: pointer;
          display: flex;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          border-radius: 100px;
          background: var(--Button-button-secondary-active, #6f6f6f);
        }
      }
    }
    .call_icon {
      cursor: pointer;
      display: flex;
      width: 32px;
      height: 32px;
      color: #fff;
      transform: rotate(135deg);
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border-radius: 100px;
      background: var(--Button-button-primary, #0f62fe);
    }
  }
  .footer_row {
    width: 100%;
    position: absolute;
    bottom: 105px;
  }
  .call_now {
    cursor: pointer;
    display: flex;
    width: 50px;
    height: 50px;
    color: #fff;
    transform: rotate(135deg);
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 100px;
    background: var(--Button-button-primary, #0f62fe);
  }
  .connect_call {
    .heading_main {
      display: flex;
      flex-direction: column;
      text-align: center;
      h6 {
        color: var(--Accessibility-White, #fff);
        font-size: 20.571px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      span {
        color: var(
          --icon-icon-on-color-disabled,
          var(--Icon-icon-on-color-disabled, #8d8d8d)
        );
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .icon_Data {
      color: #fff;
      text-align: center;
      .icon {
        border-radius: 150px;
        background: var(--Button-button-secondary-active, #6f6f6f);
        display: flex;
        width: 48px;
        height: 48px;
        color: #fff;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .supportwidth {
    width: 20%;
  }
`;
