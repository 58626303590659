export const EngagementPhase = {
  PreEngagementForm: "PreEngagementForm",
  MessagingCanvas: "MessagingCanvas",
  Loading: "Loading",
};

export const mimeToExtension = {
  "image/jpeg": "jpg",
  "image/jpg": "jpg",
  "image/png": "png",
  "audio/amr": "amr",
  "audio/mpeg": "mp3",
  "video/mp4": "mp4",
  "application/pdf": "pdf",
  "text/plain": "txt",
  // Add more mime types and corresponding extensions as needed
};
