// Note: Type annotations have been removed in the JavaScript version
import { MESSAGES_SPINNER_BOX_HEIGHT } from "utils/constants";

export const messageListStyles = {
  flexGrow: 1,
  justifyContent: "flex-end",
  overflow: "auto",
  display: "flex",
  flexDirection: "column-reverse",
};

export const outerContainerStyles = {
  display: "flex",
  flexDirection: "column-reverse",
  flex: "1",
  marginTop: "auto",
  overflow: "auto",
};

export const innerContainerStyles = {
  display: "flex",
  flexDirection: "column",
  padding: "space40",
  flex: 1,
};

export const spinnerContainerStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: `${MESSAGES_SPINNER_BOX_HEIGHT}px`,
};

export const participantTypingStyles = {
  fontSize: "fontSize10",
  fontStyle: "italic",
  color: "colorTextWeak",
  marginTop: "auto",
};

export const conversationEventContainerStyles = {
  textAlign: "center",
  marginTop: "space40",
  marginBottom: "space60",
};

export const conversationEventTitleStyles = {
  fontSize: "fontSize20",
};

export const conversationEventDateStyles = {
  fontSize: "fontSize20",
  fontStyle: "textStyleItalic",
  color: "colorTextWeak",
};
