import {
  REQUEST,
  ROOT,
  STATUS,
  PLAN,
  TRAINING,
  FAQGUIDE,
  TRAINING_KNOWLEDGE_BASE,
  TRAININFORADMINS,
} from "./pathnames";

export const sideNavRoutes = [
  {
    id: 1,
    path: ROOT,
    name: "Support Dashboard",
  },
  {
    id: 2,
    path: REQUEST,
    name: "Service Request",
  },
  {
    id: 3,
    path: STATUS,
    name: "Service Status",
  },
  {
    id: 4,
    path: PLAN,
    name: "Support Plans",
  },
  {
    id: 5,
    path: TRAINING,
    name: "Training & Guides",
  },
];

export const GlobalsideNavRoutes = [
  {
    id: 1,
    path: ROOT,
    name: "Support Dashboard",
  },
  {
    id: 2,
    path: REQUEST,
    name: "Service Request",
  },
  {
    id: 3,
    path: STATUS,
    name: "Service Status",
  },
  {
    id: 4,
    path: PLAN,
    name: "Support Plans",
  },
  {
    id: 5,
    path: TRAININFORADMINS,
    name: "Training For Admins",
  },
  {
    id: 6,
    path: TRAINING_KNOWLEDGE_BASE,
    name: " Knowledge Base",
  },
  {
    id: 7,
    path: FAQGUIDE,
    name: "FAQ & Guides",
  },
];
