const { default: styled } = require("styled-components");

export const Faqguidewrapper = styled.section`
  /* position: relative; */
  background-position: top right;
  background-repeat: no-repeat;
  background-color: ${(props) =>
    props?.theme.colorScheme == "white" ? "#f4f4f4" : "#161616"};

  .spy-box-content {
    overflow: hidden;
    /* color: var(--text-text-secondary, var(--Text-text-secondary, #525252)); */
    text-overflow: ellipsis;
    white-space: nowrap;

    /* Fixed heading styles/heading-reg-07 */
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
  }

  .mobile_dropdown {
    color: var(--text-text-secondary, var(--Text-text-secondary, #525252));
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  .active {
    color: ${(props) => (props.theme.colorScheme == "white" ? "#000" : "#fff")};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  .dropdownsticky {
    position: -webkit-sticky;
    position: sticky;
    top: 48px;
    z-index: 99;
  }

  .cds--list-box__label {
    width: 285px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
    a {
      color: ${(props) =>
        props?.theme.colorScheme == "white" ? "#161616" : "#fff"};
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
    }
  }

  .main_heading_div {
    @media screen and (max-width: 1024px) {
      background-image: url("/faqheadermobileimage.png");
      background-position: top right;
      background-repeat: no-repeat;
      background-color: ${(props) =>
        props?.theme.colorScheme == "white" ? "#f4f4f4" : "#161616"};
    }
  }

  .training_adminsmainheading {
    padding: 32px 0 0px 0;

    @media screen and (max-width: 768px) {
      /* position: sticky;
      top: 50px; */
      padding: 32px 0 24px 0;
    }

    .cds--search-input {
      background-color: ${(props) =>
        props.theme.colorScheme == "white" ? "white" : "#393939"};
    }

    h6 {
      color: var(--Text-text-secondary, #525252);
      font-family: "IBM Plex Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.32px;
    }
    h4 {
      color: var(--Accessibility-Black, #000);
      font-family: "IBM Plex Sans";
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
      padding-bottom: 32px;
    }
  }

  .faq_heading {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 72px;

    .dangerous_para {
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
      p {
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.16px;
      }
      b {
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.16px;
      }
    }
    .load_more {
      cursor: pointer;
      color: #2570fe;
      font-family: "IBM Plex Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    h5 {
      color: var(--Accessibility-Black, #000);
      font-family: "IBM Plex Sans";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
    }
  }
  .filter_data {
    display: flex;
    flex-direction: column;
    box-shadow: 3px 0px 0px 0px #c6c6c6 inset;
    position: sticky;
    top: 0;
    a {
      color: #8d8d8d;
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      cursor: pointer;
    }
    .active {
      box-shadow: 3px 0px 0px 0px #0f62fe inset;
      color: ${(props) =>
        props.theme.colorScheme == "white" ? "#161616" : "#fff"};
      display: block;
      overflow: hidden;

      .spy-box-content {
        text-overflow: ellipsis;
        /* Fixed heading styles/heading-semi-07 */
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
      }
    }
  }
`;
