import styled from "styled-components";
const SideNavWrapper = styled.div`
  margin-top: -17px;
  .cds--side-nav__submenu-title {
    overflow: hidden;
    color: ${(props) =>
      props.theme.colorScheme == "white" ? "#161616" : "white"};
    text-overflow: ellipsis;
    white-space: nowrap;

    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
  }
  a.cds--side-nav__link--current::before {
    background-color: #2570fe;
  }
  .cds--side-nav__items {
    .cds--side-nav__item {
      .cds--side-nav__link-text {
        overflow: hidden;
        color: var(--Text-text-primary, #161616);
        text-overflow: ellipsis;
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
      }
    }
  }

  .ordered_list_sidenav {
    margin-top: -36px;
    .ordered_listItem {
      a {
        overflow: hidden;
        color: var(--text-text-primary, var(--Text-text-primary, #161616));
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
        padding-left: 16px;
      }
    }

    .order_nested_link {
      a {
        color: var(--text-text-primary, var(--Text-text-primary, #161616));
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
        padding-left: 36px;
      }
    }

    .cds--list__item {
      padding: 8px 0%;

      .cds--list--nested {
        margin-inline-start: 0rem !important;
      }
    }
  }

  .heading {
    display: flex;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.1599999964237213px;
    text-align: left;
    gap: 1rem;
    padding-inline: 5px;
    padding-top: 24px;
    padding-bottom: 16px;
    align-items: center;

    span {
      color: var(--Gray-500, #161616);
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
    }
  }
`;
export default SideNavWrapper;
