import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB7iPfmtbLKYtUJkqpiM1pUwO8ylJ9v3x8",
  authDomain: "test-7ee7d.firebaseapp.com",
  projectId: "test-7ee7d",
  storageBucket: "test-7ee7d.appspot.com",
  messagingSenderId: "710152431651",
  appId: "1:710152431651:web:ffa056b10e31e2dcc06996",
  measurementId: "G-F9ZJ9FQ1QZ",
};

export async function getFirebaseMessaging() {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    console.log("Firebase is not supported in this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
