import styled from "styled-components";
const ServiceRequestWrapper = styled.div`
  gap: 8px;
  padding: 16px;
  display: flex;
  flex-wrap: no-wrap;
  background: var(--white, #fff);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  z-index: 999;

  @media screen and (max-width: 1240px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .heading {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1.2;
    margin-bottom: 0;
    .icon_box {
      display: flex;
      justify-content: center;
      align-items: center;
      /* background-color: #e0e0e0; */
      border-radius: 50%;
    }

    h3 {
      font-family: IBM Plex Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    h5 {
      color: var(--Accessibility-Black, #000);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 155.556% */
    }
  }
  .status_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--field, #f4f4f4);
    padding: 16px;
    gap: 1rem;
    flex: 1;
    color: ${(props) =>
      props.theme.colorScheme === "white" ? "#000" : "#fff"};
    border: 2px solid transparent;
    z-index: 0;
    h5 {
      color: var(--Text-text-primary, #161616);

      /* Fixed heading styles/heading-semi-07 */
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
    }
    &:hover {
      cursor: pointer;
      background-color: ${(props) =>
        props.theme.colorScheme === "white"
          ? "#e0e0e0 !important"
          : "rgb(61, 61, 61) !important"};
    }

    &.active {
      border: 2px solid #2570fe;
    }
  }
`;
export default ServiceRequestWrapper;
