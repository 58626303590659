// Note: Type annotations have been removed in the JavaScript version

export const formStyles = {
  borderTopColor: "colorBorderWeaker",
  borderTopWidth: "borderWidth10",
  borderTopStyle: "solid",
  padding: "space20",

  className: "message-input-container",
};

export const innerInputStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
};

export const textAreaContainerStyles = {
  marginTop: "auto",
  marginBottom: "auto",
  flex: 1,
};

export const messageOptionContainerStyles = {
  margin: "space30",
  marginLeft: "space20",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "left",

  _notLast: {
    marginRight: "space0",
  },
};

export const filePreviewContainerStyles = {
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  maxHeight: "300px",
  position: "relative",
  paddingLeft: "space30",
  paddingRight: "space30",
};
