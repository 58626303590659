import { useAccount, useMsal } from "@azure/msal-react";
import { Document, Close } from "@carbon/icons-react";
import {
  Button,
  Dropdown,
  FileUploaderDropContainer,
  Layer,
  Loading,
  MenuItemDivider,
  RadioButton,
  RadioButtonGroup,
  Select,
  SelectItem,
  SideNavDivider,
  Tag,
  TextArea,
  TextInput,
  FormLabel,
  Theme,
} from "@carbon/react";

import { Contextprovider } from "App";
import {
  ServiceList,
  SeverityList,
  countrycode,
} from "Component/Data/DataJson";
import { GlobalContext } from "ContextApi/GlobalContext";
import { error_toast } from "Toast";
import axios from "axios";
import axiosInstance from "axiosintance";
import CustomButton from "components/button/customButton";
import TicketCreateSuccessModal from "components/modal/TicketCreateSuccessModal/TicketCreateSuccessModal";
import { FieldArray, Form, Formik } from "formik";
import useWindowSize from "hooks/useWindowSize";
import $ from "jquery";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Element } from "react-scroll";
import {
  getAllNotifications,
  getUnseenNotifications,
} from "redux/slices/Notifications/NotificationsSlice";
import { getSupportTickets } from "redux/slices/Support/SupportSlice";
import {
  handleCreateTicketTab,
  headerState,
  handleCreateTicketComplete,
} from "redux/slices/headerSlice";
import timezonesJson from "timezones.json";
import * as Yup from "yup";
import CreateTicketSideNavFormWrapper from "./style";
import TicketCreateModalForFullView from "components/modal/TicketCreateSuccessModal/TicketCreateModalForFullView";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const CreateRequestSchema = Yup.object().shape({
  service: Yup.object().required("Required"),
  timeZone: Yup.string().min(1).required("Required"),
  Subject: Yup.string().required("Required"),
  Description: Yup.string().required("Required"),
  emails: Yup.array(Yup.string().email("Invalid Email").required("Required")),
  phone: Yup.string()
    .required("Required")
    .matches(phoneRegExp, "Phone number is not valid"),
  contactMethod: Yup.string().required("Required"),
});

function CreateTicketSideNavForm({
  renderCancelBtn = true,
  submitBtnContainerClass = "",
  smallViewPadding = false,
  isFullView = false,
}) {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { toggle, settoggle } = useContext(GlobalContext);
  const [error, setError] = useState(null);
  const [attachmentToken, setattachmentToken] = useState("");
  const [isFileUploading, setIsFileUploading] = useState(false);
  const { useralldata } = useContext(Contextprovider);
  const subscriptions = useSelector((state) => state.subscriptions);
  const reduxDispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [technicalDatavalue, settechnicalDatavalue] = useState([]);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [Datavalue, setDatavalue] = useState([]);
  const [multiSelectError, setMultiSelectError] = useState(null);
  const { width } = useWindowSize();
  const context = useContext(GlobalContext);
  const [modalState, setModalState] = useState();
  const [fullViewModalState, setFullViewModalState] = useState();
  const [inputValue, setInputValue] = useState("");
  // const [modalState, setModalState] = useState({
  //   type: "success",
  //   data: "hello testing tis ",
  // });
  const navigate = useNavigate();
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);

  const defaultState = {
    loading: "",
    loadingCall: false,
    attachFile: "",
    call: "",
    postalCode: "",
    switchValue: "1500000007241",
  };
  const [Data, setData] = useState(defaultState);

  const { isSupportTabMaximized, createTicketComplete } =
    useSelector(headerState);
  const [resdata, setresdata] = useState([
    {
      id: "",
      timezone: "",
      status: "",
    },
  ]);

  const subcategories = subscriptions?.data
    .filter(
      (subscription) =>
        subscription.subscription.parent_item === selectedCategory
    )
    .map((subscription) => subscription.subscription.subscription_name);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubcategory("");
  };

  const handleSubcategoryChange = (event) => {
    setSelectedSubcategory(event.target.value);
  };

  const dragAndDropText = "Drag and drop files here or click to \n upload";

  useEffect(() => {
    $(".contact-tile-group").children(2).addClass("flex gap-[10px] w-full");
    $(".contact-tile-group").children(2).children().addClass("w-full");
  }, []);

  useEffect(() => {
    try {
      const formData = new FormData();
      formData.append("method", "customer_support_ticket");
      axios
        .post(process.env.REACT_APP_WORDPRESS_URL, formData)
        .then((response) => {
          if (response) {
            setDatavalue(response.data?.data);
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    try {
      const formData = new FormData();
      formData.append("method", "technical_support_ticket");
      axios
        .post(process.env.REACT_APP_WORDPRESS_URL, formData)
        .then((response) => {
          if (response) {
            settechnicalDatavalue(response.data?.data);
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  function handleFileAttachment(files) {
    if (!files.length) return setError("Attachment is required");

    const maxSize = 500 * 1024; // 500KB

    const file = files[0];
    setSelectedImage(file.name);

    if (!file) return;

    if (file.size > maxSize)
      return setError("The selected image size more than 500KB.");
    else setError(null);

    setIsFileUploading(true);

    axios
      .post(
        `${process.env.REACT_APP_ZENDESKURL}/api/v2/uploads?filename=${file.name}`,
        file,
        {
          headers: {
            "Content-Type": "application/binary",
            Authorization:
              "Basic " +
              btoa(
                `${process.env.REACT_APP_USERINFO}:${process.env.REACT_APP_PASSWORDINFO}`
              ),
          },

          // assuming 99% is upload part

          onDownloadProgress({ progress }) {
            setAttachmentUploadProgress(Math.floor(progress * 100 * 0.01 + 99));
          },

          onUploadProgress({ progress }) {
            setAttachmentUploadProgress(Math.floor(progress * 100 * 0.99));
          },
        }
      )
      .then((res) => {
        setattachmentToken(res.data.upload.token);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsFileUploading(false);
      });
  }

  async function handleSubmit(values) {
    if (values.issuecatagory) {
      values.issuecatagory = Datavalue.find(
        (item) => item.id === values.issuecatagory
      )?.ticket_name;
    }

    const body = {
      // ticket: {
      subject: values.Subject,
      email_ccs: Array.from(new Set(values.emails)).map((email) => ({
        user_email: email,
      })),
      comment: {
        body: values.Description,
        uploads: [`${attachmentToken}`],
        subscriptionlist: selectedCategory && selectedCategory,
        issuecatagory: values?.issuecatagory,
        Issuesubcatagory: values?.Issuesubcatagory,
        Servicesupport: values?.Servicesupport ? values?.Servicesupport : "",
        subcataogrysupport:
          values?.subcataogrysupport && values?.subcataogrysupport,
        servicename: selectedSubcategory && selectedSubcategory,
      },

      requester: {
        // name: useralldata?.userinfo.fullname.split(" ")[0],
        name: "test",
        email: useralldata?.userinfo.email,
      },

      custom_fields: [
        {
          id: 360007343594,
          value: useralldata?.userinfo.fullname,
        },
        {
          id: 360029033513,
          value: values.ScheduleTime,
        },
        {
          id: 360007135793,
          value: values.postalCode + values.phone,
        },
        {
          id: 360007135813,
          value: values.service.label,
        },
        {
          id: 360029045834,
          value: values.timeZone,
        },
        {
          id: 360028569473,
          value: values.contactMethod,
        },
        {
          id: 360029045634,
          value: values.ScheduleDate,
        },
        {
          id: 360028555173,
          value: values.servity.label,
        },
        {
          id: 360029033673,
          value: useralldata?.userinfo.email,
        },
      ],
      ticket_form_id: Data.switchValue,
      // },
    };

    setData({
      ...Data,
      loading: true,
    });

    const usernotification_token = localStorage.getItem("notification_token");
    // const bodydata = new FormData();
    // bodydata.append("body", JSON.stringify(body));

    // for (const [key, value] of bodydata.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    axiosInstance
      .post(
        "https://devnotificationapi.uvation.com/api/tickets/createTicket",
        body
      )
      .then((res) => {
        if (res.status == 200) {
          // success_toast("Request Created Successfully Please check your email");

          // smallViewPadding
          //   ? reduxDispatch(
          //       handleCreateTicketComplete({
          //         toggle: true,
          //         res: res.data.ticket,
          //       })
          //     )
          //   : isSupportTabMaximized
          //   ? setFullViewModalState({ type: "success", data: res.data.ticket })
          //   : setModalState({ type: "success", data: res.data.ticket });

          // reduxDispatch(
          //   getSupportTickets({
          //     email: account?.idTokenClaims?.email,
          //     pageNumber: 1,
          //   })
          // );
          try {
            // axiosInstance
            //   .post(
            //     `${process.env.REACT_APP_NOTIFICATION_URL}/action_notification`,
            //     {
            //       userid: account?.idTokenClaims?.sub,
            //       zendesk_id: res?.data?.audit?.ticket_id,
            //       zendesk_id_time: res?.data?.audit?.created_at,
            //       platform: "uvation support",
            //       token: usernotification_token,
            //     }
            //   )
            //   .then((res) => {
            //     if (res.status == 200) {
            //       //  navigate("/")
            //       // reduxDispatch(handleSupportTab(false));
            //       reduxDispatch(getUnseenNotifications());
            //       reduxDispatch(getAllNotifications());
            //     }
            //   });
          } catch (error) {
            error_toast("Something went wrong");
          }

          // setModalState({ type: "error" });
        } else {
          // console.log(
          //   "res after ticket create>>>>>>>> error wale case me",
          //   res
          // );
          // setModalState({ type: "error" });
        }

        setresdata({
          id: res.data.ticket.id,
          timezone: res.data.ticket.created_at,
          status: res.data.ticket.status,
        });
        setData({
          ...Data,
          loading: false,
        });
      })
      .catch((err) => {
        // setModalState({ type: "error" });
      });
  }

  // function handleAddEmail(event, callback) {
  //   // const hasCompleteEmail = event.target.value.includes(",");
  //   // if (!hasCompleteEmail) return;

  //   const [email, other] = event.target.value.split(",");

  //   try {
  //     Yup.string().email().validateSync(email);
  //     setMultiSelectError(null);
  //     // event.target.value = other;
  //     callback(email);
  //   } catch (error) {
  //     setMultiSelectError("Invalid Email");
  //   }
  // }

  function handleAddEmail(callback) {
    const [email, other] = inputValue?.split(",");

    try {
      Yup.string().email().validateSync(email.trim());
      setMultiSelectError(null);
      callback(email);
      setInputValue("");
    } catch (error) {
      setMultiSelectError("Invalid Email");
    }
  }

  return (
    <>
      <CreateTicketSideNavFormWrapper
        smallView={smallViewPadding}
        className={
          isSupportTabMaximized ? "pt-2 " : smallViewPadding ? "pt-5 " : ""
        }
      >
        {!!modalState && (
          <React.Fragment>
            <TicketCreateSuccessModal
              isOpen={modalState.type === "success"}
              onClose={() => setModalState(null)}
              data={modalState?.data}
            />
          </React.Fragment>
        )}

        {!!fullViewModalState && (
          <TicketCreateModalForFullView
            isOpen={fullViewModalState.type == "success"}
            onClose={() => setFullViewModalState(null)}
            data={fullViewModalState?.data}
          />
        )}

        <Formik
          initialValues={{
            Subject: "",
            Description: "",
            postalCode: countrycode[5].code + countrycode[5].dial_code,
            service: "",
            subscriptionlist: "",
            servity: SeverityList[0],
            emails: [account.idTokenClaims.email],
            contactMethod: "Email",
            ScheduleDate: "",
            timeZone: "",
            phone: "",
            Issuesubcatagory: "",
            issuecatagory: "",
            subcataogrysupport: "",
            Servicesupport: "",
          }}
          validationSchema={
            Data.switchValue === "360001970193"
              ? CreateRequestSchema.omit(["service"])
              : CreateRequestSchema
            // subscriptionlist: Yup.string().required(
            //   "subscriptionlist is required"
            // ),
            // subscriptionlists: Yup.string().required(
            //   "subscriptionlist is required"
            // ),
          }
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values).finally(() => {
              resetForm();
              setSelectedImage(null);
            });
          }}
        >
          {({
            values,
            errors,
            isInitialValid,
            isValid,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            submitForm,
          }) => (
            <Theme theme={context.globalTheme == "white" ? "#f4f4f4" : "g100"}>
              <Form
                className="create-ticket-desktop-form dark:!text-white"
                onSubmit={handleSubmit}
              >
                {(smallViewPadding || isSupportTabMaximized) && (
                  <div
                    className={
                      smallViewPadding
                        ? "form_padding pb-[16px] pt-1"
                        : "form_padding pb-5"
                    }
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5
                      className={
                        smallViewPadding
                          ? "dark:!text-white pt-1 form_heading "
                          : "dark:!text-white pt-0 form_heading"
                      }
                    >
                      Create A Service Request
                    </h5>
                  </div>
                )}
                {isSupportTabMaximized ? (
                  <MenuItemDivider />
                ) : smallViewPadding ? (
                  <SideNavDivider />
                ) : null}
                <Element
                  name="service-request-type"
                  id="service-request-type"
                  className={
                    width > 768
                      ? "pt-2 px-2 "
                      : width <= 768 && smallViewPadding
                      ? "px-2 pt-5"
                      : "px-0 pt-5"
                  }
                >
                  <div
                    className={
                      isSupportTabMaximized
                        ? "px-2 pt-2 "
                        : smallViewPadding
                        ? "px-2  "
                        : width <= 768
                        ? "pr-1"
                        : "pl-6  "
                    }
                  >
                    <h5
                      id="service request type"
                      className="dark:!text-white pb-[4px] "
                      name="service-request-type"
                    >
                      Service Request Type
                    </h5>
                  </div>

                  <div
                    className={
                      isSupportTabMaximized
                        ? ""
                        : smallViewPadding
                        ? "px-2"
                        : width <= 768
                        ? " request-type-container"
                        : "form_padding request-type-container"
                    }
                  >
                    <p
                      className={`dark:!text-white ${
                        !isSupportTabMaximized && !smallViewPadding
                          ? "md:px-2"
                          : width <= 768 && !smallViewPadding
                          ? "pl-0"
                          : ""
                      } ${isSupportTabMaximized && "px-2"} `}
                    >
                      Select the type of case that best suits your needs:
                      Technical or Customer support.
                    </p>

                    <Layer>
                      <RadioButtonGroup
                        name="support-type"
                        defaultSelected="1"
                        className={
                          !smallViewPadding && !isSupportTabMaximized
                            ? "support-type-tile-group "
                            : "support-type-tile-group"
                        }
                        onChange={(val) => {
                          const RequestTypes = Object.freeze([
                            360001970193, 1500000007241,
                          ]);

                          settoggle({
                            ...toggle,
                            requestedTabIndex: Number(val),
                          });

                          setData({
                            ...Data,
                            switchValue: String(RequestTypes[Number(val)]),
                          });

                          setSelectedCategory("");
                          setOpenSubmenu(null);
                          setSelectedSubcategory("");
                        }}
                      >
                        <RadioButton
                          labelText="Technical Request"
                          value="0"
                          id="technical_support"
                          disabled={!subscriptions?.data?.length}
                        />
                        <RadioButton
                          labelText="Customer Service Request"
                          value="1"
                          id="customer_support"
                        />
                      </RadioButtonGroup>
                    </Layer>
                  </div>
                </Element>
                {isSupportTabMaximized ||
                (width <= 768 && !smallViewPadding) ? (
                  <MenuItemDivider className="!mt-10 !mb-2" />
                ) : (
                  <SideNavDivider className="!mt-10 !mb-2" />
                )}
                <Element name="service" id="service">
                  <div
                    className={
                      !smallViewPadding && width > 768 && !isSupportTabMaximized
                        ? "service form_padding"
                        : width <= 768
                        ? "service px-0"
                        : smallViewPadding
                        ? "service pr-1 "
                        : "service !mt-[-8px] !mb-[-20px]"
                    }
                  >
                    <div>
                      <h5
                        id="Service"
                        className={
                          width <= 768 && !smallViewPadding
                            ? "dark:!text-white pb-[4px]"
                            : "dark:!text-white px-4 pb-[4px]"
                        }
                        name="service"
                      >
                        Service
                      </h5>

                      <p
                        className={
                          width <= 768 && !smallViewPadding
                            ? "dark:!text-white "
                            : "dark:!text-white !pl-[16px]"
                        }
                      >
                        Enhance your support experience by designating the
                        platform and severity level, guaranteeing a
                        custom-tailored solution.
                      </p>
                    </div>

                    {Data.switchValue !== "360001970193" && (
                      <Dropdown
                        className={
                          width <= 768 && !smallViewPadding
                            ? "dark:!text-white"
                            : "dark:!text-white form_padding"
                        }
                        id="drop-1"
                        size="lg"
                        items={ServiceList}
                        invalidText="Please select your service"
                        label="Service"
                        titleText="Service*"
                        selectedItem={values.service}
                        onChange={(e) => {
                          setFieldValue("service", e.selectedItem);
                        }}
                        invalid={touched.errors && errors.service}
                      />
                    )}

                    {Boolean(subscriptions?.data?.length) &&
                      Data.switchValue == "360001970193" && (
                        <>
                          <div className="spacing-inner ">
                            <Select
                              id="select-1"
                              size="lg"
                              labelText="Choose Subscription"
                              // helperText="Optional helper text"
                              value={selectedCategory}
                              name="subscriptionlist"
                              onChange={handleCategoryChange}
                              onBlur={handleBlur}
                              required
                              className="form_padding"
                              invalid={
                                errors.subscriptionlist &&
                                touched.subscriptionlist
                              }
                              invalidText={errors.subscriptionlist}
                            >
                              <SelectItem
                                disabled
                                hidden
                                value=""
                                text="Select Subscription"
                              />

                              {Array.from(
                                new Set(
                                  subscriptions?.data.map(
                                    (subscription) =>
                                      subscription.subscription.parent_item
                                  )
                                )
                              ).map((category) => (
                                <SelectItem
                                  key={category}
                                  value={category}
                                  text={category}
                                >
                                  {JSON.stringify(category)}
                                </SelectItem>
                              ))}
                            </Select>
                          </div>

                          {selectedCategory && (
                            <div className="spacing-inner form_padding">
                              <Select
                                id="select-2"
                                labelText="Select Service"
                                // helperText="Optional helper text"
                                value={selectedSubcategory}
                                name="subscriptionlists"
                                onChange={handleSubcategoryChange}
                                onBlur={handleBlur}
                                size="lg"
                                required
                              >
                                <SelectItem
                                  disabled
                                  hidden
                                  value=""
                                  text="Select Service"
                                />

                                {subcategories.map((subcategory) => (
                                  <SelectItem
                                    key={subcategory}
                                    value={subcategory}
                                    text={subcategory}
                                  >
                                    {JSON.stringify(subcategory)}
                                  </SelectItem>
                                ))}
                              </Select>
                            </div>
                          )}

                          {selectedCategory == "Backup Services" && (
                            <div className="spacing-inner form_padding">
                              <Select
                                id="subcataogrysupport"
                                labelText="Service Issue"
                                value={values.subcataogrysupport}
                                name="subcataogrysupport"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                size="lg"
                              >
                                <SelectItem
                                  disabled
                                  hidden
                                  value=""
                                  text="Select Service Issue"
                                />

                                {technicalDatavalue[0].children?.map((item) => (
                                  <SelectItem
                                    key={item?.ticket_name}
                                    value={item?.ticket_name}
                                    text={item?.ticket_name}
                                  >
                                    {JSON.stringify(item?.ticket_name)}
                                  </SelectItem>
                                ))}
                              </Select>
                            </div>
                          )}
                        </>
                      )}
                    <div
                      className={
                        width <= 768 && !smallViewPadding
                          ? "spacing-inner "
                          : "spacing-inner form_padding"
                      }
                    >
                      <Select
                        id="issuecatagory"
                        labelText="Issue Categories"
                        value={values.issuecatagory}
                        name="issuecatagory"
                        size="lg"
                        onChange={(event) => {
                          const id = Datavalue.find(
                            (item) => item?.id == event.target.value
                          )?.id;

                          setOpenSubmenu(id ?? null);
                          setFieldValue("issuecatagory", id);
                        }}
                        onBlur={handleBlur}
                        // required
                      >
                        <SelectItem
                          disabled
                          hidden
                          value=""
                          text="Select Issue Category"
                        />

                        {Datavalue?.map((item) => (
                          <SelectItem
                            key={item?.ticket_name}
                            value={item?.id}
                            text={item?.ticket_name}
                          >
                            {JSON.stringify(item?.ticket_name)}
                          </SelectItem>
                        ))}
                      </Select>
                    </div>
                    {openSubmenu && (
                      <div className="spacing-inner form_padding">
                        <Select
                          id="Issuesubcatagory"
                          labelText="Issue Subcategories"
                          value={values.Issuesubcatagory}
                          name="Issuesubcatagory"
                          onChange={(evt) => {
                            setFieldValue("Issuesubcatagory", evt.target.value);
                          }}
                          onBlur={handleBlur}
                          required
                        >
                          <SelectItem
                            disabled
                            hidden
                            value=""
                            text="Select a subcategory"
                          />

                          {(
                            Datavalue.find((item) => item.id === openSubmenu)
                              ?.children || []
                          )?.map((item) => (
                            <SelectItem
                              key={item?.ticket_name}
                              value={item?.ticket_name}
                              text={item?.ticket_name}
                            >
                              {JSON.stringify(item?.ticket_name)}
                            </SelectItem>
                          ))}
                        </Select>
                      </div>
                    )}
                    <Dropdown
                      id="drop-2"
                      className={
                        width <= 768 && !smallViewPadding ? "" : "form_padding"
                      }
                      ariaLabel="Dropdown"
                      items={
                        Data.switchValue == "360001970193"
                          ? SeverityList
                          : SeverityList.slice(0, 2)
                      }
                      invalidText="Please select your servity"
                      label="Severity"
                      titleText="Severity*"
                      size="lg"
                      // selectedItem={values.servity}
                      onChange={(e) => setFieldValue("servity", e.selectedItem)}
                    />
                  </div>
                </Element>
                {isSupportTabMaximized ||
                (width <= 768 && !smallViewPadding) ? (
                  <MenuItemDivider className="!mt-10 !mb-2" />
                ) : (
                  <SideNavDivider className="!mt-10 !mb-2" />
                )}
                <Element name="subject" id="subject">
                  <div
                    className={
                      !smallViewPadding && width > 768 && !isSupportTabMaximized
                        ? "subject form_padding"
                        : width <= 768 && !smallViewPadding
                        ? "subject p-0 "
                        : smallViewPadding
                        ? "subject p-1"
                        : "subject p-1 !mt-[-8px] !mb-[-20px] "
                    }
                  >
                    <div>
                      <h5
                        name="Subject"
                        className={
                          width <= 768 && !smallViewPadding
                            ? "dark:!text-white  pb-[4px] "
                            : "dark:!text-white form_padding pb-[4px]"
                        }
                      >
                        Subject
                      </h5>
                      {width <= 768 || smallViewPadding ? (
                        <p
                          className={
                            width <= 768 && !smallViewPadding
                              ? "dark:!text-white "
                              : "dark:!text-white form_padding"
                          }
                        >
                          Put your unique stamp on your support request – just
                          choose a subject and describe it in a way that feels
                          right for you.
                        </p>
                      ) : (
                        <p
                          className={
                            width <= 768
                              ? "dark:!text-white "
                              : "dark:!text-white form_padding"
                          }
                        >
                          Put your unique stamp on your support request – just
                          choose a subject and describe it in a way that feels
                          right for
                          <br /> you.
                        </p>
                      )}
                    </div>

                    <div
                      className={
                        width <= 768 && !smallViewPadding ? "" : "form_padding"
                      }
                    >
                      <TextInput
                        invalid={touched.Subject && errors.Subject}
                        invalidText={errors.Subject}
                        onBlur={handleBlur}
                        value={values.Subject}
                        required
                        labelText="Subject*"
                        placeholder="Subject"
                        name="Subject"
                        onChange={handleChange}
                        size="lg"
                      />
                    </div>

                    <div
                      className={
                        width <= 768 && !smallViewPadding
                          ? "relative"
                          : "relative form_padding"
                      }
                    >
                      <span className="absolute top-0 right-0 text-xs form_padding">
                        {values.Description?.trim().length
                          ? values.Description.split(" ").filter(
                              (val) => val.length
                            ).length
                          : 0}
                        / 100
                      </span>

                      <TextArea
                        id="test14"
                        invalid={touched.Description && errors.Description}
                        onBlur={handleBlur}
                        invalidText={errors.Description}
                        required
                        labelText="Description*"
                        placeholder="Description"
                        rows={4}
                        value={values.Description}
                        name="Description"
                        onChange={(event) => {
                          const value = event.target.value;
                          const words =
                            value.split(" ").filter((val) => val.length)
                              .length > 100;

                          if (words) return;
                          handleChange(event);
                        }}
                      />
                    </div>
                  </div>
                </Element>
                {isSupportTabMaximized ||
                (width <= 768 && !smallViewPadding) ? (
                  <MenuItemDivider className="!mt-10 !mb-2" />
                ) : (
                  <SideNavDivider className="!mt-10 !mb-2" />
                )}

                <Element name="contact" id="contact">
                  <div
                    className={
                      !smallViewPadding && width > 768 && !isSupportTabMaximized
                        ? "contact form_padding"
                        : width <= 768 && !smallViewPadding
                        ? "contact p-0"
                        : smallViewPadding
                        ? "contact p-1"
                        : "contact p-1 !mt-[-8px] !mb-[-20px]"
                    }
                  >
                    <div>
                      <h5
                        className={
                          width <= 768 && !smallViewPadding
                            ? "dark:!text-white  pb-[4px]"
                            : "dark:!text-white form_padding pb-[4px]"
                        }
                        name="contact"
                      >
                        Contact
                      </h5>

                      {width <= 768 || smallViewPadding ? (
                        <p
                          className={
                            width <= 768 && !smallViewPadding
                              ? "dark:!text-white "
                              : "dark:!text-white form_padding"
                          }
                        >
                          We're here to assist you. Just share your contact
                          details, and we'll be in touch to provide support or
                          answer any questions you have.
                        </p>
                      ) : (
                        <p
                          className={
                            width <= 768 && !smallViewPadding
                              ? "dark:!text-white "
                              : "dark:!text-white form_padding"
                          }
                        >
                          We're here to assist you. Just share your contact
                          details, and we'll be in touch to provide support or
                          answer any
                          <br /> questions you have.
                        </p>
                      )}
                    </div>

                    <div
                      className={`flex gap-4 ${
                        isSupportTabMaximized
                          ? "items-start form_padding px-4"
                          : smallViewPadding && !isSupportTabMaximized
                          ? "items-start flex-row"
                          : width <= 768 && !smallViewPadding
                          ? ""
                          : "px-4"
                      } ${
                        smallViewPadding || width <= 768
                          ? "flex-col "
                          : "flex-row items-start"
                      }  `}
                    >
                      <FieldArray
                        name="emails"
                        render={(arrayHelpers) => (
                          <div
                            className={`flex w-full flex-col gap-3 ${
                              isSupportTabMaximized
                                ? "w-full "
                                : smallViewPadding
                                ? "px-4"
                                : ""
                            } `}
                          >
                            <TextInput
                              labelText={"CC Emails*"}
                              id="emails"
                              invalidText="A valid email is required"
                              placeholder="Email"
                              value={inputValue}
                              onChange={(event) => {
                                // handleAddEmail(event, arrayHelpers.push);
                                setInputValue(event.target.value);
                              }}
                              onKeyDown={(event) =>
                                event.key == "Enter"
                                  ? handleAddEmail(arrayHelpers.push)
                                  : null
                              }
                              size="lg"
                              invalid={multiSelectError}
                            />

                            <div className="flex gap-x-1 justify-start flex-wrap ">
                              {values.emails.map((email, index) => (
                                <Tag
                                  title={email}
                                  key={email}
                                  filter={index !== 0}
                                  onClose={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  {email}
                                </Tag>
                              ))}
                            </div>
                          </div>
                        )}
                      />

                      <div
                        className={`cds--form-item phone-input-wrapper-container w-full ${
                          isSupportTabMaximized
                            ? "w-full"
                            : !smallViewPadding
                            ? ""
                            : "p-0 px-4"
                        }`}
                      >
                        <div>
                          <FormLabel>Phone*</FormLabel>
                        </div>

                        <div className={`phone-input-wrapper`}>
                          <Dropdown
                            label="+ 00"
                            id="drop-4"
                            size="lg"
                            invalid={touched.phone && errors.phone}
                            style={{ width: "8rem" }}
                            items={countrycode.map((item) => ({
                              label: `${item?.code}${item?.dial_code}`,
                            }))}
                            selectedItem={values.postalCode}
                            onChange={({ selectedItem }) =>
                              setFieldValue("postalCode", selectedItem.label)
                            }
                            onBlur={handleBlur}
                          />

                          <TextInput
                            title="Phone"
                            id="phone_input"
                            size="lg"
                            invalid={touched.phone && errors.phone}
                            placeholder="Phone"
                            value={values.phone}
                            name="phone"
                            invalidText="Invalid phone number"
                            onBlur={handleBlur}
                            className="phone_input"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        width <= 768 && !smallViewPadding ? "" : "form_padding"
                      }
                    >
                      <h6
                        className="dark:!text-[#7e7e7e] !text-[#525252]"
                        style={{
                          fontFamily: "IBM Plex Sans",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "16px",
                          letterSpacing: "0.32px",
                          marginBottom: "-16px",
                        }}
                      >
                        Preferred Contact Method*
                      </h6>
                      <RadioButtonGroup
                        name="contactMethod"
                        defaultSelected="Email"
                        className={"support-type-tile-group px-0"}
                        onChange={(val) => {
                          setFieldValue("contactMethod", val);
                        }}
                        value={values.contactMethod}
                      >
                        <RadioButton
                          labelText="Phone"
                          value="Phone"
                          id="field_phone"
                        />

                        <RadioButton
                          labelText="Email"
                          value="Email"
                          id="field_email"
                        />
                      </RadioButtonGroup>

                      <Dropdown
                        ariaLabel="Dropdown"
                        size="lg"
                        label="Timezone"
                        id="drop-3"
                        invalidText="Please select your timeZone"
                        className="px-px"
                        items={timezonesJson}
                        itemToElement={(timezonesJson) =>
                          timezonesJson ? timezonesJson.text : ""
                        }
                        titleText="Timezone*"
                        selectedItem={values.timeZone}
                        onChange={(e) =>
                          setFieldValue("timeZone", e.selectedItem.text)
                        }
                        invalid={touched.errors && errors.timeZone}
                      />
                    </div>
                  </div>
                </Element>
                {isSupportTabMaximized ||
                (width <= 768 && !smallViewPadding) ? (
                  <MenuItemDivider className="!mt-10 !mb-2" />
                ) : (
                  <SideNavDivider className="!mt-10 !mb-2" />
                )}
                <Element name="attachments" id="attachments">
                  <div
                    className={
                      !smallViewPadding && width > 768 && !isSupportTabMaximized
                        ? "attachments form_padding"
                        : width <= 768 && !smallViewPadding
                        ? "attachments p-0"
                        : smallViewPadding
                        ? "attachments p-1"
                        : "attachments p-1 !mt-[-8px] "
                    }
                  >
                    <div>
                      <h5
                        className={
                          width <= 768 && !smallViewPadding
                            ? "dark:!text-white  pb-[4px]"
                            : "dark:!text-white form_padding pb-[4px]"
                        }
                        name="attachments"
                      >
                        Attachments
                      </h5>

                      <p
                        className={
                          width <= 768 && !smallViewPadding
                            ? "dark:!text-white "
                            : "dark:!text-white form_padding"
                        }
                      >
                        Attach any files or documents that can shed light on
                        your concerns, enabling us to offer more effective
                        support.
                      </p>
                    </div>

                    <div className="uploade_file">
                      <h6
                        className={
                          width <= 768 && !smallViewPadding
                            ? "dark:!text-white  pb-[4px]"
                            : "dark:!text-white form_padding pb-[4px]"
                        }
                      >
                        Upload files
                      </h6>
                      {smallViewPadding ? (
                        <p
                          className={
                            width <= 768 && !smallViewPadding
                              ? "dark:!text-[#7E7E7E] "
                              : "dark:!text-[#7E7E7E] form_padding"
                          }
                        >
                          Max file size is 500kb. Supported file types are{" "}
                          <br /> .jpg and .png.
                        </p>
                      ) : !smallViewPadding && width <= 768 ? (
                        <p
                          className={
                            width <= 768 && !smallViewPadding
                              ? "dark:!text-[#7E7E7E] "
                              : "dark:!text-[#7E7E7E] "
                          }
                        >
                          Max file size is 500kb. Supported file types are .jpg
                          and .png.
                        </p>
                      ) : (
                        <p className="dark:!text-[#7E7E7E] form_padding ">
                          Max file size is 500kb. Supported file types are .jpg
                          and .png.
                        </p>
                      )}
                    </div>

                    <div
                      className={
                        width <= 768 && !smallViewPadding ? "" : "form_padding"
                      }
                      style={{ position: "relative" }}
                    >
                      {/* {isFileUploading && (
                      <div className="loader-overlay">
                        <Loading active withOverlay={false} small />
                      </div>
                    )} */}

                      <FileUploaderDropContainer
                        accept={["image/jpeg", "image/png", "image/jpg"]}
                        innerRef={{
                          current: "[Circular]",
                        }}
                        disabled={isFileUploading}
                        width={"90%"}
                        labelText={
                          width <= 768 || smallViewPadding ? (
                            <div>
                              {dragAndDropText
                                .split("\n")
                                .map((line, index) => (
                                  <React.Fragment key={index}>
                                    {line}
                                    <br />
                                  </React.Fragment>
                                ))}
                            </div>
                          ) : (
                            "Drag and drop files here or click to upload"
                          )
                        }
                        name="attachment"
                        onAddFiles={(_event, { addedFiles }) => {
                          handleFileAttachment(
                            addedFiles.filter((file) => !file.invalidFileType)
                          );
                        }}
                        tabIndex={0}
                        className="dark:!text-[#77A6FF]"
                      />

                      {error && (
                        <p style={{ color: "red", marginTop: "8px" }}>
                          {error}
                        </p>
                      )}
                    </div>

                    {selectedImage && (
                      <div
                        className="form_padding"
                        style={{ position: "relative" }}
                      >
                        <div className="file-uploader-preview-container gap-2">
                          <div className={`flex flex-col w-full gap-3`}>
                            <div className="flex justify-between gap-3">
                              <div className="flex gap-1">
                                <Document />
                                <span>{selectedImage}</span>
                              </div>

                              <span>{attachmentUploadProgress}%</span>
                            </div>

                            <div className="loader-container">
                              <div
                                className="loader"
                                style={{
                                  width: `${attachmentUploadProgress ?? 0}%`,
                                }}
                              />
                            </div>
                          </div>

                          <Button
                            // disabled={Ticketattachment.status === "loading"}
                            renderIcon={Close}
                            hasIconOnly
                            size="sm"
                            kind="ghost"
                            iconDescription="Remove"
                            tooltipPosition="bottom"
                            onClick={() => {
                              setSelectedImage(null);
                              setattachmentToken("");
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </Element>
                <div
                  className={`flex  ${submitBtnContainerClass} ${
                    isSupportTabMaximized
                      ? "justify-end p-2 "
                      : smallViewPadding
                      ? "justify-center "
                      : width <= 768 && !smallViewPadding
                      ? "!pr-0  mt-[48px]"
                      : "justify-end form_padding !mt-[32px]"
                  }`}
                >
                  <div
                    className={smallViewPadding ? "" : "md:px-[8px]"}
                    style={{
                      display: "flex",
                      justifyContent: !smallViewPadding
                        ? "flex-end"
                        : "flex-end",
                      alignItems: "center",
                      width: width <= 768 && !smallViewPadding ? "" : "100vw",
                      marginRight: width <= 475 && !smallViewPadding && "19px",
                    }}
                  >
                    <CustomButton
                      handleClick={() =>
                        isSupportTabMaximized || smallViewPadding
                          ? reduxDispatch(handleCreateTicketTab(false))
                          : navigate("/request")
                      }
                      style={{
                        width: smallViewPadding
                          ? "180px"
                          : width <= 768
                          ? "162px"
                          : "144px",
                        backgroundColor: "#393939",
                        paddingBottom: smallViewPadding && "30px",
                      }}
                      text={"Cancel"}
                      size="lg"
                    />
                  </div>

                  <div
                    className={smallViewPadding ? "" : "md:px-[10px]"}
                    style={{
                      display: "flex",
                      justifyContent: !smallViewPadding
                        ? "flex-end"
                        : "flex-end",
                      alignItems: "center",
                      width: smallViewPadding ? "100vw" : "",
                      marginRight:
                        smallViewPadding ||
                        width <= 768 ||
                        isSupportTabMaximized
                          ? "0px"
                          : "8px",
                    }}
                  >
                    <CustomButton
                      fullWidth={smallViewPadding}
                      style={{
                        width: smallViewPadding
                          ? "180px"
                          : width <= 768
                          ? "162px"
                          : "144px",
                        paddingBottom: smallViewPadding && "30px",
                      }}
                      disabled={!isValid || isFileUploading}
                      isLoading={Data.loading}
                      type="submit"
                      text={"Submit"}
                    />
                  </div>
                </div>
              </Form>
            </Theme>
          )}
        </Formik>
      </CreateTicketSideNavFormWrapper>
    </>
  );
}

export default CreateTicketSideNavForm;
