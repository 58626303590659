import axios from "axios";

const FetchAll_document = async (azureId) => {
  const Documentdata = new FormData();
  Documentdata.append("method", "Traning_document_for_admins");
  const response = axios.post(
    process.env.REACT_APP_WORDPRESS_URL,
    Documentdata
  );
  return response;
};

const FetchFilterOptions = async () => {
  const Documentdata = new FormData();
  Documentdata.append("method", "filter_data_options");
  const response = axios.post(
    process.env.REACT_APP_WORDPRESS_URL,
    Documentdata
  );
  return response;
};

const FilterData = async (payload) => {
  const data = { method: "Training_document_for_admins", ...payload };
  const response = await axios.post(
    process.env.REACT_APP_WORDPRESS_URL_NEW,
    data
  );
  return response;
};

const KnowledgeFilterData = async (payload) => {
  const data = { method: "articles_data", ...payload };
  const response = await axios.post(
    process.env.REACT_APP_WORDPRESS_URL_NEW,
    data
  );
  return response;
};

const FaqAndGuidesData = async () => {
  const Documentdata = new FormData();
  Documentdata.append("method", "support_faqs_data");

  const response = await axios.post(
    process.env.REACT_APP_WORDPRESS_URL,
    Documentdata
  );
  return response;
};

export const Single_document = async (payload) => {
  const Documentdata = new FormData();
  Documentdata.append("method", "Traning_document_for_admins_single_page");
  Documentdata.append("slug", payload);

  const response = axios.post(
    process.env.REACT_APP_WORDPRESS_URL,
    Documentdata
  );
  return response;
};

export const FetchProductData = async (data) => {
  const DocumentData = new FormData();
  DocumentData.append("method", "latest_product_Faqs");
  DocumentData.append("perpage", data.perpage);
  DocumentData.append("paged", data.page);

  const response = axios.post(
    process.env.REACT_APP_WORDPRESS_URL,
    DocumentData
  );
  return response;
};

export const FetchServiceData = async (data) => {
  const DocumentData = new FormData();
  DocumentData.append("method", "latest_service_Faqs");
  DocumentData.append("perpage", data.perpage);
  DocumentData.append("paged", data.page);

  const response = axios.post(
    process.env.REACT_APP_WORDPRESS_URL,
    DocumentData
  );
  return response;
};

export const FetchTotalServiceAndProducts = async (data) => {
  const DocumentData = new FormData();
  DocumentData.append("method", "chatbot_faqs");
  DocumentData.append("page_num", data.page_num);
  DocumentData.append("post_per_page", data.post_per_page);
  DocumentData.append("service_per_page", data.service_per_page);
  DocumentData.append("service_page_num", data.service_page_num);

  const response = axios.post(
    process.env.REACT_APP_WORDPRESS_URL,
    DocumentData
  );
  return response;
};

export default {
  FetchAll_document,
  FetchFilterOptions,
  FilterData,
  KnowledgeFilterData,
  FaqAndGuidesData,
  FetchProductData,
  FetchServiceData,
  FetchTotalServiceAndProducts,
};
