import * as Yup from "yup";
const phoneRegExp1 =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const MessageSchema = Yup.object().shape({
  messages: Yup.string()
    .required("Message is required")
    .max(500, "Message cannot be longer than 500 characters"),
});
export const tagSchema = Yup.object().shape({
  tags: Yup.array()
    .of(
      Yup.string().email("Invalid email address").required("Email is required")
    )
    .required("At least one email address is required"),
});

export const support_call_schema = Yup.object().shape({
  phone_number: Yup.string()
    .required("Required")
    .matches(phoneRegExp1, "Phone number is not valid"),
  country_code: Yup.string().notRequired(),
});
