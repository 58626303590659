import styled from "styled-components";

export const MainWrapperNew = styled.div`
  display: flex;
  align-items: center;
  .successmodal.is-visible {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: auto;
    left: auto;
  }

  .successmodal {
    display: block;
    width: unset !important;
    height: unset !important;
    background: unset !important;
  }

  .cds--modal-container {
    background-color: #defbe6;
    border-left: 3px solid var(--Support-support-success, #24a148);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: 155px;
    width: "max-content";
    padding: "0 24px 0 12px";

    @media screen and (max-width: 768px) {
      width: 346px;
      height: max-content;
    }

    .cds--modal-header {
      .cds--modal-close__icon {
        fill: #161616 !important;
      }
    }
  }

  .cds--modal-content {
    margin-block-end: 0;
    padding-block-start: 0rem;

    svg {
      fill: #24a148;
    }
    .header_modal {
      display: flex;
      flex-direction: column;
      align-items: center;

      h4 {
        color: #161616;
        font-family: "IBM Plex Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        padding-bottom: 9px;
      }
    }

    .sub_heading {
      h4 {
        color: #161616;
        text-align: center;
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.16px;
      }

      .font-semibold {
        color: var(--Accessibility-Black, #000);
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.014px;
      }
    }
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  align-items: center;

  .cds--modal.is-visible {
    display: flex;
    align-items: start;
    padding-top: 151px;
  }
  .cds--modal-close svg {
    fill: ${(props) =>
      props.theme.colorScheme === "white" ? "#2570fe" : "white "};
  }

  .cds--modal-container {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @media screen and (max-width: 768px) {
      width: 346px;
      height: max-content;
      margin-top: 151px;
    }
  }

  .cds--modal-content {
    padding-inline-end: 0rem;
    padding-inline-start: 0rem;
    padding-bottom: 0;

    svg {
      fill: #2570fe;
    }

    .header_modal {
      background-color: ${(props) =>
        props.theme.colorScheme === "white" ? "#f4f4f4" : "#161616"};
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px 0;
      margin-top: -0.5rem;
    }

    .sub_heading {
      padding: 16px 0.5rem;
      h4 {
        font-family: Arial;
        font-size: 14px;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.16px;
        background-color: ${(props) =>
          props.theme.colorScheme === "white" ? "#fff" : "#262626"};
      }
    }
  }

  .cds--modal-header {
    grid-column: 1/-1;
    grid-row: 1/1;
    margin-block-end: 0rem;
    padding-block-start: 0rem;
    padding-inline-end: 0rem;
    padding-inline-start: 0rem;

    .cds--modal-close__icon {
      fill: ${(props) =>
        props.theme.colorScheme === "white" ? "#161616" : "white "};
    }
  }
`;

export const MainContentWrapper = styled.div`
  padding: 1rem;
  background-color: ${(props) =>
    props.theme.colorScheme === "white" ? "#f4f4f4" : "#161616"};

  .request_number {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    h6 {
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
    }
  }

  .info {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    gap: 5px;
    flex-direction: column;

    h6 {
      /* color: var(--text-text-helper, var(--Text-text-helper, #6f6f6f)); */
      /* Utility styles/label-01 */
      font-family: IBM Plex Sans;
      /* font-size: 12px; */
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.32px;
      margin: 0;
    }
  }

  .font-bold {
    font-weight: 900;
  }

  .content {
    font-size: 14px;
    font-weight: 300;
  }

  .title {
    font-size: 12px;
    /* color: #6f6f6f; */
    color: ${(props) =>
      props.theme.colorScheme === "white" ? "#6f6f6f" : "#c6c6c6"};
    font-weight: 400;
  }
`;

export const FullViewModalWrapper = styled.div`
  .successmodal.is-visible {
    display: flex;
    align-items: start;
    padding-top: 151px;

    .successmodal {
      display: block;
      width: unset !important;
      height: unset !important;
      background: unset !important;
    }
  }

  .successmodal .cds--modal-container {
    /* background-color: #defbe6; */
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "white" : "   #262626"};

    /* border-left: 3px solid var(--Support-support-success, #24a148); */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    /* padding: 0px 12px 30px 24px; */
    /* width: max-content; */
    width: unset !important;
    height: unset !important;

    .cds--modal-header {
      .close_icon {
        display: none;
      }
    }
    .cds--modal-content {
      margin-block-end: 0;
      padding-block-start: 0rem;
      .close_icon {
        display: flex;
        justify-content: flex-end;
        /* padding-top: 12px; */
        padding: 16px 12px 0px 24px;

        background-color: ${(props) =>
          props.theme.colorScheme == "white" ? "#f4f4f4" : "   #161616"};

        cursor: pointer;
        svg {
          fill: ${(props) =>
            props.theme.colorScheme === "white" ? "#161616" : "white "};
        }
      }

      svg {
        /* fill: #24a148; */
        fill: #2570fe;
      }
      .header_modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -16px;
        background-color: ${(props) =>
          props.theme.colorScheme == "white" ? "#f4f4f4" : "   #161616"};
        padding: 0px 12px 16px 24px;

        h4 {
          color: ${(props) =>
            props.theme.colorScheme == "white" ? "#161616" : "   #fff"};
          font-family: "IBM Plex Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          padding-bottom: 9px;
        }
      }

      .sub_heading {
        h4 {
          color: ${(props) =>
            props.theme.colorScheme == "white" ? "#161616" : "   #fff"};
          text-align: center;
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.16px;
        }

        .font-semibold {
          color: ${(props) =>
            props.theme.colorScheme == "white" ? "#161616" : "   #fff"};
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: -0.014px;
        }
      }
    }
  }
`;
