import styled from "styled-components";
import { blue60 } from "@carbon/colors";
const AccountWrapper = styled.div`
  .account_panel {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    max-height: 240px;
    inline-size: 15rem;
    background-color: #393939;
    border: unset !important;
    color: #fff;

    .top {
      align-items: center;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      h6 {
        color: var(--text-text-primary, var(--Text-text-primary, #fff));

        /* Fixed heading styles/heading-compact-01 */
        font-family: IBM Plex Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
      }

      .left {
        align-items: center;
        display: flex;
        color: #7ca9fe;
        button {
          background-color: #393939;
          outline: unset !important;
          outline-offset: unset !important;
          outline-color: unset !important;
          box-shadow: none !important;
          svg {
            fill: #7ca9fe;
          }
        }
      }
      .right {
        width: 64px;
        height: 64px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(141, 141, 141, 0.2);
        overflow: hidden;
        img {
          object-fit: cover;
        }
      }
    }
    .logout_button {
      max-width: unset;
      width: 100%;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 16px;
    li {
      button {
        color: #fff;
      }
    }
  }
`;
export default AccountWrapper;
