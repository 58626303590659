import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { AttachIcon } from "@twilio-paste/icons/esm/AttachIcon";
import { memoize } from "proxy-memoize";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { attachFiles } from "redux/reducers/chat.reducer";
import { validateFiles } from "utils/validateFiles";
import {
  attachIconContainerStyles,
  attachTitleStyles,
  containerStyles,
  dropAreaStyles,
} from "./styles/AttachFileDropArea.styles";

const selector = memoize((state) => ({
  attachedFiles: state.chat.attachedFiles || [],
  fileAttachmentConfig: state.config.fileAttachment,
}));

export const AttachFileDropArea = ({ children }) => {
  const { attachedFiles, fileAttachmentConfig } = useSelector(selector);

  const dispatch = useDispatch();

  const [isDragging, setIsDragging] = useState(false);

  const containsFile = (dataTransfer) =>
    Boolean(
      dataTransfer?.types?.find(
        (dataTransferType) => dataTransferType === "Files"
      )
    );

  const handleDrop = (event) => {
    event.preventDefault();

    if (containsFile(event.dataTransfer)) {
      const { files } = event.dataTransfer;

      if (files && files.length) {
        const validFiles = validateFiles(
          Array.from(files),
          dispatch,
          attachedFiles,
          fileAttachmentConfig
        );
        dispatch(attachFiles(validFiles));
      }
    }

    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();

    if (isDragging) {
      return;
    }

    if (!containsFile(event.dataTransfer)) {
      return;
    }

    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();

    const relatedTarget =
      event.nativeEvent.relatedTarget || event.nativeEvent.toElement;
    if (
      !relatedTarget ||
      // Prevent event from being fired when dragging over a child (or child ShadowDOM element)
      (!event.currentTarget.contains(relatedTarget) &&
        !(relatedTarget.getRootNode() instanceof ShadowRoot))
    ) {
      setIsDragging(false);
    }
  };

  return (
    <Box
      {...containerStyles}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {isDragging && (
        <Box {...dropAreaStyles}>
          <Box {...attachIconContainerStyles}>
            <AttachIcon decorative={true} size="sizeIcon60" />
          </Box>
          <Text as="p" {...attachTitleStyles}>
            Drop a file or image here
          </Text>
        </Box>
      )}
      {children}
    </Box>
  );
};
