import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllticket } from "./allTicketsService";
export const getAllticketData = createAsyncThunk(
  "support/Tickets",
  async (userId, thunkAPI) => {
    try {
      return await getAllticket();
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  Allticketsinfo: {
    error: null,
    status: "idle", // succeeded || idle || failed || loading
    data: [],
    message: "",
    isloading: false,
  },
};

export const ticketstatusSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    handleTicketFilter: (state) => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllticketData.fulfilled, (state, { payload }) => {
      state.Allticketsinfo.data = payload || [];
      state.Allticketsinfo.error = null;
      state.Allticketsinfo.status = "succeeded";
    });
    builder.addCase(getAllticketData.pending, (state, payload) => {
      state.Allticketsinfo.status = "loading";
      // state.Allticketsinfo.data = null;
      state.Allticketsinfo.error = null;
    });
    builder.addCase(getAllticketData.rejected, (state, { payload }) => {
      state.Allticketsinfo.status = "failed";
      state.Allticketsinfo.data = null;
      state.Allticketsinfo.error = payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = ticketstatusSlice.actions;

export default ticketstatusSlice.reducer;
