import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import axiosInstance from "axiosintance";
const msalInstance = new PublicClientApplication(msalConfig);
let azureId = msalInstance.getAllAccounts()[0]?.idTokenClaims?.sub;

const RecommandedArtical = async () => {
  const Documentdata = new FormData();

  Documentdata.append("method", "recommended_articles");
  const response = axios.post(
    process.env.REACT_APP_WORDPRESS_URL,
    Documentdata
  );
  return response;
};

export default {
  RecommandedArtical,
};

export const SingleRecommandedArtical = async (payload) => {
  const Documentdata = new FormData();

  Documentdata.append("method", "recommended_articles_single_page");
  Documentdata.append("slug", payload);

  const response = axios.post(
    process.env.REACT_APP_WORDPRESS_URL,
    Documentdata
  );
  return response;
};

export const getPlans = async () => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_USPURL}/subscription/api/user_subscription?customer_id=${azureId}`
  );
  return response;
};
