export const separatorContainerStyles = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "space30",
    marginBottom: "space60"
};

export const getSeparatorLineStyles = (separatorType) => ({
    flex: "1",
    borderStyle: "solid",
    borderColor: separatorType === "new" ? "colorBorderError" : "colorBorderWeak",
    borderWidth: "borderWidth0",
    borderTopWidth: "borderWidth10"
});

export const getSeparatorTextStyles = (separatorType) => ({
    fontSize: "fontSize20",
    marginLeft: "space40",
    marginRight: "space40",
    color: separatorType === "new" ? "colorTextError" : "colorText"
});
