import styled from "styled-components";
const ModalWrapper = styled.div`
  @media screen and (max-width: 768px) {
    .cds--modal.is-visible {
      display: flex;
      align-items: start;
      justify-content: center;
    }
    .cds--modal {
      display: block;
    }

    .cds--modal-content {
      padding-block-start: 0rem;
      padding-block-end: 24px;
    }

    .cds--tile {
      min-block-size: 0rem;
    }
  }

  .cds--modal-content {
    p {
      padding-inline-end: 0;
    }
  }

  .theme-modal {
    .cds--modal-container {
      background-color: ${(props) =>
        props.theme.colorScheme == "white" ? " white" : " #262626 "};

      @media screen and (max-width: 768px) {
        margin-top: 192px;
        width: 320px;
        height: max-content;
      }
      .cds--modal-header__heading {
        font-family: "IBM Plex Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        color: ${(props) =>
          props.theme.colorScheme == "white" ? "#161616" : "#fff"};
      }

      .cds--modal-container--sm .cds--modal-content > p {
        padding-inline-end: 0 !important;
      }
      .tile {
        background-color: ${(props) =>
          props.theme.colorScheme == "white" ? " #f4f4f4" : " #161616 "};
      }

      .cds--tile--is-selected {
        border: unset;
        border-bottom: 1px solid #8d8d8d;
      }

      .cds--tile--selectable {
        border: unset;
        border-bottom: 1px solid #8d8d8d;
      }

      .cds--tile {
        display: flex;
        align-items: center;

        .cds--tile-content {
          display: flex;
          gap: 8px;
          align-items: center;
        }
        .cds--tile__checkmark {
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.014px;
      text-align: left;
      margin-bottom: 16px;
    }
  }
`;
export default ModalWrapper;
