import { Phone, PhoneFilled, PhoneOutgoing } from "@carbon/icons-react";
import { Button, Dropdown, Layer, TextInput, Theme } from "@carbon/react";
import { Contextprovider } from "App";
import { countrycode } from "Component/Data/DataJson";
import { error_toast, success_toast } from "Toast";
import { support_call_schema } from "Valdation/Validation";
import axiosInstance from "axiosintance";
import { Formik } from "formik";
import _ from "lodash";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleBrowser, headerState } from "redux/slices/headerSlice";
import CallTabWrapper from "./style";
import { getUnseenNotifications } from "redux/slices/Notifications/NotificationsSlice";
import { GlobalContext } from "ContextApi/GlobalContext";

const CallTab = () => {
  const { subscriptions } = useSelector((state) => state);
  const { useralldata } = useContext(Contextprovider);
  const { browserCall } = useSelector(headerState);
  const context = useContext(GlobalContext);

  const dispatch = useDispatch();
  const makeCall = async (values) => {
    try {
      let { data } = await axiosInstance.post(
        `${process.env.REACT_APP_NOTIFICATION_URL}/public/call-back`,
        {
          phoneNo: values.country_code.slice(3) + "-" + values.phone_number,
          user_id: useralldata?.userinfo?.userid,
          platform: "uvation support",
        }
      );
      dispatch(getUnseenNotifications());
      success_toast(data.message || "Successfully registered the callback");
    } catch (err) {
      error_toast(err);
    }
  };

  return (
    <div className="flex justify-center p-4 mt-8 w-full">
      <h3 className="text-base">Coming Soon</h3>
    </div>
  );

  return (
    <>
      <Theme theme={context.globalTheme == "white" ? "white" : "g90"}>
        <CallTabWrapper className="dark:!text-white  h-full">
          {!!subscriptions?.data?.length ? (
            // || true
            <Formik
              initialValues={{
                country_code: countrycode[5].code + countrycode[5].dial_code,
                phone_number: "",
              }}
              onSubmit={(values) => {
                makeCall(values);
              }}
              validationSchema={support_call_schema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
              }) => (
                <>
                  <div>
                    <>
                      <div className="chose_number">
                        <div className="cds--telephone">
                          <div style={{ marginBottom: "10px" }}>
                            <h2>Choose Your Country</h2>
                          </div>
                          <p>
                            Please select the phone number of the country you
                            are currently located in when seeking support within
                            your respective country.
                          </p>
                          <div className="country_code">
                            <Dropdown
                              label="+ 00"
                              id="drop-4"
                              style={{ width: "8rem" }}
                              items={countrycode.map((item) => ({
                                label: `${item?.code}${item?.dial_code}`,
                              }))}
                              selectedItem={values.country_code}
                              onChange={({ selectedItem }) =>
                                setFieldValue(
                                  "country_code",
                                  selectedItem.label
                                )
                              }
                              onBlur={handleBlur}
                            />
                            <TextInput
                              id="test17"
                              // invalidText={errors.phone_number}
                              // invalid={touched.phone_number && errors.phone_number}
                              placeholder="(619) 567 3200"
                              name="phone_number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone_number}
                            />
                          </div>
                          <div style={{ color: "#da1e28", marginTop: "5px" }}>
                            <span style={{ fontSize: "12px" }}>
                              {errors.phone_number}
                            </span>
                          </div>
                        </div>
                        <div
                          className="mainheading_recent"
                          style={{ margin: "10px 0" }}
                        >
                          <h4>Recent</h4>
                        </div>
                      </div>
                      <hr style={{ border: "1px solid #8D8D8D" }} />
                      <div className="scrool_class">
                        <div className="recentcall_history">
                          <ul>
                            <span>
                              <PhoneOutgoing size={16} />
                            </span>
                            <li>
                              <h6>Support Center (US)</h6>
                              <span>10/30/23, 1:08 PM</span>
                            </li>
                          </ul>
                        </div>
                        <div className="recentcall_history">
                          <ul>
                            <span>
                              <PhoneOutgoing size={16} />
                            </span>
                            <li>
                              <h6>Support Center (US)</h6>
                              <span>10/30/23, 1:08 PM</span>
                            </li>
                          </ul>
                        </div>

                        <div className="recentcall_history">
                          <ul>
                            <span>
                              <PhoneOutgoing size={16} />
                            </span>
                            <li>
                              <h6>Support Center (US)</h6>
                              <span>10/30/23, 1:08 PM</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>

                    <div
                      style={{
                        position: "absolute",
                        bottom: "0",
                        width: "100%",
                      }}
                    >
                      <Button
                        style={{ width: "50%" }}
                        renderIcon={PhoneFilled}
                        type="submit"
                        kind="secondary"
                        onClick={handleSubmit}
                        disabled={!_.isEmpty(errors) || !values.phone_number}
                      >
                        Phone Call
                      </Button>

                      <Button
                        style={{ width: "50%" }}
                        renderIcon={PhoneFilled}
                        kind="primary"
                        onClick={() => {
                          dispatch(handleBrowser(!browserCall));
                        }}
                      >
                        Browser Call
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Formik>
          ) : (
            <div>
              <div className="p-4 claatalk_agent">
                <div style={{ marginBottom: "10px" }}>
                  <h4>Talk with an Agent</h4>
                </div>
                <p>
                  {" "}
                  Need assistance? Our dedicated support team is just a phone
                  call away. Feel free to reach out to our support hotline:
                </p>
                <br />
                <br />
                <p>
                  {" "}
                  Our friendly and knowledgeable support agents are available to
                  help you with any questions or issues you may have. We're here
                  to provide you with the best possible assistance. Your
                  satisfaction is our priority.
                </p>
              </div>
              <div className="callBtn p-4">
                <Button renderIcon={Phone} href="tel:1-123-456-7890">
                  Call Now ((619) 815-8740)
                </Button>
              </div>
            </div>
          )}
        </CallTabWrapper>
      </Theme>
    </>
  );
};

export default CallTab;
