import React from "react";
import * as icons from "@carbon/icons-react";
const Icon = ({ iconName, className, size }) => {
  try {
    const icon = React.createElement(icons[iconName], {
      size: parseInt(size),
    });
    return <span className={className || null}>{icon}</span>;
  } catch (error) {
    console.error(error);
  }
};
export default Icon;
