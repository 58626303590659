import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updatedEmailinfo } from "./UpdateemailService";
export const UpdateInfoEmailcc = createAsyncThunk(
  "update/email",
  async (payload, thunkAPI) => {
    try {
      return await updatedEmailinfo(payload, thunkAPI);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response?.data || err.message);
    }
  }
);

const initialState = {
  UpdateCC: {
    error: null,
    status: "idle", // succeeded || idle || failed || loading
    data: [],
    message: "",
    isloading: false,
  },
};

export const createCCemail = createSlice({
  name: "update",
  initialState,
  reducers: {
    // Define any reducers here if needed
  },
  extraReducers: (builder) => {
    builder.addCase(UpdateInfoEmailcc.fulfilled, (state, { payload }) => {
      state.UpdateCC.data = payload || [];
      state.UpdateCC.error = null;
      state.UpdateCC.status = "succeeded";
    });
    builder.addCase(UpdateInfoEmailcc.pending, (state) => {
      state.UpdateCC.status = "loading";
      state.UpdateCC.error = null;
    });
    builder.addCase(UpdateInfoEmailcc.rejected, (state, { payload }) => {
      state.UpdateCC.status = "failed";
      state.UpdateCC.error = payload;
    });
  },
});

export default createCCemail.reducer;
