import { Text } from "@twilio-paste/core/text";
import { Alert } from "@twilio-paste/core/alert";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { removeNotification } from "redux/reducers/notification.reducer";

export const NotificationBarItem = ({
  dismissible,
  id,
  message,
  onDismiss,
  timeout,
  type,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let timer;
    if (dismissible || Number.isInteger(timeout)) {
      timer = setTimeout(() => {
        dispatch(removeNotification({ id }));
        if (onDismiss) {
          onDismiss();
        }
      }, timeout ?? 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, timeout, id, onDismiss]);

  const dismissNotification = () => {
    if (onDismiss) {
      onDismiss();
    }
    dispatch(removeNotification({ id }));
  };

  return (
    <Alert
      role="status"
      aria-live="polite"
      variant={type}
      onDismiss={dismissible ? dismissNotification : undefined}
      data-test="alert-message"
    >
      <Text data-test="alert-message-text" as="span">
        {message ?? ""}
      </Text>
    </Alert>
  );
};
