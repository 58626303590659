import { CheckmarkFilled } from "@carbon/icons-react";
import { Modal } from "@carbon/react";
import { MainContentWrapper, MainWrapper, MainWrapperNew } from "./style.js";
import { useMemo, useReducer } from "react";
import useWindowSize from "hooks/useWindowSize.js";
import { useSelector } from "react-redux";
import { headerState } from "redux/slices/headerSlice/index.js";

const TicketCreateSuccessModal = ({ data, isOpen, onClose }) => {
  const { width } = useWindowSize();
  const { ticketCompleteData, isFullView } = useSelector(headerState);

  const service = useMemo(
    () => data?.custom_fields?.find(({ id }) => id === 360007135813)?.value,
    [data]
  );

  const severity = useMemo(
    () => data?.custom_fields?.find(({ id }) => id === 360028555173)?.value,
    [data]
  );

  if (!isOpen || !data) return null;

  // return (
  //   <MainWrapperNew>
  //     <Modal
  //       open={isOpen}
  //       className="successmodal"
  //       passiveModal
  //       onRequestClose={() => onClose(false)}
  //       size="sm"
  //     >
  //       <div className="header_modal">
  //         <CheckmarkFilled size={32} />

  //         <h4 className="">Request Completed!</h4>
  //       </div>

  //       <div>
  //         <div className="sub_heading">
  //           {width <= 425 ? (
  //             <h4>
  //               Your <b className="font-semibold">Request # {data.id}</b> has
  //               been forwarded to <br />
  //               our customer services team and has been added to the list of
  //               open requests.
  //             </h4>
  //           ) : (
  //             <h4>
  //               Your <b className="font-semibold">Request # {data.id}</b> has
  //               been forwarded to our customer services team and has been added
  //               to <br /> the list of open requests.
  //             </h4>
  //           )}
  //         </div>
  //       </div>
  //     </Modal>
  //   </MainWrapperNew>
  // );

  return (
    <MainWrapper className="main_modal">
      <Modal
        open={isOpen}
        passiveModal
        onRequestClose={() => onClose(false)}
        size="sm"
      >
        <div className="header_modal">
          <CheckmarkFilled size={32} />

          <h4 className="text-lg font-bold ">Request Completed!</h4>
        </div>

        <div className="px-3 bg-white dark:bg-[#262626]">
          <div className="sub_heading">
            {width <= 425 ? (
              <h4>
                Your <b className="font-semibold">Request # {data.id}</b> has
                been forwarded to our customer services team and has been added
                to the list of open requests.
              </h4>
            ) : (
              <h4>
                Your <b className="font-semibold">Request # {data.id}</b> has
                been forwarded to our customer services team and has been added
                to <br /> the list of open requests.
              </h4>
            )}
          </div>

          {/* <MainContentWrapper>
            <div className="mb-2">
              <h6 className="font-bold">Request #{data.id}</h6>
            </div>

            <div className="info">
              <h6 className="title">Type</h6>
              <h6 className="content">Technical Support</h6>
            </div>

            <div className="info">
              <h6 className="title">Service</h6>
              <h6 className="content">{service}</h6>
            </div>

            <div className="info">
              <h6 className="title">Severity</h6>
              <h6 className="content">{severity}</h6>
            </div>

            <div className="info">
              <h6 className="title">Subject</h6>
              <h6 className="content">{data.subject}</h6>
              <h6 className="content">{data.description}</h6>
            </div>
          </MainContentWrapper> */}
        </div>
      </Modal>
    </MainWrapper>
  );
};

export default TicketCreateSuccessModal;
