export const outerContainerStyles = {
  //   position: "fixed",
  //   bottom: "space50",
  //   right: "space60",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  height: "inherit",
  width: "100%",
};

export const innerContainerStyles = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  // backgroundColor: "colorBackgroundBody",
  width: "100%",
};
