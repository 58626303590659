import React, { useState, useContext } from "react";
import { BrowsercallWrapper } from "./style";
import {
  Apps,
  CustomerService,
  MicrophoneFilled,
  PhoneFilled,
  RecentlyViewed,
  RecordingFilled,
  RecordingFilledAlt,
} from "@carbon/icons-react";
import { Column, FlexGrid, Row, TextInput } from "@carbon/react";
import { useSelector } from "react-redux";
import { headerState } from "redux/slices/headerSlice";
import { GlobalContext } from "ContextApi/GlobalContext";
import { Theme } from "@carbon/react";

const Browsercall = () => {
  const [dialped, setdialped] = useState(false);
  const [calltoConnect, setcalltoConnect] = useState(false);
  const [Profilecall, setprofilecall] = useState(true);
  const [Phonenumber, setPhonenumber] = useState("");
  const context = useContext(GlobalContext);
  const { supportTabIndex, isSupportTabMaximized, createTicketTab } =
    useSelector(headerState);
  const handleButtonClick = (value) => {
    setPhonenumber((prevNumber) => prevNumber + value);
  };
  return (
    <BrowsercallWrapper className="dark:!bg-unset">
      <div className="main_divbrowser shadow-unset dark:!shadow-unset  h-full">
        <h6 className="text-white p-3">Coming Soon</h6>
        {/* Header */}
        <Column>
          <div className="main_headericon">
            {calltoConnect && (
              <div className="connect_call">
                <div className="heading_main">
                  <h6>{Phonenumber}</h6>
                  <span>connecting...</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    marginTop: "40px ",
                  }}
                >
                  <div className="icon_Data">
                    <div className="icon">
                      <RecentlyViewed size={24} />
                      <br />
                    </div>
                    <span>Recent</span>
                  </div>
                  <div className="icon_Data">
                    <div className="icon">
                      <Apps size={24} />
                      <br />
                    </div>
                    <span>dial</span>
                  </div>
                  <div className="icon_Data">
                    <div className="icon">
                      <MicrophoneFilled size={24} />
                      <br />
                    </div>
                    <span>mute</span>
                  </div>
                </div>
              </div>
            )}
            {!!dialped && (
              <div class="dial-pad">
                <div class="display">{Phonenumber}</div>
                <div class="buttons">
                  <button class="number" onClick={() => handleButtonClick("1")}>
                    1<br />
                    <span>ABC</span>
                  </button>
                  <button class="number" onClick={() => handleButtonClick("2")}>
                    2<br />
                    <span>DEF</span>
                  </button>
                  <button class="number" onClick={() => handleButtonClick("3")}>
                    3<br />
                    <span>GHI</span>
                  </button>
                  <button class="number" onClick={() => handleButtonClick("4")}>
                    4<br />
                    <span>JKL</span>
                  </button>
                  <button class="number" onClick={() => handleButtonClick("5")}>
                    5<br />
                    <span>MNO</span>
                  </button>
                  <button class="number" onClick={() => handleButtonClick("6")}>
                    6<br />
                    <span>PQRS</span>
                  </button>
                  <button class="number" onClick={() => handleButtonClick("7")}>
                    7<br />
                    <span>TUV</span>
                  </button>
                  <button class="number" onClick={() => handleButtonClick("8")}>
                    8<br />
                    <span>WXYZ</span>
                  </button>
                  <button class="number" onClick={() => handleButtonClick("9")}>
                    9<br />
                    <span>+</span>
                  </button>
                  <button class="symbol" onClick={() => handleButtonClick("*")}>
                    *
                  </button>
                  <button class="number" onClick={() => handleButtonClick("0")}>
                    0
                  </button>
                  <button class="symbol" onClick={() => handleButtonClick("#")}>
                    #
                  </button>
                </div>
                <div
                  class="call-action"
                  onClick={() => {
                    setprofilecall(false);
                    setdialped(false);
                    setcalltoConnect(true);
                  }}
                >
                  <button class="call-button">
                    <PhoneFilled size={16} />
                  </button>
                  <button class="delete-button">✖</button>
                </div>
              </div>
            )}
            {Profilecall && (
              <div className="header-content">
                <CustomerService size={64} />
              </div>
            )}
          </div>
        </Column>

        {/* Main Content Area */}

        {/* Footer */}
      </div>
      <div className={isSupportTabMaximized ? "position-unset" : "footer_row"}>
        <Column className={isSupportTabMaximized ? "flex justify-center" : ""}>
          {calltoConnect && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="call_now">
                <PhoneFilled size={16} />
              </div>
            </div>
          )}
          {!!dialped ? (
            <div
              className="footer-content"
              style={{ justifyContent: "center" }}
            >
              <ul style={{ justifyContent: "center" }}>
                <li>
                  <div
                    className="app_icon"
                    onClick={() => {
                      setprofilecall(false);
                      setdialped(true);
                    }}
                  >
                    <Apps size={16} />
                  </div>
                  <div className="app_icon">
                    <MicrophoneFilled size={16} />
                  </div>
                  <div className="call_icon">
                    <PhoneFilled size={16} />
                  </div>
                </li>
              </ul>
            </div>
          ) : (
            Profilecall && (
              <div
                className={
                  isSupportTabMaximized
                    ? "footer-content supportwidth"
                    : "footer-content "
                }
              >
                <ul>
                  <li>
                    <div className="record_icon">
                      <RecordingFilled size={16} />
                    </div>
                    <span>01:24</span>
                  </li>
                  <li>
                    <div
                      className="app_icon"
                      onClick={() => {
                        setprofilecall(false);
                        setdialped(true);
                      }}
                    >
                      <Apps size={16} />
                    </div>
                    <div className="app_icon">
                      <MicrophoneFilled size={16} />
                    </div>
                    <div className="call_icon">
                      <PhoneFilled size={16} />
                    </div>
                  </li>
                </ul>
              </div>
            )
          )}
        </Column>
      </div>
    </BrowsercallWrapper>
  );
};

export default Browsercall;
