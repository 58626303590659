import React, { useContext } from "react";
import { ArrowLeft } from "@carbon/icons-react";
import { GlobalContext } from "../../../ContextApi/GlobalContext";
import { Contextprovider } from "../../../App";

const GridModeTab = () => {
  const { toggle, settoggle } = useContext(GlobalContext);
  const { useralldata, dispatch } = useContext(Contextprovider);

  return (
    <div className="bx--grid--full-width">
      <div className="bx--row">
        <div
          className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4 border--right"
          style={{ display: toggle.openShow ? "none" : "" }}
        >
          <div className="support-col-title">
            <h2>Open ({useralldata?.GlobalState.newCount})</h2>
            <span className="align-icon">
              |<ArrowLeft />
            </span>
          </div>
          <div className="GridTicket">{useralldata?.GlobalState.fetchnew}</div>
          {/* <div>{props.OpenData}</div> */}
        </div>

        <div
          className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4 border--right"
          style={{ display: toggle.newShow ? "none" : "" }}
        >
          <div className="support-col-title">
            <h2>Assigned ({useralldata?.GlobalState.openCount})</h2>
            <span className="align-icon">
              |<ArrowLeft />
            </span>
          </div>
          <div className="GridTicket">{useralldata?.GlobalState.fetchopen}</div>
          {/* <div>{props.NewData}</div> */}
        </div>
        <div
          className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4 border--right"
          style={{
            display: toggle.pendingShow ? "none" : "",
          }}
        >
          <div className="support-col-title">
            <h2>In progress ({useralldata?.GlobalState.pendingCount})</h2>
            <span className="align-icon">
              |<ArrowLeft />
            </span>
          </div>
          <div className="GridTicket">
            {useralldata?.GlobalState.fetchpending}
          </div>
          {/* <div>{props.PendingData}</div> */}
        </div>
        <div
          className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4 border--right"
          style={{
            display: toggle.closedShow ? "none" : "",
          }}
        >
          <div className="support-col-title">
            <h2>Closed ({useralldata?.GlobalState.closedCount})</h2>
            <span className="align-icon">
              |<ArrowLeft />
            </span>
          </div>
          <div className="GridTicket">
            {useralldata?.GlobalState.fetchclosed}
          </div>
          {/* <div>{props.CloseData}</div> */}
        </div>
      </div>
    </div>
  );
};

export default GridModeTab;
