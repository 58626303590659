import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export function capitalizeString(str) {
  return str
    .split(" ")
    .map((subStr) => subStr.charAt(0).toUpperCase() + subStr.slice(1))
    .join(" ");
}

const SERVICE_REQUEST_STATUS_TEXT = {
  new: "Open",
  open: "Open",
};

// export function getServiceRequestStatusText(status = "") {
//   return SERVICE_REQUEST_STATUS_TEXT[status?.toLowerCase()] ?? status;
// }

export function getServiceRequestStatusText(status = "") {
  return status;
}

export { dayjs };
