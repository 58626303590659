import { CustomizationProvider } from "@twilio-paste/core/customization";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initSession } from "redux/reducers/actions/initActions";
import { changeEngagementPhase } from "redux/reducers/chat.reducer";
import { EngagementPhase } from "redux/reducers/definitions";
import { sessionDataHandler } from "utils/sessionDataHandler";
import { RootContainer } from "./RootContainer";

const AnyCustomizationProvider = CustomizationProvider;

export default function WebchatWidget() {
  const theme = useSelector((state) => state.config.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    const data = sessionDataHandler.tryResumeExistingSession();
    const logger = window.Twilio.getLogger("WebChatWidget");
    if (data) {
      try {
        logger.info("Initializing session.");
        dispatch(
          initSession({
            token: data.token,
            conversationSid: data.conversationSid,
          })
        );
      } catch (e) {
        // if initSession fails, go to changeEngagement phase - most likely there's something wrong with the store token or conversation sis
        logger.error(
          "Something wrong with the store token or conversation sis. Changing engagement phase."
        );
        dispatch(
          changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm })
        );
      }
    } else {
      // if no token is stored, go to engagement form
      logger.warn("Found no token. Going to Engagement form.");
      dispatch(
        changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm })
      );
    }
  }, [dispatch]);

  return (
    <AnyCustomizationProvider
      baseTheme={localStorage.getItem("theme") == "g100" ? "dark" : "default"}
      theme={theme?.overrides}
      elements={{
        MESSAGE_INPUT: {
          boxShadow: "none!important",
        },
        MESSAGE_INPUT_BOX: {
          display: "inline-block",
          boxShadow: "none",
        },
        ALERT: {
          paddingTop: "space30",
          paddingBottom: "space30",
        },
        BUTTON: {
          "&[aria-disabled='true'][color='colorTextLink']": {
            color: "colorTextLinkWeak",
          },
        },
      }}
      style={{
        minHeight: "100%",
        minWidth: "100%",
        height: "inherit",
        flex: 1,
        display: "flex",
      }}
    >
      <RootContainer />
      {/* <div className="flex justify-center p-4 mt-8 w-full">
        <h3 className="text-base">Coming Soon</h3>
      </div> */}
    </AnyCustomizationProvider>
  );
}
