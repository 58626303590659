import styled from "styled-components";
const CreateTicketSideNavFormWrapper = styled.div`
  position: relative;
  overflow: auto;

  .cds--select-input__wrapper {
    z-index: 999;
  }

  .support-type-tile-group fieldset .cds--radio-button-wrapper {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "#fff" : "#262626"};
  }
  .cds--text-input,
  .cds--text-area {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "#fff" : "#262626"};
  }

  .cds--select-input {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "#fff" : "#262626"};
  }

  .cds--dropdown {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "#fff" : "#262626"};
    z-index: 99;
  }

  .cds--label {
    color: ${(props) =>
      props.theme.colorScheme == "white" ? " #525252" : "#7E7E7E"};
  }

  .cds--radio-button__label-text {
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
  }

  .cds--file__drop-container {
    color: ${(props) =>
      props.theme.colorScheme == "white"
        ? "var(--link-link-primary, var(--Default-Blue-60, #2570fe))"
        : "var(--link-link-primary, var(--Default-Blue-60, #75A6fe))"};
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.16px;
  }

  .cds--modal
    .cds--modal-container
    .support-modal-content
    .create-ticket-desktop-form
    .support-type-tile-group
    div:only-child
    div
    .cds--tile {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "white" : "#262626"} !important;
  }

  .cds--tile {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "white" : "#262626"} !important;
  }

  .cds--select-input {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "white" : "#262626"} !important;
  }

  .cds--text-input {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "white" : "#262626"} !important;
  }

  .cds--text-area {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "white" : "#262626"} !important;
  }

  .form_padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .service-request-type {
    margin-top: 2rem 0;
  }

  .form_heading {
    font-family: IBM Plex Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: -6px;
  }

  h5 {
    color: #161616;
    font-family: "IBM Plex Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 143.75% */
    letter-spacing: 0.16px;
  }

  p {
    color: var(--accessibility-black, #000);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 138.462% */
    letter-spacing: 0.16px;
  }

  .support-type-tile-group.px-0 {
    fieldset {
      padding: 0 !important;
    }
  }
  .support-type-tile-group {
    display: flex;
    margin: 1.5rem 0;

    fieldset {
      padding-inline: ${(props) => (props.smallView ? "0" : "0.5rem")};
      display: flex;
      flex-direction: ${(props) => (props.smallView ? "column" : "row")};
      gap: 1rem;
      width: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        flex-wrap: wrap;
        padding-inline: 0;
      }

      .cds--radio-button-wrapper {
        width: 100%;
        margin: 0;
        border: 1px solid
          ${(props) =>
            props.theme.colorScheme === "white" ? "#e0e0e0" : "#474747"};
        z-index: 999;

        label {
          padding: 14px;
          justify-content: left;
        }
      }
    }

    label {
      display: flex;
      align-items: center;
    }
  }
  li[role="separator"] {
    margin-left: 0;
    margin-right: 0;
  }
  .service {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    margin: 1rem 0;
    h5 {
      color: #161616;
      font-family: "IBM Plex Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px; /* 143.75% */
      letter-spacing: 0.16px;
    }
  }
  .subject {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    margin: 1rem 0;
    h5 {
      color: #161616;
      font-family: "IBM Plex Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px; /* 143.75% */
      letter-spacing: 0.16px;
    }
  }

  .contact {
    display: flex;
    gap: 32px;
    flex-direction: column;
    margin: 1rem 0;

    .cds--list-box__field {
      background: ${(props) =>
        props.theme.colorScheme == "white" ? " #fff" : "#262626"};

      border-bottom: ${(props) =>
        props.theme.colorScheme == "white"
          ? "1px solid #474747"
          : "1px solid#8D8D8D"};
    }

    h5 {
      color: #161616;
      font-family: "IBM Plex Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px; /* 143.75% */
      letter-spacing: 0.16px;
    }

    .contact-tile-group {
      display: flex;
      div {
        flex-direction: ${(props) => (props.smallView ? "column" : "row")};

        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
        }

        .cds--tile {
          border: ${(props) =>
            props.theme.colorScheme == "white"
              ? "1px solid var(--border-subtle, #e0e0e0) "
              : "1px solid var(--border-subtle, #393939)"};

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          .cds--tile__checkmark {
            top: 50%;
            transform: translate(0, -50%);
          }
        }
      }
      label {
        display: flex;
        align-items: center;
      }
    }
  }
  .attachments {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    margin: 1rem 0;
    h5 {
      color: #161616;
      font-family: "IBM Plex Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px; /* 143.75% */
      letter-spacing: 0.16px;
    }
    .cds--file {
      button {
        width: 100%;
        max-width: 100%;
      }
    }
    h6 {
      color: var(--text-text-primary, var(--Text-text-primary, #161616));

      /* Fixed heading styles/heading-compact-01 */
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
    }

    .uploade_file {
      p {
        font-family: "IBM Plex Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px; /* 141.667% */
        letter-spacing: 0.32px;
        color: ${(props) =>
          props.theme.colorScheme == "white" ? "#525252 " : " #525252"};
      }

      h6 {
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }

  .loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(22, 22, 22, 0.5);
  }

  .phone-input-wrapper-container {
    label {
      margin-block-end: 6px;
    }

    .phone-input-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      width: 100%;
      .cds--list-box__field {
        border-bottom: none !important;
        block-size: 100%;
      }
      /* .cds--text-input {
        border-block-end: none !important;
        border-bottom: none !important;
      } */

      .cds--dropdown__wrapper {
        width: 135px;
      }
    }
  }

  .file-uploader-preview-container {
    width: 100%;
    display: flex;

    .loader-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 4px;
      background-color: #c6c6c6;

      .loader {
        background-color: #2570fe;
        height: 4px;
      }
    }
  }
`;
export default CreateTicketSideNavFormWrapper;
