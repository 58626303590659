import { createSlice } from "@reduxjs/toolkit";
// import {
//   ACTION_ADD_NOTIFICATION,
//   ACTION_REMOVE_NOTIFICATION,
// } from "./actions/actionTypes";

const initialState = [];

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.push(action.payload.notification ?? action.payload);
    },
    removeNotification: (state, action) => {
      return state.filter(
        (notification) => notification.id !== action.payload.id
      );
    },
  },
});

export const { addNotification, removeNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
