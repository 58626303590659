import { Box } from "@twilio-paste/core/box";
import { useSelector } from "react-redux";
import { EngagementPhase } from "redux/reducers/definitions";
import { LoadingPhase } from "./LoadingPhase";
import { MessagingCanvasPhase } from "./MessagingCanvasPhase";
import { PreEngagementFormPhase } from "./PreEngagementFormPhase";
import {
  innerContainerStyles,
  outerContainerStyles,
} from "./styles/RootContainer.styles";
import { Theme } from "@carbon/react";
import { useContext } from "react";
import { GlobalContext } from "ContextApi/GlobalContext";

const getPhaseComponent = (phase) => {
  switch (phase) {
    case EngagementPhase.Loading:
      return <LoadingPhase />;

    case EngagementPhase.MessagingCanvas:
      return <MessagingCanvasPhase />;

    case EngagementPhase.PreEngagementForm:
    default:
      return <PreEngagementFormPhase />;
  }
};

const defaultFont = {
  fontFamily: "Oxygen, Ubuntu, sans-serif",
  height: "inherit",
  width: "100%",
};

export function RootContainer() {
  const currentPhase = useSelector((state) => state.chat.currentPhase);
  const expanded = useSelector((state) => state.chat.expanded);
  const context = useContext(GlobalContext);

  return (
    <Box style={defaultFont} className="flex ">
      <Box {...outerContainerStyles}>
        {currentPhase && (
          <Box
            data-test="root-container"
            {...innerContainerStyles}
            style={{
              backgroundColor:
                context.globalTheme == "white" ? "#f4f4f4" : "#161616",
            }}
          >
            {getPhaseComponent(currentPhase)}
          </Box>
        )}
      </Box>
    </Box>
  );
}
