import { combineReducers, configureStore } from "@reduxjs/toolkit";
import headerReducer from "redux/slices/headerSlice"; // Verify this path
import allTicketsReducer from "./slices/Alltickets/allticketSlice"; // Renamed for consistency
import notificationsReducer from "./slices/Notifications/NotificationsSlice";
import salesForceReducer from "./slices/SalesForce/SalesForceSlice";
import subscriptionsReducer from "./slices/Subscriptions/SubscriptionsSlice"; // Renamed for consistency
import supportReducer from "./slices/Support/SupportSlice";
import ticketInfoReducer from "./slices/TicketInfo/TicketinfoSlice"; // Assuming this is the reducer
import createCCemailReducer from "./slices/TicketInfo/UpdateemailSlice"; // Assuming this is the reducer
import userReducer from "./slices/UserSlice/UserAlldataSlice"; // Renamed for consistency
import ChatbotSlice from "./slices/Chatbot/ChatbotSlice";
import GetDocumentslistReducer from "./slices/TraningGuidens/TrainingGuidensSlice";
import GetReommendedDataReducer from "./slices/RecommendArticals/RecommendSlice";
import ChatReducer from "./reducers/chat.reducer";
import ConfigReducer from "./reducers/config.reducer";
import NotificationReducer from "./reducers/notification.reducer";
import GetFaqDetailsReducer from "./slices/Faq/FaqSlice";
import TicketFilterReducer from "./slices/TicketSlice";

const reducer = combineReducers({
  header: headerReducer,
  user: userReducer,
  salesForce: salesForceReducer,
  notifications: notificationsReducer,
  support: supportReducer,
  subscriptions: subscriptionsReducer,
  allticket: allTicketsReducer,
  setticketinfo: ticketInfoReducer,
  CCemail: createCCemailReducer,
  chatbot: ChatbotSlice,
  Document: GetDocumentslistReducer,
  Articals: GetReommendedDataReducer,
  Faq: GetFaqDetailsReducer,
  chat: ChatReducer,
  config: ConfigReducer,
  chatNotifications: NotificationReducer,
  filter: TicketFilterReducer,
});

const store = configureStore({
  reducer,
});

export default store;
