import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { getDaysOld } from "utils/getDaysOld";
import {
  getSeparatorLineStyles,
  getSeparatorTextStyles,
  separatorContainerStyles,
} from "./styles/MessageListSeparator.styles";

export const MessageListSeparator = ({ message, separatorType }) => {
  const getSeparatorText = () => {
    let separatorText;
    if (separatorType === "new") {
      separatorText = "New";
    } else {
      const daysOld = getDaysOld(message.dateCreated);
      if (daysOld === 0) {
        separatorText = "Today";
      } else if (daysOld === 1) {
        separatorText = "Yesterday";
      } else {
        separatorText = message.dateCreated.toLocaleDateString();
      }
    }

    return separatorText;
  };

  return (
    <Box
      {...separatorContainerStyles}
      data-test="new-message-separator"
      role="separator"
    >
      <Box {...getSeparatorLineStyles(separatorType)} aria-hidden="true" />
      <Text as="p" {...getSeparatorTextStyles(separatorType)}>
        {getSeparatorText()}
      </Text>
      <Box {...getSeparatorLineStyles(separatorType)} aria-hidden="true" />
    </Box>
  );
};
