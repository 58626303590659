import React from "react";
import SwitcherButtonWrapper from "./style";
import Icon from "components/icon/icon";

const SwitcherButton = ({ icon, text, handleClick }) => {
  return (
    <SwitcherButtonWrapper onClick={handleClick}>
      <Icon iconName={icon} size={24} /> <span>{text}</span>
    </SwitcherButtonWrapper>
  );
};

export default SwitcherButton;
