import {
  Column,
  ContentSwitcher,
  DataTable,
  Row,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  FlexGrid,
  TableRow,
} from "@carbon/react";
import { IncidentDummyData } from "Component/Data/DataJson";
import { useState } from "react";
import Incedent from "./Incedent";
import PlainedMaintanence from "./PlainedMaintenance";
import Serviceindex from "./ServiceStatus";
import StatusWrapper from "./style";
const activeRowStyles = {
  backGgroundColor: "#393939", // Light grey background for the active row
  border: "1px solid #0F62FE ",
};

const Status = () => {
  const [currentRegion, setCurrentRegion] = useState({
    index: 0,
    name: "one",
    text: "America",
  });

  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const handleRowClick = (rowIndex) => {
    setActiveRowIndex(rowIndex);
  };
  const getRowStyle = (rowIndex) => {
    return rowIndex === activeRowIndex ? { ...activeRowStyles } : {};
  };

  return (
    <StatusWrapper className="dark:!bg-[#161616]">
      {/* <MobileHeaderNav/> */}
      <FlexGrid fullWidth>
        <section className="header  dark:text-[#fff]">
          <h4>support dashboard</h4>
          <h1>Service Status</h1>
        </section>
        {/* </FlexGrid> */}

        {/* <FlexGrid
        fullWidth
        className="!bg-[transparent] dark:!bg-[#161616] dark:text-[#fff]"
      > */}
        <section className="plans-content ">
          <Row>
            <Column lg={8}>
              <div style={{ marginBottom: "40px" }}>
                <p>
                  Clients and visitors can access our Service Status Page to
                  obtain timely updates on the performance and status of our
                  systems. This page offers real-time information about any
                  ongoing maintenance, interruptions, or enhancements aimed at
                  ensuring a seamless user experience.
                </p>
              </div>
            </Column>
          </Row>

          <div className="status">
            <Serviceindex />
          </div>

          <div className="data_table">
            <div className="datatable_switcher dark:!text-white">
              <ContentSwitcher
                className="dark:!text-white custom-switcher"
                size="lg"
                kind="ghost"
                selectedIndex={currentRegion.index}
                onChange={(region) => {
                  setCurrentRegion(region);
                }}
              >
                <Switch
                  name="one"
                  text="America"
                  className="dark:!text-white"
                />
                <Switch name="two" text="Europe" className="dark:!text-white" />
                <Switch
                  name="three"
                  text="Asia Pacific"
                  className="dark:!text-white"
                />
              </ContentSwitcher>
            </div>

            <div
              className="city_datatable dark:!border-[#474747]"
              style={{ border: "1px solid #e0e0e0" }}
            >
              <DataTable
                className="dark:!border-[#474747]"
                rows={IncidentDummyData[currentRegion.text].rows}
                key={IncidentDummyData[currentRegion.text].rows}
                headers={IncidentDummyData[currentRegion.text].headers}
                render={({ rows, headers, getHeaderProps, getRowProps }) => (
                  <TableContainer className="dark:!border-[#474747] dark:!text-white">
                    <Table
                      aria-label="sample table"
                      className="dark:!border-[#474747]"
                    >
                      <TableHead>
                        <TableRow>
                          {headers.map((header) => (
                            <TableHeader
                              {...getHeaderProps({ header })}
                              className="dark:!text-[#fff]"
                            >
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow {...getRowProps({ row })}>
                            {row.cells.map((cell) => (
                              <TableCell
                                className="dark:!text-white"
                                key={cell.id}
                              >
                                {cell.value}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              />
            </div>
          </div>
          <Incedent />
          <PlainedMaintanence />
        </section>
      </FlexGrid>
    </StatusWrapper>
  );
};

export default Status;
const Serivity = [
  {
    id: "Service",
    text: "",
  },
  {
    id: "city",
    text: "Normal",
  },
];
