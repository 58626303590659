import React, { useMemo } from "react";
import SwitcherWrapper from "./style";
import { Forum, User } from "@carbon/icons-react";
import {
  CopyButton,
  SideNavDivider,
  SideNavMenuItem,
  SideNavMenu,
  SideNavLink,
} from "@carbon/react";
import { ChevronRight } from "@carbon/icons-react";
import SwitcherButton from "./button";
import { handleThemeModal } from "redux/slices/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GlobalsideNavRoutes } from "utils/routes/sideNavRoutes";

const SwitcherTab = () => {
  const user = useSelector((state) => state.salesForce.userInfoData.data);
  const handleCopy = () => {
    navigator.clipboard.writeText(user?.contactInfo?.clientId ?? "");
  };
  const dispatch = useDispatch();

  const headerLinks = useMemo(
    () => [
      {
        link: "https://identity.uvation.com/",
        title: "Identity",
        icon: "FingerprintRecognition",
      },
      {
        link: "https://portal.uvation.com/",
        title: "USP",
        icon: "IbmCloudCitrixDaas",
      },
      {
        link: "https://marketplace.uvation.com/",
        title: "Marketplace",
        icon: "ShoppingCart",
      },
      { link: "https://rewards.uvation.com/", title: "Rewards", icon: "Gift" },
      {
        link: "https://identity.uvation.com/account",
        title: "Profile",
        icon: "User",
      },
    ],
    []
  );

  const TrainingRoutes = [
    {
      linkNames: "Training For Admins",
      path: "/training/Training-For-admins",
    },
    {
      linkNames: "Knowledge Base",
      path: "/training/knowledge-base",
    },
    {
      linkNames: "FAQ & Guides",
      path: "/training/Faq-Guide",
    },
  ];

  const sideNavDeepLinks = [
    {
      to: "/",
      text: "Support Dashboard",
    },
    {
      to: "/status",
      text: "Support Status",
    },
    {
      to: "/request",
      text: "Support Request",
    },
    {
      to: "/plan",
      text: "Support Plans",
    },
    {
      to: "/training",
      text: "Training & Guides",
    },
  ];

  const handleClick = (path) => {
    window.open(path, "_blank");
  };

  return (
    <SwitcherWrapper>
      {/* <div className="heading">
        <div
          className="flex items-flexstart gap-5 "
          style={{ paddingTop: "1rem", paddingBottom: "5px" }}
        >
          {user.profileImage ? (
            <img
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src={user.profileImage}
            />
          ) : (
            <div className="icon_box">
              <User size={20} />
            </div>
          )}

          <div className="middle">
            <h3>{user?.contactInfo?.name}</h3>
            <h3 className="account_Id">Account ID </h3>
            <div className="copy">
              <span>{user?.contactInfo?.clientId ?? ""}</span>
              <CopyButton
                iconDescription="Copy"
                align="bottom"
                onClick={handleCopy}
                feedback="Copied"
              />
            </div>
          </div>
        </div>

        <a
          href="https://identity.uvation.com/account"
          target="_blank"
          className="icon_btn"
        >
          <ChevronRight />
        </a>
      </div> */}

      {/* <hr
        style={{
          height: "1px",
          marginTop: "10px",
          marginBottom: "2px",
          color: "var(--gry-call-400, #393939)",
        }}
      /> */}

      {headerLinks.map((header, index) => {
        return (
          <SwitcherButton
            icon={header.icon}
            text={header.title}
            handleClick={() => handleClick(header.link)}
          />
        );
      })}
      {/* <hr
        style={{
          height: "1px",
          marginTop: "10px",
          marginBottom: "2px",
          color: "var(--gry-call-400, #393939)",
        }}
      /> */}
      <>
        {/* <SideNavMenu
          aria-current={location.pathname == "/" ? "page" : "false"}
          renderIcon={Forum}
          title="Support Center"
          className="!text-white fill-white Profile_user"
          as={Link}
          to="/account"
          large
        >
          {GlobalsideNavRoutes.map((privateRouteDetail) => {
            if (privateRouteDetail.name !== "Support Dashboard") {
              return (
                <>
                  <SideNavLink
                    className="text-white"
                    isActive={privateRouteDetail.path === location.pathname}
                    key={privateRouteDetail.id}
                    element={Link}
                    to={privateRouteDetail.path}
                  >
                    <span className="text-white">
                      {privateRouteDetail.name}
                    </span>
                  </SideNavLink>
                </>
              );
            } else {
              return null;
            }
          })}
        </SideNavMenu> */}
        {/* {sideNavDeepLinks.map((link, index) => {
          if (link.text !== "Training & Guides") {
            return (
              <SideNavLink to={link.to} as={Link}>
                {link.text}
              </SideNavLink>
            );
          } else {
            return (
              <SideNavMenu title="Training & Guides">
                {TrainingRoutes.map((link, index) => (
                  <SideNavMenuItem to={link.path} as={Link}>
                    {link.linkNames}
                  </SideNavMenuItem>
                ))}
              </SideNavMenu>
            );
          }
        })} */}
      </>
      {/* <SwitcherButton
        icon="Light"
        text="Change Theme"
        handleClick={() => dispatch(handleThemeModal())}
      /> */}
    </SwitcherWrapper>
  );
};

export default SwitcherTab;
