import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  setTicketinfo,
  setTicketattachment,
  Updatecommentdata,
} from "./TicketInfoService";

export const setTicketData = createAsyncThunk(
  "setTicketinfo/Tickets",
  async (payload, thunkAPI) => {
    try {
      const res = await setTicketinfo(payload);
      return res;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const setAtachment = createAsyncThunk(
  "setTicketinfo/Ticketattachment",
  async (payload, thunkAPI) => {
    console.clear();

    function updateProgress(progress) {
      thunkAPI.dispatch(setAttachmentUploadProgress(progress));
    }

    try {
      return await setTicketattachment(payload, updateProgress);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const setUpdatecomment = createAsyncThunk(
  "setTicketinfo/updatecommnetnn",
  async (payload, thunkAPI) => {
    try {
      return await Updatecommentdata(payload);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response?.data || err.message);
    }
  }
);

const initialState = {
  Ticketinfo: {
    error: null,
    status: "idle", // succeeded || idle || failed || loading
    data: [],
    message: "",
    isloading: false,
  },
  Ticketattachment: {
    error: null,
    status: "idle", // succeeded || idle || failed || loading
    data: "",
    message: "",
    isloading: false,
    progress: 0,
  },
  updatecomment: {
    error: null,
    status: "idle", // succeeded || idle || failed || loading
    data: "",
    message: "",
    isloading: false,
  },
};

export const createTicketInfo = createSlice({
  name: "setTicketinfo",
  initialState,
  reducers: {
    // Define any reducers here if needed
    removeAttachment(state) {
      state.Ticketattachment.data = "";
      return state;
    },

    setAttachmentUploadProgress(state, { payload }) {
      state.Ticketattachment.progress = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setTicketData.fulfilled, (state, { payload }) => {
      state.Ticketinfo.data = payload;
      state.Ticketinfo.error = null;
      state.Ticketinfo.status = "succeeded";
    });
    builder.addCase(setTicketData.pending, (state) => {
      state.Ticketinfo.status = "loading";
      state.Ticketinfo.error = null;
    });
    builder.addCase(setTicketData.rejected, (state, { payload }) => {
      state.Ticketinfo.status = "failed";
      state.Ticketinfo.error = payload;
    });

    // ------------------------------------------------------------------------------------ticketattachment------------------------------------------------------------
    builder.addCase(setAtachment.fulfilled, (state, { payload }) => {
      state.Ticketattachment.data = payload || [];
      state.Ticketattachment.error = null;
      state.Ticketattachment.status = "succeeded";
      state.Ticketattachment.progress = 100;
    });
    builder.addCase(setAtachment.pending, (state) => {
      state.Ticketattachment.status = "loading";
      state.Ticketattachment.progress = 0;
      state.Ticketattachment.error = null;
    });
    builder.addCase(setAtachment.rejected, (state, { payload }) => {
      state.Ticketattachment.status = "failed";
      state.Ticketattachment.error = payload;
    });

    //  ------------------------------------------------------------------------updateComent-------------------------------------------------
    builder.addCase(setUpdatecomment.fulfilled, (state, { payload }) => {
      state.updatecomment.data = payload || [];
      state.updatecomment.error = null;
      state.updatecomment.status = "succeeded";
      state.Ticketattachment.data = "";
    });
    builder.addCase(setUpdatecomment.pending, (state) => {
      state.updatecomment.status = "loading";
      state.updatecomment.error = null;
    });
    builder.addCase(setUpdatecomment.rejected, (state, { payload }) => {
      state.updatecomment.status = "failed";
      state.updatecomment.error = payload;
    });
  },
});

export const { removeAttachment, setAttachmentUploadProgress } =
  createTicketInfo.actions;

export default createTicketInfo.reducer;
