import styled from "styled-components";

const CallTabWrapper = styled.div`
  .callBtn {
    position: absolute;
    bottom: 0;
    width: 100%;

    a {
      min-width: 100%;
    }

    button {
      min-width: 100%;
    }
  }

  .chose_number {
    padding: 20px 20px 0px 20px;

    .cds--telephone {
      .country_code {
        display: flex;
        align-items: center;
        margin-top: 20px;
      }
    }

    h2 {
      font-family: IBM Plex Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .mainheading_recent {
      h4 {
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 18px; /* 138.462% */
        letter-spacing: 0.16px;
      }
    }
    p {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.013px;
    }
  }

  .recentcall_history {
    padding: 20px;
    border-bottom: 1px solid var(--border-subtle, #e0e0e0);

    ul {
      display: flex;
      align-items: center;
      gap: 5px;

      li {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        h6 {
          font-family: IBM Plex Sans;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 138.462% */
          letter-spacing: 0.16px;
        }

        span {
          color: var(
            --text-text-on-color-disabled,
            var(--text-text-on-color-disabled, #8d8d8d)
          );
          text-align: right;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          letter-spacing: 0.32px;
        }
      }
    }
  }

  .claatalk_agent {
    h4 {
      font-family: IBM Plex Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    p {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.013px;
    }
  }
`;

export default CallTabWrapper;
