import {
  Add,
  CheckmarkFilled,
  Launch,
  RequestQuote,
} from "@carbon/icons-react";
import {
  DataTable,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Theme,
} from "@carbon/react";
import { Contextprovider } from "App";
import CustomButton from "components/button/customButton";
import NoDataInTable from "components/datatable/noDataInTable";
import CreateTicketSideNavForm from "components/forms/createRequestForm/withoutFullScreen";
import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getSupportTickets } from "redux/slices/Support/SupportSlice";
import {
  handleCreateTicketComplete,
  handleCreateTicketTab,
  headerState,
} from "redux/slices/headerSlice";
import { RequestTabWrapper, RequestCompleteWrapper } from "./style";
import { GlobalContext } from "ContextApi/GlobalContext";
import { getServiceRequestStatusText } from "utils";
import useWindowSize from "hooks/useWindowSize";

const headerData = [
  {
    header: "Request #",
    key: "id",
  },
  {
    header: "Subject/Date",
    key: "datesubject",
  },
  {
    header: "Status",
    key: "status",
  },
];

const RequestTab = () => {
  const [activePage, setActivePage] = useState({ page: 1, pageSize: 5 });
  const { useralldata } = useContext(Contextprovider);
  const context = useContext(GlobalContext);
  const { width } = useWindowSize();
  const [statusFilter, setStatusFilter] = useState([]);
  const { createTicketTab, createTicketComplete, ticketCompleteData } =
    useSelector(headerState);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Active, setActive] = useState(false);
  const {
    tickets: { data: tickets },
    tickets: { count },
  } = useSelector((state) => state.support);

  function handlePageChange(pageNumber) {
    dispatch(
      getSupportTickets({
        email: useralldata?.userinfo?.email,
        pageNumber,
        tab: 1,
        status: statusFilter,
      })
    );
    setActivePage(pageNumber || { page: 1, pageSize: 5 });
  }

  function getTickets(status) {
    if (status.length) {
      setStatusFilter([{ id: status, text: status }]);
    } else {
      setStatusFilter([]);
    }

    dispatch(
      getSupportTickets({
        email: useralldata?.userinfo?.email,
        values: {
          ...statusFilter,
          page_no: 1,
          per_page: 10,
          status: [{ id: status, text: status }],
        },
      })
    );
  }

  useEffect(() => {
    handlePageChange(); // You may want to pass the active page as a parameter here
  }, [useralldata]);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      dispatch(handleCreateTicketComplete({ toggle: false, res: "" }));
      createTicketComplete && dispatch(handleCreateTicketTab(false));
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [createTicketComplete]);

  if (createTicketComplete) {
    return (
      <RequestCompleteWrapper className="dark:!bg-[#161616]">
        <div className="header_modal">
          <CheckmarkFilled size={32} />
          <h4 className="">Request Completed!</h4>
        </div>

        <div>
          <div className="sub_heading">
            <h4>
              Your{" "}
              <b className="font-semibold">Request #{ticketCompleteData.id}</b>{" "}
              has been forwarded to our
              <br /> customer services team and has been added to <br /> the
              list of open requests.
            </h4>
          </div>
        </div>
      </RequestCompleteWrapper>
    );
  } else {
    return (
      <RequestTabWrapper className="dark:!bg-[#161616]">
        {!createTicketTab ? (
          <>
            <div className="top-row w-full flex-1 dark:!text-[#fff] flex flex-col">
              <div className="request-header">
                <h3 className="dark:!text-[#fff] pt-[24px]">My Requests</h3>
              </div>
              <div className="request-status-row">
                <div
                  className={`box cursor-pointer ${
                    statusFilter[0]?.text === "Open" ? "active" : ""
                  }`}
                  onClick={() => getTickets("Open")}
                >
                  <span className="dark:!text-[#fff]">Open </span>
                  <span>
                    <b className="dark:!text-[#fff]">
                      {useralldata?.GlobalState?.newCount}
                    </b>
                  </span>
                </div>
                <div
                  className={`box cursor-pointer ${
                    statusFilter[0]?.text === "Assigned" ? "active" : ""
                  }`}
                  onClick={() => getTickets("Assigned")}
                >
                  <span className="dark:!text-[#fff]">Unassigned</span>
                  <span>
                    <b className="dark:!text-[#fff]">
                      {useralldata?.GlobalState?.openCount}
                    </b>
                  </span>
                </div>
              </div>

              <div className="request-status-row">
                <div
                  className={`box cursor-pointer ${
                    statusFilter[0]?.text === "Pending" ? "active" : ""
                  }`}
                  onClick={() => getTickets("Pending")}
                >
                  <span className="dark:!text-[#fff]">In Progress </span>
                  <span>
                    <b className="dark:!text-[#fff]">
                      {useralldata?.GlobalState?.pendingCount}
                    </b>
                  </span>
                </div>
                <div
                  className={`box cursor-pointer ${
                    statusFilter[0]?.text === "Closed" ? "active" : ""
                  }`}
                  onClick={() => getTickets("Closed")}
                >
                  <span className="dark:!text-[#fff]">Closed</span>
                  <span>
                    <b className="dark:!text-[#fff]">
                      {useralldata?.GlobalState?.closedCount}
                    </b>
                  </span>
                </div>
              </div>

              {tickets.length ? (
                <>
                  <div className="support-tab-content flex flex-col h-full">
                    <DataTable rows={tickets} headers={headerData}>
                      {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                      }) => (
                        <TableContainer style={{ height: "100%" }}>
                          {/* <TableToolbar>
                            <TableToolbarContent>
                              <TableToolbarSearch
                                className="dark:!bg-[#161616] dark:!text-white"
                                onChange={onInputChange}
                                expanded
                                placeholder="Search input text"
                              />
                            </TableToolbarContent>
                          </TableToolbar> */}
                          <div
                            style={{
                              minHeight: width <= 768 ? "328px" : "328px",
                              boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.05)",
                            }}
                          >
                            <Table
                              className="dark:!text-[#fff] dark:!bg-[#474747]"
                              {...getTableProps()}
                              isSortable
                            >
                              <TableHead>
                                <TableRow className="dark:!text-[#fff] dark:!bg-[#474747]">
                                  {headers.map((header) => (
                                    <TableHeader
                                      className="dark:!text-[#fff] dark:!bg-[#474747]"
                                      key={header.key}
                                      {...getHeaderProps({ header })}
                                    >
                                      {header.header}
                                    </TableHeader>
                                  ))}
                                  <TableHeader />
                                </TableRow>
                              </TableHead>
                              <TableBody className="table_body">
                                {rows.slice(0, 5).map((row, row_inx) => (
                                  <TableRow
                                    className="dark:!text-[#fff] dark:!bg-[#262626] bg-[white] table_row"
                                    key={row.id}
                                    onClick={() =>
                                      navigate(`/ticketdetails/${row.id}`)
                                    }
                                    {...getRowProps({ row })}
                                  >
                                    {row.cells.map((cell, inx) => {
                                      return (
                                        <React.Fragment key={cell.id}>
                                          <TableCell
                                            className={`!bg-[transparent] dark:!text-[#fff] dark:!bg-[#262626] table_column ${
                                              inx === "statusColumnIndex"
                                                ? "status-cell"
                                                : ""
                                            }`}
                                          >
                                            {cell.id.includes("status") &&
                                            tickets[row_inx]?.status ? (
                                              <span
                                                className="!bg-[#D0E2FF] dark:!bg-[#D0E2FF] !text-[#0043CE] px-3 py-1 "
                                                style={{
                                                  borderRadius: "1000px",
                                                }}
                                              >
                                                {getServiceRequestStatusText(
                                                  tickets[row_inx].status
                                                )}
                                              </span>
                                            ) : (
                                              cell.value
                                            )}
                                            {inx == 1 && (
                                              <div>
                                                <p
                                                  style={{
                                                    fontSize: "14px",
                                                    lineHeight: "18px",
                                                    fontWeight: "400",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    fontFamily: "Arial",
                                                  }}
                                                >
                                                  {tickets[row_inx]?.subject}
                                                </p>
                                                <p
                                                  style={{
                                                    fontFamily: "IBM Plex Sans",
                                                    fontSize: "12px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "17px",
                                                    letterSpacing: "0.32px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    color: "#8D8D8D",
                                                  }}
                                                >
                                                  {tickets[row_inx]?.created_at}
                                                </p>
                                              </div>
                                            )}
                                          </TableCell>
                                        </React.Fragment>
                                      );
                                    })}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </div>
                        </TableContainer>
                      )}
                    </DataTable>
                    {/* <div className="pagination">
                      <Pagination
                        pagesUnknown={true}
                        backwardText="Previous page"
                        forwardText="Next page"
                        itemsPerPageText=""
                        page={activePage.page || 1}
                        pageSize={activePage.pageSize || 10}
                        pageNumberText="Page Number"
                        pageSizes={[10, 20, 30, 40, 50]}
                        size="md"
                        totalItems={count || 0}
                        onChange={handlePageChange}
                      />
                    </div> */}
                  </div>
                </>
              ) : (
                <div className="no_data">
                  <div className="request_quote_div">
                    <RequestQuote size={68} />
                  </div>
                  <h6>No Requests</h6>
                  <span>
                    Create a new request to view the your <br /> status.
                  </span>
                </div>
              )}
            </div>

            <div className="bottom-row !sticky ">
              <CustomButton
                handleClick={() => dispatch(handleCreateTicketTab(true))}
                text="Create A Service Request"
                icon={Add}
                fullWidth
              />
            </div>
          </>
        ) : (
          <Theme theme={context.globalTheme == "white" ? "white" : "g100"}>
            <CreateTicketSideNavForm smallViewPadding={true} />
          </Theme>
        )}
      </RequestTabWrapper>
    );
  }
};

export default RequestTab;
