import React from "react";
import { Content } from "@carbon/react";
import SideNavBar from "components/sidenav";
import LayoutWrapper from "./styles";
import ThemeModal from "components/modal/themeModal";
import FullScreenModal from "components/modal/fullScreenModal";
import { useSelector } from "react-redux";
import GlobalSideNav from "components/sidenav/globalSideNav/GlobalSideNav";
import { headerState } from "../../redux/slices/headerSlice";
import { useLocation, useParams } from "react-router-dom";
import useWindowSize from "hooks/useWindowSize";
import { sideNavRoutes } from "utils/routes/sideNavRoutes";
import { privateRoutes } from "utils/routes/privateRoutes";
import MobileHeaderNav from "components/sidenav/mobileNav/mobileHeaderNav";

const Layout = ({ children }) => {
  const { globalMenu, switcher, sideNav } = useSelector(headerState);
  const location = useLocation();
  const { width } = useWindowSize();

  const currentRoute = privateRoutes.find(
    (route) => route.path == location?.pathname
  );

  return width >= 1024 ? (
    <LayoutWrapper>
      {currentRoute?.path !== "/" &&
      currentRoute?.path !== "/notification" &&
      location?.pathname !==
        `/ticketdetails/${location?.pathname.split("/ticketdetails/")[1]}` &&
      currentRoute?.path !== "/TicketCreate" &&
      sideNav == false ? (
        <SideNavBar />
      ) : (
        <GlobalSideNav />
      )}

      <Content className="dark:!bg-[#262626] ">{children}</Content>
    </LayoutWrapper>
  ) : (
    <LayoutWrapper>
      <Content className="dark:!bg-[#262626] ">{children}</Content>
    </LayoutWrapper>
  );
  //  (
  //   <LayoutWrapper>
  //     {currentRoute?.path !== "/" &&
  //     currentRoute?.path !== "/notification" &&
  //     location?.pathname !==
  //       `/ticketdetails/${location?.pathname.split("/ticketdetails/")[1]}` &&
  //     currentRoute?.path !== "/TicketCreate" &&
  //     sideNav == false ? (
  //       <SideNavBar />
  //     ) : (
  //       <GlobalSideNav />
  //     )}

  //     <Content className="dark:!bg-[#262626] ">{children}</Content>
  //   </LayoutWrapper>
  // );
};

export default Layout;
