import React, { useContext } from "react";
import SupportWrapper from "./styles";
import {
  HeaderPanel,
  HeaderMenuButton,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Layer,
  Theme,
} from "@carbon/react";
import {
  Chat,
  Close,
  Maximize,
  RequestQuote,
  Phone,
} from "@carbon/icons-react";
import ChatTab from "./tabs/chat";
import RequestTab from "./tabs/request";
import CallTab from "./tabs/call";
import { useDispatch, useSelector } from "react-redux";
import {
  handleSupportTabIndex,
  handleSupportTabMaximized,
  headerState,
  reset,
} from "redux/slices/headerSlice";
import useWindowSize from "hooks/useWindowSize";
import Browsercall from "./tabs/call/BrowserCall";
import { GlobalContext } from "ContextApi/GlobalContext";

const Support = ({ open }) => {
  const { supportTabIndex, isSupportTabMaximized } = useSelector(headerState);
  const { browserCall } = useSelector(headerState);

  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const context = useContext(GlobalContext);

  return (
    open &&
    !isSupportTabMaximized && (
      <SupportWrapper className="dark:!bg-[#262626]">
        <HeaderPanel
          aria-label="Account Panel"
          className="account_panel dark:!bg-[#161616]"
          addFocusListeners={false}
          expanded={open && !isSupportTabMaximized}
        >
          <div className="header dark:!text-white">
            <h2 className="dark:!text-white">Support & Help</h2>
            {width <= 479 && (
              <Close size={20} onClick={() => dispatch(reset())} />
            )}

            {width >= 1056 && (
              <div className="button-box dark:!text-white ">
                <HeaderMenuButton
                  renderMenuIcon={<Maximize size={14} />}
                  onClick={() => dispatch(handleSupportTabMaximized())}
                />
                <HeaderMenuButton
                  renderMenuIcon={<Close size={14} />}
                  onClick={() => dispatch(reset())}
                />
              </div>
            )}
          </div>

          <Tabs
            className="dark:!text-white"
            selectedIndex={supportTabIndex}
            onChange={({ selectedIndex }) =>
              dispatch(handleSupportTabIndex(selectedIndex))
            }
          >
            <TabList aria-label="List of tabs" className="dark:!text-white">
              <Tab className="dark:!text-white">
                <Chat /> Chat
              </Tab>
              <Tab className="dark:!text-white ">
                <RequestQuote />
                Requests
              </Tab>
              <Tab className="dark:!text-white">
                <Phone />
                Call
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Theme
                  style={{ height: "100%" }}
                  theme={context.globalTheme == "white" ? "white" : "g100"}
                >
                  <ChatTab />
                </Theme>
              </TabPanel>

              <TabPanel className="dark:!bg-[#161616] ">
                <RequestTab />
              </TabPanel>

              {browserCall ? (
                <TabPanel className="dark:!bg-[#161616]">
                  <Browsercall />
                </TabPanel>
              ) : (
                <TabPanel className="dark:!bg-[#161616]">
                  <CallTab />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </HeaderPanel>
      </SupportWrapper>
    )
  );
};

export default Support;
