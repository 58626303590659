import {
  Attachment,
  Categories,
  List,
  Notebook,
  Phone,
  ServiceDesk,
} from "@carbon/icons-react";
import { BreadcrumbItem, Column, Dropdown, FlexGrid, Row } from "@carbon/react";
import CreateTicketSideNavForm from "components/forms/createRequestForm/withoutFullScreen";
import useWindowSize from "hooks/useWindowSize";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Link as Slink, scrollSpy } from "react-scroll";
import { TicketStyle } from "./style";
import { useRef } from "react";
import { GlobalContext } from "ContextApi/GlobalContext";
import ReactDOM from "react-dom";
import TicketCreateSuccessModal from "components/modal/TicketCreateSuccessModal/TicketCreateSuccessModal";

const CreteTicket = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const context = useContext(GlobalContext);

  const items = [
    {
      label: (
        <div className="icon_div">
          <Categories style={{ marginRight: "8px" }} size={16} />
          {"Service Request Type"}
        </div>
      ),
    },

    {
      label: (
        <div className="icon_div">
          <ServiceDesk style={{ marginRight: "8px" }} />
          {"Service"}
        </div>
      ),
    },
    {
      label: (
        <div className="icon_div">
          <Notebook style={{ marginRight: "8px" }} />
          {"Subject"}
        </div>
      ),
    },
    {
      label: (
        <div className="icon_div">
          <Phone style={{ marginRight: "8px" }} />
          {"Contact"}
        </div>
      ),
    },
    {
      label: (
        <div className="icon_div">
          <Attachment style={{ marginRight: "8px" }} />
          {"Attachments"}
        </div>
      ),
    },
  ];

  const [selectedItem, setSelectedItem] = useState("");

  useEffect(() => {
    scrollSpy.update();
  }, []);

  useEffect(() => {
    const dropdown = dropdownRef.current;

    if (dropdown) {
      const icon = dropdown.querySelector(".cds--list-box__menu-icon");

      if (icon) {
        const svgIcon = <List size={16} />;
        icon.innerHTML = "";
        ReactDOM.render(svgIcon, icon);
      }
    }
  }, []);

  useEffect(() => {
    setSelectedItem(items[0]);
  }, []);

  const handleDropdownChange = (event) => {
    setSelectedItem(event.selectedItem.value);
    const selectedElement = document.getElementById(
      event.selectedItem.label.props.children[1].toLowerCase()
    );

    // Scroll to the selected element
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <>
      <TicketStyle className="dark:!bg-[#161616]">
        {/* <FlexGrid fullWidth className="!p-0 flex-grid "> */}
        <div className=" dark:!bg[#161616] dropdownsticky">
          <Row className="md:px-[10px] dropdown_request  dark:!bg-[#161616]">
            <Dropdown
              ref={dropdownRef}
              id="default"
              selectedItem={selectedItem}
              onChange={handleDropdownChange}
              items={items}
              itemToString={(item) => (item ? item.label : "")}
              className=" bg-[#f4f4f4] z-50"
            />
          </Row>
        </div>

        <FlexGrid fullWidth className="">
          <div className="maindiv_create">
            <Row>
              <Column lg={10}>
                <div className="service_create">
                  <BreadcrumbItem>
                    <Link to={"/"} className="dark:!text-[#77A6FF] breadcrumb">
                      Support Dashboard{" "}
                    </Link>
                  </BreadcrumbItem>

                  <span className="dark:!text-[#fff] text-[#000]">
                    Create A Service Request
                  </span>
                </div>
                <div className="mainheading">
                  <h1>Create A Service Request</h1>
                </div>
              </Column>
            </Row>
          </div>
        </FlexGrid>
        {/* {width <= 768 && (
            <div style={{ padding: "0 16px  25px 16px" }}>
              <CustomButton
                text="Cancel"
                fullWidth
                handleClick={() => {
                  navigate("/request");
                }}
              />
            </div>
          )} */}
        {/* </FlexGrid> */}

        <FlexGrid
          fullWidth
          style={{ backgroundColor: "#f4f4f4" }}
          className="dark:!bg-[#161616]"
        >
          <div className="dark:!text-white container  dark:!bg-[#161616]">
            <Row>
              <Column lg={3} className="scroll-spy-side-nav-col">
                <div className="scroll-spy-link-box sticky top-[70px] dark:!text-white ">
                  <Slink
                    activeClass="active"
                    href="javascript:void(0)"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-100}
                    to="service-request-type"
                  >
                    <div className="spy-box-content dark:!text-white dark:!opacity-unset">
                      <Categories /> Service Request Type
                    </div>
                  </Slink>

                  <Slink
                    activeClass="active"
                    href="javascript:void(0)"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-100}
                    to="service"
                  >
                    <div className="spy-box-content dark:!text-white dark:!opacity-unset">
                      <ServiceDesk /> Service
                    </div>
                  </Slink>

                  <Slink
                    activeClass="active"
                    href="javascript:void(0)"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-100}
                    to="subject"
                  >
                    <div className="spy-box-content dark:!text-white dark:!opacity-unset">
                      <Notebook />
                      Subject
                    </div>
                  </Slink>

                  <Slink
                    activeClass="active"
                    href="javascript:void(0)"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-100}
                    to="contact"
                  >
                    <div className="spy-box-content dark:!text-white dark:!opacity-unset">
                      <Phone />
                      Contact
                    </div>
                  </Slink>

                  <Slink
                    activeClass="active"
                    href="javascript:void(0)"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-100}
                    to="attachments"
                  >
                    <div className="spy-box-content dark:!text-white dark:!opacity-unset">
                      <Attachment />
                      Attachments
                    </div>
                  </Slink>
                </div>
              </Column>

              <Column
                lg={10}
                className="bg-[#f4f4f4] dark:!bg-[#161616] createticketform"
              >
                <CreateTicketSideNavForm
                  isFullView={true}
                  renderCancelBtn={false}
                  withHeader={false}
                  submitBtnContainerClass="!justify-end pr-8"
                />
              </Column>

              {/* {width > 768 && (
                  <Column
                    lg={3}
                    // className={`flex justify-end ${
                    //   width > 1055 ? "" : "mt-10"
                    // } `}
                  >
                    <CustomButton
                      text="Cancel "
                      style={{ width: "100%" }}
                      fullWidth
                      handleClick={() => {
                        navigate("/request");
                      }}
                    />
                  </Column>
                )} */}
            </Row>
          </div>
        </FlexGrid>
      </TicketStyle>
    </>
  );
};

export default CreteTicket;
