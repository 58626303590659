import {
  Chat,
  Close,
  Launch,
  Minimize,
  Phone,
  PhoneFilled,
  PhoneOutgoing,
  RequestQuote,
  Add,
} from "@carbon/icons-react";
import {
  Button,
  Column,
  Content,
  DataTable,
  Dropdown,
  HeaderMenuButton,
  InlineNotification,
  Layer,
  Modal,
  Pagination,
  Row,
  Select,
  SelectItem,
  SideNav,
  SideNavItems,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Tabs,
  TextInput,
} from "@carbon/react";
import { Contextprovider } from "App";
import { countrycode } from "Component/Data/DataJson";
import { support_call_schema } from "Valdation/Validation";
import ServiceRequestStatus from "components/ServiceRequestStatus";
import CustomButton from "components/button/customButton";
import NoDataInTable from "components/datatable/noDataInTable";
import CreateRequestForm from "components/forms/createRequestForm";
import Header from "components/header";
import { Formik } from "formik";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getSupportTickets } from "redux/slices/Support/SupportSlice";
import {
  handleBrowser,
  handleCreateTicketTab,
  handleSupportTabIndex,
  handleSupportTabMaximized,
  headerState,
  reset,
} from "redux/slices/headerSlice";
import FullScreenModalWrapper from "./style";
import { GlobalContext } from "ContextApi/GlobalContext";

// import { scrollSpy } from "react-scroll";
import { error_toast, success_toast } from "Toast";
import axiosInstance from "axiosintance";
import Browsercall from "components/header/tabs/support/tabs/call/BrowserCall";
import ChatTab from "components/header/tabs/support/tabs/chat";
import { getServiceRequestStatusText } from "utils";
import TicketCreateSuccessModal from "../TicketCreateSuccessModal/TicketCreateSuccessModal";

const FullScreenModal = () => {
  const [activePage, setActivePage] = useState({ page: 1, pageSize: 10 });
  const { useralldata } = useContext(Contextprovider);
  const { subscriptions } = useSelector((state) => state);
  const { browserCall } = useSelector(headerState);
  const context = useContext(GlobalContext);
  const [statusFilter, setStatusFilter] = useState([]);
  const navigate = useNavigate();

  const globalContext = useContext(GlobalContext);

  const isDarkTheme = useMemo(
    () => globalContext.globalTheme === "g100",
    [globalContext.globalTheme]
  );

  const makeCall = async (values) => {
    try {
      let { data } = await axiosInstance.post(
        `${process.env.REACT_APP_NOTIFICATION_URL}/public/call-back`,
        {
          phoneNo: values.country_code.slice(3) + values.phone_number,
          user_id: useralldata?.userinfo?.userid,
          platform: "uvation support",
        }
      );
      success_toast(data.message || "Successfully registered the callback");
    } catch (err) {
      console.log(err);
      error_toast(err);
    }
  };
  const { supportTabIndex, isSupportTabMaximized, createTicketTab } =
    useSelector(headerState);
  const dispatch = useDispatch();
  function handlePageChange(pageNumber) {
    dispatch(
      getSupportTickets({ email: useralldata?.userinfo?.email, pageNumber })
    );
    setActivePage(pageNumber || { page: 1, pageSize: 10 });
  }
  useEffect(() => {
    handlePageChange(); // You may want to pass the active page as a parameter here
  }, [useralldata]);

  const {
    tickets: { data: tickets },
    tickets: { count },
  } = useSelector((state) => state.support);

  function getTickets(status) {
    if (status.length) {
      setStatusFilter([{ id: status, text: status }]);
    } else {
      setStatusFilter([]);
    }

    dispatch(
      getSupportTickets({
        email: useralldata?.userinfo?.email,
        values: {
          ...statusFilter,
          page_no: 1,
          per_page: 10,
          status: [{ id: status, text: status }],
        },
      })
    );
  }

  return (
    <FullScreenModalWrapper isDarkTheme={isDarkTheme}>
      <Modal
        open={isSupportTabMaximized}
        isFullWidth
        primaryButtonDisabled
        id="fullscreen-modal"
        className="fullscree_modaldata"
      >
        {/* <Header /> */}
        <Tabs
          selectedIndex={supportTabIndex}
          onChange={({ selectedIndex }) => {
            dispatch(handleSupportTabIndex(selectedIndex));
          }}
        >
          <SideNav
            className="dark:!bg-[#161616]"
            style={{
              backgroundColor: "#f4f4f4",
              top: 0,
            }}
            aria-label="Side navigation"
            expanded={true}
            isFixedNav={true}
            isChildOfHeader={true}
          >
            <SideNavItems>
              <div className="side-panel-heading">
                <span className="dark:!text-white">Support & Help </span>
                {/* <HeaderMenuButton
                  renderMenuIcon={<Close size={24} />}
                  onClick={() => dispatch(handleSupportTabMaximized())}
                /> */}
              </div>

              <TabList aria-label="Support tabs">
                <Tab>
                  <Chat /> &nbsp;Chat
                </Tab>
                <Tab onClick={() => dispatch(handleCreateTicketTab(false))}>
                  <RequestQuote />
                  &nbsp; Requests
                </Tab>
                <Tab>
                  <Phone />
                  &nbsp; Call
                </Tab>
              </TabList>
            </SideNavItems>
          </SideNav>
          <Content
            className={
              browserCall
                ? " support-modal-content dark:!bg-[#393939]"
                : " support-modal-content dark:!bg-[#161616]"
            }
          >
            <TabPanels>
              <TabPanel
                className="dark:!bg-[#161616] "
                style={{ minHeight: "100%", padding: 0 }}
              >
                <div
                  className={
                    browserCall
                      ? "button-box dark:!bg-[#393939]"
                      : "button-box dark:!bg-[#161616]"
                  }
                >
                  <HeaderMenuButton
                    renderMenuIcon={<Minimize size={14} />}
                    onClick={() => dispatch(handleSupportTabMaximized())}
                  />
                  <HeaderMenuButton
                    renderMenuIcon={<Close size={14} />}
                    onClick={() => dispatch(reset())}
                  />
                </div>

                <div className="p-4">
                  <ChatTab />
                </div>
              </TabPanel>

              <TabPanel
                className="request-tab dark:!bg-[#161616]"
                style={{ minHeight: "100%", padding: 0 }}
              >
                <div
                  className={
                    browserCall
                      ? "button-box dark:!bg-[#393939]"
                      : "button-box dark:!bg-[#161616]"
                  }
                >
                  <HeaderMenuButton
                    renderMenuIcon={<Minimize size={14} />}
                    onClick={() => dispatch(handleSupportTabMaximized())}
                  />
                  <HeaderMenuButton
                    renderMenuIcon={<Close size={14} />}
                    onClick={() => dispatch(reset())}
                  />
                </div>

                {!createTicketTab ? (
                  <div className="p-4 !bg-[#f4f4f4] dark:!bg-[#161616]">
                    <ServiceRequestStatus
                      onStatusChange={(status) => getTickets(status)}
                      filters={statusFilter}
                    />

                    <div className="support-tab-content  dark:!border-[#474747]">
                      <DataTable rows={tickets} headers={Requesheader}>
                        {({
                          rows,
                          headers,
                          getHeaderProps,
                          getRowProps,
                          getTableProps,
                          onInputChange,
                        }) => (
                          <TableContainer>
                            <TableToolbar>
                              <TableToolbarContent>
                                <TableToolbarSearch
                                  className="dark:!bg-[#161616] "
                                  onChange={onInputChange}
                                  expanded
                                  placeholder="Search input text"
                                />

                                <CustomButton
                                  text="Create A Service Request"
                                  icon={Add}
                                  handleClick={() =>
                                    dispatch(handleCreateTicketTab(true))
                                  }
                                />
                              </TableToolbarContent>
                            </TableToolbar>

                            {tickets.length ? (
                              <Table {...getTableProps()} isSortable>
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header) => (
                                      <TableHeader
                                        className="dark:!text-white"
                                        key={header.key}
                                        {...getHeaderProps({ header })}
                                      >
                                        {header.header}
                                      </TableHeader>
                                    ))}
                                    <TableHeader />
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rows.map((row, row_inx) => (
                                    <TableRow
                                      className="table_row "
                                      key={row.id}
                                      onClick={() =>
                                        navigate(`/ticketdetails/${row.id}`)
                                      }
                                      {...getRowProps({ row })}
                                    >
                                      {row.cells.map((cell, inx) => {
                                        return (
                                          <React.Fragment key={cell.id}>
                                            <TableCell
                                              className={`!bg-[transparent] dark:!text-white table_column ${
                                                inx === "statusColumnIndex"
                                                  ? "status-cell"
                                                  : ""
                                              }`}
                                            >
                                              {cell.id.includes("status") &&
                                              tickets[row_inx]?.status ? (
                                                <span
                                                  className="!bg-[#D0E2FF] dark:!bg-[#D0E2FF] !text-[#0043CE] px-3 py-1 "
                                                  style={{
                                                    borderRadius: "1000px",
                                                  }}
                                                >
                                                  {getServiceRequestStatusText(
                                                    tickets[row_inx].status
                                                  )}
                                                </span>
                                              ) : (
                                                cell.value
                                              )}
                                            </TableCell>
                                          </React.Fragment>
                                        );
                                      })}
                                      {/* <TableCell className="cds--table-column-menu !bg-white">
                                        <div
                                          style={{
                                            paddingTop: "1rem",
                                          }}
                                        >
                                          <Link
                                            className="text-[#0F61FD]"
                                            to={`/ticketdetails/${row.id}`}
                                          >
                                            <Launch
                                              size={16}
                                              style={{
                                                color:
                                                  context.globalTheme == "white"
                                                    ? "#2570FE"
                                                    : "#77A6FF",
                                              }}
                                            />
                                          </Link>
                                        </div>
                                      </TableCell> */}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            ) : (
                              <div className="no_data">
                                <div className="request_quote_div">
                                  <RequestQuote size={68} />
                                </div>
                                <h6>No Requests</h6>
                                <span>
                                  Create a new request to view the your <br />{" "}
                                  status.
                                </span>
                              </div>
                            )}
                          </TableContainer>
                        )}
                      </DataTable>

                      {tickets.length > 0 && (
                        <div>
                          <Pagination
                            // pagesUnknown={true}
                            backwardText="Previous page"
                            forwardText="Next page"
                            itemsPerPageText=""
                            page={activePage.page || 1}
                            pageSize={activePage.pageSize || 10}
                            pageNumberText="Page Number"
                            pageSizes={[10]}
                            totalItems={count || 0}
                            onChange={handlePageChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="pt-0 pr-4 pl-4 pb-4 dark:!bg-[#161616] !bg-[#f4f4f4]">
                    <CreateRequestForm isFullView={false} />
                  </div>
                )}
              </TabPanel>

              <TabPanel
                style={{ minHeight: "100%", padding: 0 }}
                className={
                  browserCall ? "browser_callpanel" : "dark:!bg-[#161616]"
                }
              >
                <div
                  className={
                    browserCall
                      ? "button-box dark:!bg-[#393939]"
                      : "button-box dark:!bg-[#161616]"
                  }
                >
                  <HeaderMenuButton
                    renderMenuIcon={<Minimize size={14} />}
                    onClick={() => dispatch(handleSupportTabMaximized())}
                  />
                  <HeaderMenuButton
                    renderMenuIcon={<Close size={14} />}
                    onClick={() => dispatch(reset())}
                  />
                </div>
                <div className="">
                  {!subscriptions?.data?.length ? (
                    // || true
                    <Formik
                      initialValues={{
                        country_code:
                          countrycode[5].code + countrycode[5].dial_code,
                        phone_number: "",
                      }}
                      onSubmit={(values) => {
                        makeCall(values);
                      }}
                      validationSchema={support_call_schema}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                      }) => (
                        <>
                          <div className="call_centerdata dark:!border-none">
                            <div
                              className={
                                browserCall
                                  ? "button-box !bg-[#393939] !text-white"
                                  : " dark:!bg-[#161616]"
                              }
                            >
                              <HeaderMenuButton
                                renderMenuIcon={
                                  <Minimize
                                    size={14}
                                    className={browserCall ? "!fill-white" : ""}
                                  />
                                }
                                onClick={() =>
                                  dispatch(handleSupportTabMaximized())
                                }
                              />
                              <HeaderMenuButton
                                renderMenuIcon={
                                  <Close
                                    size={14}
                                    className={browserCall ? "!fill-white" : ""}
                                  />
                                }
                                onClick={() => dispatch(reset())}
                              />
                            </div>

                            <div className="flex justify-center p-4 mt-8 w-full">
                              <h3 className="text-base">Coming Soon</h3>
                            </div>

                            {/* <>
                              {!browserCall ? (
                                <>
                                  <div className="call_centerdataee dark:!bg-[#262626]">
                                    <Row>
                                      <Column lg={10}>
                                        <div className="choose_country">
                                          <h5>Choose Your Country</h5>
                                          <p>
                                            Please select the phone number of
                                            the country you are currently
                                            located in when seeking support
                                            within your respective country.
                                          </p>
                                        </div>
                                        <div className="country_code">
                                          <Dropdown
                                            label="+ 00"
                                            id="drop-4"
                                            style={{ width: "8rem" }}
                                            items={countrycode.map((item) => ({
                                              label: `${item?.code}${item?.dial_code}`,
                                            }))}
                                            selectedItem={values.country_code}
                                            onChange={({ selectedItem }) =>
                                              setFieldValue(
                                                "country_code",
                                                selectedItem.label
                                              )
                                            }
                                            onBlur={handleBlur}
                                          />
                                          <TextInput
                                            id="test17"
                                            // invalidText={errors.phone_number}
                                            // invalid={touched.phone_number && errors.phone_number}
                                            placeholder="(619) 567 3200"
                                            name="phone_number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phone_number}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            color: "#da1e28",
                                            marginTop: "5px",
                                          }}
                                        >
                                          <span style={{ fontSize: "12px" }}>
                                            {errors.phone_number}
                                          </span>
                                        </div>
                                      </Column>

                                      <Column lg={6}>
                                        <div className="phonecall_btn">
                                          <Button
                                            renderIcon={PhoneFilled}
                                            type="submit"
                                            kind="secondary"
                                            onClick={handleSubmit}
                                            disabled={
                                              !_.isEmpty(errors) ||
                                              !values.phone_number
                                            }
                                          >
                                            Phone Call
                                          </Button>

                                          <Button
                                            renderIcon={PhoneFilled}
                                            kind="primary"
                                            onClick={() => {
                                              dispatch(
                                                handleBrowser(!browserCall)
                                              );
                                            }}
                                          >
                                            Browser Call
                                          </Button>
                                        </div>
                                      </Column>
                                    </Row>
                                  </div>

                                  <Row style={{ marginInline: 0 }}>
                                    <Column>
                                      <div className="recent_heding">
                                        <h6>Recent</h6>
                                      </div>
                                    </Column>
                                  </Row>

                                  <div
                                    style={{
                                      borderBottom: "1px solid #8D8D8D",
                                    }}
                                  ></div>
                                  <div>
                                    <div className="support_centertime">
                                      <ul>
                                        <span>
                                          <PhoneOutgoing size={16} />
                                        </span>
                                        <li>
                                          <h6>Support Center (US)</h6>
                                          <span>10/30/23, 1:08 PM</span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="support_centertime">
                                      <ul>
                                        <span>
                                          <PhoneOutgoing size={16} />
                                        </span>
                                        <li>
                                          <h6>Support Center (US)</h6>
                                          <span>10/30/23, 1:08 PM</span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="support_centertime">
                                      <ul>
                                        <span>
                                          <PhoneOutgoing size={16} />
                                        </span>
                                        <li>
                                          <h6>Support Center (US)</h6>
                                          <span>10/30/23, 1:08 PM</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <Browsercall />
                              )}
                            </> */}
                          </div>
                        </>
                      )}
                    </Formik>
                  ) : (
                    <div className="claatalk_agent">
                      <div
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h4>Talk with an Agent</h4>
                        <div className="callBtn p-4">
                          <Button renderIcon={Phone} href="tel:1-123-456-7890">
                            Call Now((619) 815-8740)
                          </Button>
                        </div>
                      </div>
                      <div className="support_content">
                        <p>
                          {" "}
                          Need assistance? Our dedicated support team is just a
                          phone call away. Feel free to reach out to our support
                          hotline:
                        </p>
                        <br />
                        <p>
                          {" "}
                          Our friendly and knowledgeable support agents are
                          available to help you with any questions or issues you
                          may have. We're here to provide you with the best
                          possible assistance. Your satisfaction is our
                          priority.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </TabPanel>
            </TabPanels>
          </Content>
        </Tabs>
      </Modal>
    </FullScreenModalWrapper>
  );
};

export default FullScreenModal;
export const Requesheader = [
  {
    header: "Ticket#",
    key: "id",
  },
  {
    header: "Date",
    key: "created_at",
  },
  {
    header: "Case Type",
    key: "casetype",
  },
  {
    header: "Subject",
    key: "subject",
  },

  {
    header: "Severity",
    key: "servity",
  },
  {
    header: "Status",
    key: "status",
  },
];
