import { Box } from "@twilio-paste/core/box";
// import { Button } from "@twilio-paste/core/button";
// import { Input } from "@twilio-paste/core/input";
// import { Label } from "@twilio-paste/core/label";
import { Text } from "@twilio-paste/core/text";
import { useDispatch, useSelector } from "react-redux";
import { initSession } from "redux/reducers/actions/initActions";
import {
  changeEngagementPhase,
  updatePreEngagementData,
} from "redux/reducers/chat.reducer";
import { EngagementPhase } from "redux/reducers/definitions";
import { addNotification } from "redux/reducers/notification.reducer";
import { notifications } from "utils/notifications";
import { sessionDataHandler } from "utils/sessionDataHandler";
import { useSanitizer } from "utils/useSanitizer";
import { Header } from "./Header";
import { NotificationBar } from "./NotificationBar";
import {
  fieldStyles,
  formStyles,
  introStyles,
  titleStyles,
  textInputStyles,
} from "./styles/PreEngagementFormPhase.styles";
import CustomButton from "components/button/customButton";
import {
  Dropdown,
  FileUploaderDropContainer,
  Layer,
  Loading,
  RadioTile,
  Select,
  SelectItem,
  SideNavDivider,
  Tag,
  TextArea,
  TextInput,
  TileGroup,
  ToastNotification,
} from "@carbon/react";
import { Contextprovider } from "../../App";
import { useContext } from "react";
import { GlobalContext } from "ContextApi/GlobalContext";

export const PreEngagementFormPhase = () => {
  const { name, email, query } =
    useSelector((state) => state.chat.preEngagementData) || {};
  const dispatch = useDispatch();
  const { onUserInputSubmit } = useSanitizer();
  const { useralldata } = useContext(Contextprovider);
  const context = useContext(GlobalContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
    try {
      const data = await sessionDataHandler.fetchAndStoreNewSession({
        formData: {
          friendlyName:
            useralldata?.userinfo?.fullname &&
            onUserInputSubmit(useralldata?.userinfo?.fullname, true),
          email: useralldata?.userinfo?.email,
          query: query && onUserInputSubmit(query),
        },
      });

      dispatch(
        initSession({
          token: data.token,
          conversationSid: data.conversationSid,
        })
      );
    } catch (err) {
      alert(err);
      dispatch(
        addNotification(
          notifications.failedToInitSessionNotification(err.message)
        )
      );
      dispatch(
        changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm })
      );
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <>
      <Header />
      <NotificationBar />
      <Box
        as="form"
        data-test="pre-engagement-chat-form"
        onSubmit={handleSubmit}
        {...formStyles}
      >
        <Text {...titleStyles} as="h3">
          Hi there!
        </Text>
        <Text {...introStyles} as="p">
          We&#39;re here to help. Please give us some info to get started.
        </Text>

        <Box {...fieldStyles}>
          <TextInput
            type="text"
            placeholder="Please enter your name"
            name="name"
            data-test="pre-engagement-chat-form-name-input"
            // value={name}
            value={useralldata?.userinfo?.fullname}
            onChange={(e) =>
              dispatch(updatePreEngagementData({ name: e.target.value }))
            }
            pattern="[A-Za-z0-9' ]{1,}"
            required
            labelText="Name"
            style={{
              background: context.globalTheme == "white" ? "white" : "#262626",
            }}
          />
        </Box>

        <Box {...fieldStyles}>
          <TextInput
            type="email"
            placeholder="Please enter your email address"
            name="email"
            data-test="pre-engagement-chat-form-email-input"
            // value={email}
            value={useralldata?.userinfo?.email}
            onChange={(e) =>
              dispatch(updatePreEngagementData({ email: e.target.value }))
            }
            required
            labelText="Email address"
            style={{
              background: context.globalTheme == "white" ? "white" : "#262626",
            }}
          />
        </Box>

        <Box {...fieldStyles}>
          <TextArea
            placeholder="Ask a question"
            name="query"
            data-test="pre-engagement-chat-form-query-textarea"
            value={query}
            onChange={(e) =>
              dispatch(updatePreEngagementData({ query: e.target.value }))
            }
            onKeyPress={handleKeyPress}
            required
            labelText="How can we help you?"
            style={{
              background: context.globalTheme == "white" ? "white" : "#262626",
            }}
          />
        </Box>

        <CustomButton type="submit" text={"Start Chat"} />
      </Box>
    </>
  );
};
