import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllNotificationsService,
  getDonotdistrubButton,
  getUnseenNotificationsService,
} from "./NotificationsServices";

export const getUnseenNotifications = createAsyncThunk(
  "users/fetchUnseenNotifications",
  async (userId, thunkAPI) => {
    try {
      return await getUnseenNotificationsService();
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllNotifications = createAsyncThunk(
  "users/fetchAllNotifications",
  async (userId, thunkAPI) => {
    try {
      return await getAllNotificationsService();
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getDisturbData = createAsyncThunk(
  "users/DisturbData",
  async (userId, thunkAPI) => {
    try {
      return await getDonotdistrubButton();
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  unseenNotifications: {
    error: null,
    status: "idle", // succeeded || idle || failed || loading
    data: [],
    message: "",
    isloading: false,
  },
  allNotifications: {
    error: null,
    status: "idle",
    data: { data: [], count: 0 },
    message: "",
  },
  Notificationstop: {
    error: null,
    status: "idle",
    data: "",
    message: "",
  },
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUnseenNotifications.fulfilled, (state, { payload }) => {
      state.unseenNotifications.data = payload || [];
      state.unseenNotifications.error = null;
      state.unseenNotifications.status = "succeeded";
    });
    builder.addCase(getUnseenNotifications.pending, (state, payload) => {
      state.unseenNotifications.status = "loading";
      // state.unseenNotifications.data = null;
      state.unseenNotifications.error = null;
    });
    builder.addCase(getUnseenNotifications.rejected, (state, { payload }) => {
      state.unseenNotifications.status = "failed";
      state.unseenNotifications.data = null;
      state.unseenNotifications.error = payload;
    });
    builder.addCase(getAllNotifications.fulfilled, (state, { payload }) => {
      state.allNotifications.data =
        payload || initialState.allNotifications.data;
      state.allNotifications.error = null;
      state.allNotifications.status = "succeeded";
    });
    builder.addCase(getAllNotifications.pending, (state, payload) => {
      state.allNotifications.status = "loading";
      state.allNotifications.data = initialState.allNotifications.data;
      state.allNotifications.error = null;
    });
    builder.addCase(getAllNotifications.rejected, (state, { payload }) => {
      state.allNotifications.status = "failed";
      state.allNotifications.data = initialState.allNotifications.data;
      state.allNotifications.error = payload;
    });
    builder.addCase(getDisturbData.fulfilled, (state, { payload }) => {
      state.Notificationstop.data =
        payload || initialState.Notificationstop.data;
      state.Notificationstop.error = null;
      state.Notificationstop.status = "succeeded";
    });
    builder.addCase(getDisturbData.pending, (state, payload) => {
      state.Notificationstop.status = "loading";
      state.Notificationstop.data = initialState.Notificationstop.data;
      state.Notificationstop.error = null;
    });
    builder.addCase(getDisturbData.rejected, (state, { payload }) => {
      state.Notificationstop.status = "failed";
      state.Notificationstop.data = initialState.Notificationstop.data;
      state.allNotifications.error = payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = notificationsSlice.actions;

export default notificationsSlice.reducer;
