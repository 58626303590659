import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  notificationTab: false,
  accountTab: false,
  supportTab: false,
  supportTabIndex: 0,
  isSupportTabMaximized: false,
  themeModal: false,
  search: "",
  sideNav: false,
  switcher: false,
  createTicketTab: false,
  createTicketComplete: false,
  ticketCompleteData: {},
  browserCall: false,
  globalMenu: false,
  isFullView: false,
  activeIndexOfTicket: null,
  activeRow: null,
  // This state is for status filter everywhere of SERVICE REQUEST STATUS
  activeStatusOfServiceStatus: null,
  mobileNav: false,
  TicketFilter: {
    StartDate: "",
    EndDate: "",
    status: [],
    severity: [],
    castype: [],
  },
};

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    handleNotificationTab: (state) => {
      return {
        ...initialState,
        notificationTab: !state.notificationTab,
      };
    },
    handleAccountTab: (state) => {
      return {
        ...initialState,
        accountTab: !state.accountTab,
      };
    },
    handleSupportTab: (state, action) => {
      return {
        ...initialState,
        supportTab: action.payload,
      };
    },
    handleThemeModal: (state) => {
      return {
        ...initialState,
        themeModal: !state.themeModal,
      };
    },
    handleSupportTabMaximized: (state) => {
      return {
        ...state,
        isSupportTabMaximized: !state.isSupportTabMaximized,
        supportTab: true,
      };
    },

    handleCreateTicketTab: (state, action) => {
      return {
        ...state,
        createTicketTab: action.payload,
      };
    },

    handleCreateTicketComplete: (state, action) => {
      return {
        ...state,
        createTicketComplete: action.payload.toggle,
        ticketCompleteData: action.payload.res,
        isFullView: action.payload.isFullView,
      };
    },

    handleGlobalNav: (state) => {
      return {
        ...initialState,
        globalMenu: !state.globalMenu,
      };
    },

    handleSideNav: (state) => {
      return {
        ...initialState,
        sideNav: !state.sideNav,
      };
    },
    handleSwitcher: (state) => {
      return {
        ...initialState,
        switcher: !state.switcher,
      };
    },

    handleMobileSideNav: (state) => {
      return {
        ...initialState,
        mobileNav: !state.mobileNav,
      };
    },

    handleActiveStatusOfServices: (state, action) => {
      return {
        ...initialState,
        activeStatusOfServiceStatus: action.payload.status,
      };
    },
    handleActiveIndex: (state, action) => {
      return {
        ...initialState,
        activeIndexOfTicket: action.payload,
        activeRow: action.payload,
      };
    },
    handleSearch: (state, action) => {
      return {
        ...state,
        search: action.payload,
      };
    },
    handleSupportTabIndex: (state, action) => {
      return {
        ...state,
        supportTabIndex: action.payload,
      };
    },
    handleBrowser: (state) => {
      return {
        ...state,
        browserCall: !state.browserCall,
      };
    },

    handleTicketFilter: (state, action) => {
      return {
        ...state,
        TicketFilter: action.payload,
      };
    },

    reset: () => {
      return initialState;
    },
  },
});

export const {
  handleNotificationTab,
  handleAccountTab,
  handleSupportTab,
  handleThemeModal,
  handleSearch,
  handleSwitcher,
  handleSideNav,
  handleSupportTabIndex,
  handleSupportTabMaximized,
  handleCreateTicketTab,
  handleBrowser,
  reset,
  handleGlobalNav,
  handleCreateTicketComplete,
  handleActiveIndex,
  handleActiveStatusOfServices,
  handleMobileSideNav,
  handleTicketFilter,
} = headerSlice.actions;
export const headerState = (state) => state.header;
export default headerSlice.reducer;
