import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  TicketFilter: {
    StartDate: "",
    EndDate: "",
    status: [],
    severity: [],
    castype: [],
  },
};

const ticketSlice = createSlice({
  name: "filterTicket",
  initialState,
  reducers: {
    handleTicketFilter: (state, action) => {
      state.TicketFilter = action.payload;
    },
  },
});

export const { handleTicketFilter } = ticketSlice.actions;
export default ticketSlice.reducer;
