import merge from "lodash.merge";
import store from "redux/store";
import { getLogger, initLogger } from "utils/logger";
import { sessionDataHandler } from "utils/sessionDataHandler";
import { loadConfig } from "../config.reducer";

const defaultConfig = {
  deploymentKey: "",
  region: "",
  theme: {
    isLight: true,
  },
  fileAttachment: {
    enabled: true,
    maxFileSize: 16777216, // 16 MB
    acceptedExtensions: [
      "jpg",
      "jpeg",
      "png",
      "amr",
      "mp3",
      "mp4",
      "pdf",
      "txt",
    ],
  },
};

export const initWebchat = async (userConfig) => {
  // TODO: serverUrl needs to be removed with PR #74
  const validKeys = ["deploymentKey", "region", "theme", "serverUrl"];
  const logger = window.Twilio.getLogger(`InitWebChat`);

  // TODO: Returning from here if no deployment key with PR #74
  if (!userConfig?.deploymentKey) {
    logger.error(`deploymentKey must exist to connect to webchat servers`);
  }

  Object.keys(userConfig).forEach((userConfigKey) => {
    if (!validKeys.includes(userConfigKey)) {
      logger.warn(`${userConfigKey} is not supported.`);
    }
  });

  const webchatConfig = merge({}, defaultConfig, userConfig);

  sessionDataHandler.setEndpoint(webchatConfig.serverUrl);
  sessionDataHandler.setRegion(webchatConfig.region);
  sessionDataHandler.setDeploymentKey(webchatConfig.deploymentKey);

  store.dispatch(loadConfig(webchatConfig));
  // const rootElement = document.getElementById("twilio-webchat-widget-root");
  // const reactRoot = createRoot(rootElement)
  logger.info("Now rendering the webchat");

  // if (window.Cypress) {
  //     window.store = store;
  // }
};

// Expose `initWebchat` function to window object
Object.assign(window, {
  Twilio: {
    initWebchat,
    initLogger,
    getLogger,
  },
});
