export const containerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "colorBackgroundPrimaryStronger",
  padding: "space20",
  paddingTop: "space40",
  paddingBottom: "space40",
  // borderTopLeftRadius: "borderRadius20",
  // borderTopRightRadius: "borderRadius20"
};

export const titleStyles = {
  color: "colorTextWeakest",
  paddingLeft: "space30",
};
