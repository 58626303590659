import { Box } from "@twilio-paste/core/box";
import { Flex } from "@twilio-paste/core/flex";
import { ScreenReaderOnly } from "@twilio-paste/core/screen-reader-only";
import { Text } from "@twilio-paste/core/text";
import { SuccessIcon } from "@twilio-paste/icons/esm/SuccessIcon";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { parseMessageBody } from "utils/parseMessageBody";
import { FilePreview } from "./FilePreview";
import {
  authorStyles,
  bodyStyles,
  bubbleAndAvatarContainerStyles,
  getAvatarContainerStyles,
  getInnerContainerStyles,
  outerContainerStyles,
  readStatusStyles,
  timeStampStyles,
} from "./styles/MessageBubble.styles";
import { memoize } from "proxy-memoize";
import { GlobalContext } from "ContextApi/GlobalContext";

const doubleDigit = (number) => `${number < 10 ? 0 : ""}${number}`;

const selector = memoize((state) => ({
  conversationsClient: state.chat.conversationsClient,
  participants: state.chat.participants,
  users: state.chat.users,
  fileAttachmentConfig: state.config.fileAttachment,
}));

export const MessageBubble = ({
  message,
  isLast,
  isLastOfUserGroup,
  focusable,
  updateFocus,
}) => {
  const [read, setRead] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const { conversationsClient, participants, users, fileAttachmentConfig } =
    useSelector(selector);
  const messageRef = useRef(null);
  const globalContext = useContext(GlobalContext);

  const belongsToCurrentUser = useMemo(
    () => message.author === conversationsClient?.user.identity,
    [message, conversationsClient]
  );

  const isDarkTheme = useMemo(
    () => globalContext.globalTheme === "g100",
    [globalContext.globalTheme]
  );

  useEffect(() => {
    if (isLast && participants && belongsToCurrentUser) {
      const getOtherParticipants = participants.filter(
        (p) => p.identity !== conversationsClient?.user.identity
      );

      setRead(
        Boolean(getOtherParticipants.length) &&
          getOtherParticipants.every(
            (p) => p.lastReadMessageIndex === message.index
          )
      );
    } else {
      setRead(false);
    }
  }, [
    participants,
    isLast,
    belongsToCurrentUser,
    conversationsClient,
    message,
  ]);

  useEffect(() => {
    if (focusable) {
      messageRef.current?.focus();
    }
  }, [focusable]);

  const renderMedia = useCallback(() => {
    if (fileAttachmentConfig?.enabled) {
      if (!message.attachedMedia) {
        return null;
      }

      return message.attachedMedia.map((media, index) => {
        const file = {
          name: media.filename,
          type: media.contentType,
          size: media.size,
        };
        return (
          <FilePreview
            key={index}
            file={file}
            isBubble={true}
            media={media}
            focusable={focusable}
          />
        );
      });
    }

    return <i>Media messages are not supported</i>;
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      const newFocusValue = message.index + (e.key === "ArrowUp" ? -1 : 1);
      updateFocus(newFocusValue);
    }
  };

  const handleMouseDown = () => {
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleFocus = () => {
    // Ignore focus from clicks
    if (!isMouseDown) {
      // Necessary since screen readers can set the focus to any focusable element
      updateFocus(message.index);
    }
  };

  const author =
    users?.find((u) => u.identity === message.author)?.friendlyName ||
    message.author;

  return (
    <Box
      {...outerContainerStyles}
      tabIndex={focusable ? 0 : -1}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      ref={messageRef}
      data-message-bubble
      data-testid="message-bubble"
    >
      <Box {...bubbleAndAvatarContainerStyles}>
        {!belongsToCurrentUser && (
          <Box {...getAvatarContainerStyles} data-testid="avatar-container">
            {isLastOfUserGroup && (
              <UserIcon decorative={true} size="sizeIcon40" />
            )}
          </Box>
        )}

        <Box
          {...getInnerContainerStyles(belongsToCurrentUser, isDarkTheme)}
          className={`message ${
            belongsToCurrentUser && "belongsToCurrentUser"
          }`}
        >
          <Flex
            hAlignContent="between"
            width="100%"
            vAlignContent="center"
            marginBottom="space20"
          >
            <Text
              {...authorStyles}
              as="p"
              aria-hidden
              style={{ textOverflow: "ellipsis" }}
              title={author}
            >
              {author}
            </Text>

            <ScreenReaderOnly as="p">
              {belongsToCurrentUser
                ? "You sent at"
                : `${
                    users?.find((u) => u.identity === message.author)
                      ?.friendlyName
                  } sent at`}
            </ScreenReaderOnly>

            <Text {...timeStampStyles} as="p">
              {`${doubleDigit(message.dateCreated.getHours())}:${doubleDigit(
                message.dateCreated.getMinutes()
              )}`}
            </Text>
          </Flex>

          <Text as="p" {...bodyStyles}>
            {message.body
              ? parseMessageBody(message.body, belongsToCurrentUser)
              : null}
          </Text>

          {message.type === "media" ? renderMedia() : null}
        </Box>
      </Box>
      {read && (
        <Flex hAlignContent="right" vAlignContent="center" marginTop="space20">
          <Text as="p" {...readStatusStyles}>
            Read
          </Text>

          <SuccessIcon
            decorative={true}
            size="sizeIcon10"
            color="colorTextWeak"
          />
        </Flex>
      )}
    </Box>
  );
};
