import axiosInstance from "axiosintance";
import { getErrorMessageAndCode } from "getErrorFromResponse";

export async function updatedEmailinfo(payload, thunkApi) {
  try {
    const body1 = {
      ticket: {
        email_ccs: payload?.values?.tags.map((email) => ({
          user_email: email,
        })),
      },
    };

    const updatebodydata = new FormData();
    updatebodydata.append("body1", JSON.stringify(body1));
    updatebodydata.append("ticketId", payload?.ticketId);
    updatebodydata.append("Operation", payload?.operation);
    updatebodydata.append("primary_email", payload?.primaryEmail);
    updatebodydata.append("del_email", payload?.del_Email);

    const updateResponse = await axiosInstance.post(
      `${process.env.REACT_APP_BACKENDURL_SUPPORT}/ticket_update`,
      updatebodydata
    );

    const emails = payload?.values?.tags;

    if (!emails) return updateResponse;

    // api for delete users
    const collaborator_ids = updateResponse.data.ticket.collaborator_ids;
    const { current_email_ccs } = updateResponse.data.audit.events.find(
      (event) => !!event.current_email_ccs
    );

    const newEmailIds = collaborator_ids
      .map((id, index) => ({ id, email: current_email_ccs[index] }))
      .filter((data) => emails.includes(data.email))
      .map((data) => data.id);

    const fd = new FormData();
    fd.append("ticketId", payload?.ticketId);
    fd.append(
      "body1",
      JSON.stringify({
        ticket: {
          email_cc_ids: newEmailIds,
          collaborator_ids: newEmailIds,
        },
      })
    );

    const response = await axiosInstance.post(
      `${process.env.REACT_APP_BACKENDURL_SUPPORT}/ticket_update`,
      fd
    );

    return response;
  } catch (err) {
    console.log(err);
    let error = getErrorMessageAndCode(err);
    throw error;
  }
}
