import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RecommendedService, {
  SingleRecommandedArtical,
  getPlans,
} from "./RecommendedService";

export const GetArticals = createAsyncThunk(
  "Articals/Recomended",
  async (userId, thunkAPI) => {
    try {
      return await RecommendedService.RecommandedArtical();
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const GetSingleArticals = createAsyncThunk(
  "Articals/single",
  async (payload, thunkAPI) => {
    try {
      return await SingleRecommandedArtical(payload);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const GetPlans = createAsyncThunk(
  "Articals/getPlans",
  async (payload, thunkAPI) => {
    try {
      return await getPlans();
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  getRecommendedData: {
    error: null,
    status: "idle",
    data: "",
    message: "",
  },
  getSingleRecommendedData: {
    error: null,
    status: "idle",
    data: "",
    message: "",
  },

  plans: {
    error: null,
    status: "idle",
    data: "",
    message: "",
  },
};

export const GetReommendedData = createSlice({
  name: "Articals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetPlans.fulfilled, (state, { payload }) => {
      state.plans.data = payload;
      state.error = null;
      state.status = "succeeded";
    });
    builder.addCase(GetPlans.pending, (state, payload) => {
      state.status = "loading";
      state.error = null;
      state.plans.data = [];
    });
    builder.addCase(GetPlans.rejected, (state, { payload }) => {
      state.status = "failed";
      state.plans.data = [];
      state.error = payload;
    });

    builder.addCase(GetArticals.fulfilled, (state, { payload }) => {
      state.getRecommendedData.data = payload;
      state.error = null;
      state.status = "succeeded";
    });
    builder.addCase(GetArticals.pending, (state, payload) => {
      state.status = "loading";
      state.error = null;
      state.getRecommendedData.data = [];
    });
    builder.addCase(GetArticals.rejected, (state, { payload }) => {
      state.status = "failed";
      state.getRecommendedData.data = [];
      state.error = payload;
    });

    builder.addCase(GetSingleArticals.fulfilled, (state, { payload }) => {
      state.getSingleRecommendedData.data = payload;
      state.error = null;
      state.status = "succeeded";
    });
    builder.addCase(GetSingleArticals.pending, (state, payload) => {
      state.status = "loading";
      state.error = null;
      state.getSingleRecommendedData.data = [];
    });
    builder.addCase(GetSingleArticals.rejected, (state, { payload }) => {
      state.status = "failed";
      state.getSingleRecommendedData.data = [];
      state.error = payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = GetReommendedData.actions;

export default GetReommendedData.reducer;
