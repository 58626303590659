import { LOCALSTORAGE_SESSION_ITEM_ID } from "./sessionDataHandler";
import store from "redux/store";

const HEADER_SEC_DECODER = "i-twilio-sec-decoders";
const HEADER_SEC_USER_AGENT = "i-twilio-user-agent";
const HEADER_SEC_USERSETTINGS = "i-twilio-sec-usersettings";
const HEADER_SEC_WEBCHAT = "i-twilio-sec-webchatinfo";

const DEFAULT_NAVIGATOR_LANG = "en_IN";
const DEFAULT_COOKIE_ENABLED = false;
const DEFAULT_LOGIN_TIMESTAMP = "9999999";
const DEFAULT_CODEC_INFO = {
  powerEfficient: false,
  smooth: false,
  supported: false,
  keySystemAccess: "twilio-keySystemAccess",
};

const getUserSpecificSettings = () => {
  return {
    language: navigator.language ?? DEFAULT_NAVIGATOR_LANG,
    cookieEnabled: navigator.cookieEnabled ?? DEFAULT_COOKIE_ENABLED,
    userTimezone: new Date().getTimezoneOffset(),
  };
};

const getWebchatInfo = () => {
  const sessionStorage =
    localStorage.getItem(LOCALSTORAGE_SESSION_ITEM_ID) || "";
  const reduxState = store.getState();
  const logger = window.Twilio.getLogger("getWebchatInfo");

  let parsedStorage = null;

  try {
    parsedStorage = JSON.parse(sessionStorage);
  } catch (e) {
    logger.error("Couldn't parse locally stored data");
  }

  return {
    loginTimestamp: parsedStorage?.loginTimestamp || DEFAULT_LOGIN_TIMESTAMP,
    deploymentKey: reduxState?.chatConfig?.deploymentKey || null,
  };
};

const getAudioVideoDecoders = async () => {
  const audioDecoder = navigator.mediaCapabilities?.decodingInfo({
    type: "file",
    audio: {
      contentType: "audio/mp3",
      channels: "2",
      bitrate: 132700,
      samplerate: 5200,
    },
  });
  const videoDecoder = navigator.mediaCapabilities?.decodingInfo({
    type: "file",
    audio: {
      contentType: "audio/mp4",
      channels: "2",
      bitrate: 132700,
      samplerate: 5200,
    },
  });

  return Promise.allSettled([audioDecoder, videoDecoder]).then((results) => {
    const allFulfilled = results.every(
      (result) => result.status === "fulfilled" && Boolean(result.value)
    );

    let audio = DEFAULT_CODEC_INFO;
    let video = DEFAULT_CODEC_INFO;

    if (allFulfilled) {
      const fulfilledResults = results;

      audio = fulfilledResults[0].value;
      video = fulfilledResults[1].value;
    }

    return {
      audio,
      video,
    };
  });
};

export const generateSecurityHeaders = async () => {
  const headers = {};
  return getAudioVideoDecoders().then((decoders) => {
    headers[HEADER_SEC_WEBCHAT] = JSON.stringify(getWebchatInfo());
    headers[HEADER_SEC_USERSETTINGS] = JSON.stringify(
      getUserSpecificSettings()
    );
    headers[HEADER_SEC_DECODER] = JSON.stringify(decoders);
    headers[HEADER_SEC_USER_AGENT] = navigator.userAgent;

    return headers;
  });
};
