import {
  CheckmarkOutline,
  CircleDash,
  Critical,
  Launch,
  Number_0,
  Number_1,
  Number_2,
} from "@carbon/icons-react";
import { Column, FlexGrid, Row } from "@carbon/react";
import { Link, useNavigate } from "react-router-dom";
import PlanWrapper from "./style";
import { GlobalContext } from "ContextApi/GlobalContext";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetPlans } from "redux/slices/RecommendArticals/RecommendSlice";

const Plan = () => {
  const context = useContext(GlobalContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { plans } = useSelector((state) => state.Articals);

  useEffect(() => {
    dispatch(GetPlans());
  }, []);

  return (
    <PlanWrapper className="dark:!bg-[#161616] dark:text-[#fff]">
      {/* <MobileHeaderNav/> */}
      <FlexGrid fullWidth className="">
        <div className="header ">
          <h4 className="dark:!text-[#fff]">support dashboard</h4>
          <h1>Support Plans</h1>
        </div>
        {/* </FlexGrid>
      <FlexGrid
        fullWidth
        style={{ backgroundColor: "#f4f4f4" }}
        className="dark:!bg-[#262626] "
      > */}
        <section className="plans-content ">
          <Row>
            <Column lg={8} sm={16} md={16}>
              <p className="dark:!text-[#fff]">
                Enhance your Cloud experience with customizable support plans:
                Basic, Advanced, or Premium, tailored to suit your business
                needs. Your selected support level defines the severity of cases
                and access to Support Center tools. Find support details for
                Pay-As-You-Go, Subscription accounts, and Enterprise Savings
                Plans in our support types table, with more information
                available on account types documentation.
              </p>
            </Column>
          </Row>
        </section>
        <section className="plans-description-table-wrapper  dark:!text-[#fff]">
          <table>
            <tr>
              <div></div>
              <th className="table-first-header dark:!bg-[#262626]">
                <div className="table-heading">
                  <div className="container-with-icon dark:!text-white">
                    <span className="" style={{ zIndex: 1 }}>
                      Basic
                    </span>
                    <Number_0 size={24} />
                  </div>
                </div>
              </th>
              <th className="dark:!bg-[#262626]">
                <div className="table-heading">
                  <div className="container-with-icon dark:!text-white">
                    <span>Advanced</span>
                    <Number_1 size={24} />
                  </div>
                </div>
              </th>
              <th className="dark:!bg-[#262626]">
                <div className="table-heading">
                  <div className="container-with-icon dark:!text-white">
                    <span>Premium</span>
                    <Number_2 size={24} />
                  </div>
                </div>
              </th>
            </tr>
            <tr style={{ backgroundColor: "white" }}>
              <td className="dark:!bg-[#161616]">
                <div className="table-heading dark:!text-white">
                  Description
                </div>
              </td>
              <td className="dark:!bg-[#161616] dark:!text-white">
                Basic business protection that is included with your IBM Cloud
                Pay-As-You-Go or Subscription account
              </td>
              <td className="dark:!bg-[#161616] table_data dark:!text-white">
                Prioritized case handling and support experience that is aligned
                with your business needs for your Pay-As-You-Go account,
                Subscription account, or Enterprise Savings Plan billing model
              </td>
              <td className="dark:!bg-[#161616] dark:!text-white">
                Client engagement that is aligned with your business outcomes to
                accelerate time-to-value for your Pay-As-You-Go account,
                Subscription account, or Enterprise Savings Plan billing model
              </td>
            </tr>
            <tr>
              <td className="dark:!bg-[#262626] dark:!text-white">
                <div className="table-heading dark:!text-white">
                  Availability
                </div>
              </td>
              <td className=" dark:!text-white">
                Basic business protection that is included with your IBM Cloud
                Pay-As-You-Go or Subscription account
              </td>
              <td className="dark:!text-white">
                24 x 7 access to the IBM Cloud technical support team through
                cases, phone, and chat
              </td>
              <td className="dark:!text-white">
                24 x 7 access to the IBM Cloud technical support team through
                cases, phone, and chat
              </td>
            </tr>
            <tr style={{ backgroundColor: "white" }}>
              <td className="dark:!bg-[#161616] dark:!text-white ">
                <div className="table-heading dark:!text-white">
                  Case Severity
                </div>
              </td>
              <td className="dark:!bg-[#161616] dark:!text-white">
                Not applicable
              </td>
              <td className="dark:!bg-[#161616] dark:!text-white">
                Case severity ranking available
              </td>
              <td className="dark:!bg-[#161616] dark:!text-white">
                Case severity ranking available
              </td>
            </tr>
            <tr>
              <td className="dark:!bg-[#262626] dark:!text-white">
                <div className="table-heading dark:!text-white">
                  Initial Response Time Objectives
                </div>
              </td>
              <td className=" dark:!text-white">Not applicable</td>
              <td className="dark:!text-white">
                Severity 1: Less than one hourSeverity 2: Less than two hours
                Severity 3: Less than four hoursSeverity 4: Less than eight
                hours
              </td>
              <td className=" dark:!text-white">
                Severity 1: Less than 15 minutesSeverity 2: Less than one hour
                Severity 3: Less than two hoursSeverity 4: Less than four hours
              </td>
            </tr>
            <tr style={{ backgroundColor: "white" }}>
              <td className="dark:!bg-[#161616] dark:!text-white">
                <div className="table-heading dark:!text-white">
                  Additional Support
                </div>
              </td>
              <td className="dark:!bg-[#161616] dark:!text-white">
                Not applicable
              </td>
              <td className="dark:!bg-[#161616] dark:!text-white">
                Not applicable
              </td>
              <td className="dark:!bg-[#161616] dark:!text-white">
                Technical Account Manager assignedQuarterly business
                reviewsAccess to experts
              </td>
            </tr>
            <tr>
              <td className="dark:!bg-[#262626] dark:!text-white">
                <div className="table-heading dark:!text-white">Pricing</div>
              </td>
              <td className=" dark:!text-white">
                Included with cloud entitlement
              </td>
              <td className="dark:!text-white">
                Pay-As-You-Go and Subscription: Starting at USD 200 per month or
                10% of consumption if it exceeds the monthly starting price
                Enterprise Savings Plan: 10% of consumption
              </td>
              <td className=" dark:!text-white ">
                Pay-As-You-Go and Subscription: Starting at USD 200 per month or
                10% of consumption if it exceeds the monthly starting price
                Enterprise Savings Plan: 10% of consumption
              </td>
            </tr>
            <tr style={{ backgroundColor: "white" }}>
              <td className="dark:!bg-[#161616] dark:!text-white">
                <div className="table-heading dark:!text-white dark:!bg-[#161616]">
                  Resource Allocation
                </div>
              </td>
              <td className="dark:!bg-[#161616] dark:!text-white ">
                <div className="master_div">
                  {plans?.data?.data?.data?.basic &&
                  plans?.data?.data?.data?.basic.length > 0
                    ? plans?.data?.data?.data?.basic?.map((item, index) => {
                        return (
                          <>
                            <div className="launch_icon_div dark:!text-white ">
                              <div>
                                <h5 className="dark:!text-[#77A6FF]">
                                  {item?.parent_item}
                                </h5>
                                <h5 className="dark:!text-[#77A6FF]">
                                  ID: {item?.id}
                                </h5>
                              </div>

                              <Link
                                to={item?.redirect_url}
                                style={{ cursor: "pointer" }}
                                target="_blank"
                              >
                                <Launch
                                  size={16}
                                  color={
                                    context.globalTheme == "white"
                                      ? "#2570fe"
                                      : "#77A6FF"
                                  }
                                />
                              </Link>
                            </div>
                          </>
                        );
                      })
                    : "Not applicable"}
                </div>
              </td>
              <td className="dark:!bg-[#161616] dark:!text-white">
                {
                  <div className="master_div">
                    {plans?.data?.data?.data?.advance &&
                    plans?.data?.data?.data?.advance?.length > 0
                      ? plans?.data?.data?.data?.advance?.map((item, index) => {
                          return (
                            <>
                              <div className="launch_icon_div dark:!text-white ">
                                <div>
                                  <h5 className="dark:!text-[#77A6FF]">
                                    {item?.parent_item}
                                  </h5>
                                  <h5 className="dark:!text-[#77A6FF]">
                                    ID: {item?.id}
                                  </h5>
                                </div>
                                <Link
                                  to={item?.redirect_url}
                                  style={{ cursor: "pointer" }}
                                  target="_blank"
                                >
                                  <Launch
                                    size={16}
                                    onClick={() => navigate(item?.redirect_url)}
                                    color={
                                      context.globalTheme == "white"
                                        ? "#2570fe"
                                        : "#77A6FF"
                                    }
                                  />
                                </Link>
                              </div>
                            </>
                          );
                        })
                      : "Not applicable"}
                  </div>
                }
              </td>
              <td className="dark:!bg-[#161616] dark:!text-white">
                {
                  <div className="master_div">
                    {plans?.data?.data?.data?.premium &&
                    plans?.data?.data?.data?.premium?.length > 0
                      ? plans?.data?.data?.data?.premium?.map((item, index) => {
                          return (
                            <>
                              <div className="launch_icon_div dark:!text-white ">
                                <div>
                                  <h5 className="dark:!text-[#77A6FF]">
                                    {item?.parent_item}
                                  </h5>
                                  <h5 className="dark:!text-[#77A6FF]">
                                    ID: {item?.id}
                                  </h5>
                                </div>
                                <Link
                                  to={item?.redirect_url}
                                  style={{ cursor: "pointer" }}
                                  target="_blank"
                                >
                                  <Launch
                                    size={16}
                                    color={
                                      context.globalTheme == "white"
                                        ? "#2570fe"
                                        : "#77A6FF"
                                    }
                                  />
                                </Link>
                              </div>
                            </>
                          );
                        })
                      : "Not applicable"}
                  </div>
                }
              </td>
            </tr>
          </table>
        </section>
        <section className="case-response-time-table">
          {/* <Row> */}
          {/* <Column xs={16} sm={16} lg={16}> */}
          <div className="critical_mainheading dark:!bg-[#161616]">
            {/* <div className="critical_icon">
                <Critical size={24} />
              </div> */}
            <h6>Case Severity & Initial Response Times</h6>

            <p className="dark:!text-[#fff]">
              To ensure that we address each case with the appropriate level of
              urgency, we classify cases into different severity levels. These
              classifications help us prioritize cases based on their impact on
              your business operations and the severity of the issue
              encountered.
            </p>
          </div>

          <table className="table dark:!text-white">
            <tr>
              <th>Severity</th>
              <th>Business Impact</th>
              <th>Details</th>
              <th>Basic</th>
              <th>Advanced</th>
              <th>Premium</th>
            </tr>
            <tr>
              <td className="dark:!text-white ]">4</td>
              <td className="dark:!text-white">Minimal</td>
              <td className="dark:!text-white">
                An inquiry or non-technical request.
              </td>
              <td className="dark:!text-white">
                No SLO, can't open severity 4 cases
              </td>
              <td className="dark:!text-white">Within 8 hours</td>
              <td className="dark:!text-white">Within 4 hours</td>
            </tr>
            <tr style={{ backgroundColor: "white" }}>
              <td className="dark:!text-white dark:!bg-[#161616]">3</td>
              <td className="dark:!text-white dark:!bg-[#161616]">Some</td>
              <td className="dark:!text-white dark:!bg-[#161616]">
                The product, service, or functions are usable, and the issue
                doesn't <br />
                represent a significant impact on operations.
              </td>
              <td className="dark:!text-white dark:!bg-[#161616]">
                No SLO, can't open severity 3 cases
              </td>
              <td className="dark:!text-white dark:!bg-[#161616]">
                Within 4 hours
              </td>
              <td className="dark:!text-white dark:!bg-[#161616]">
                Within 2 hours
              </td>
            </tr>
            <tr>
              <td className="dark:!text-white">2</td>
              <td className="dark:!text-white">Significant</td>
              <td className="dark:!text-white">
                A product, service, business feature, or function of the product
                or service <br />
                is severely restricted in its use, or you are in jeopardy of
                missing <br />
                business deadlines.
              </td>
              <td className="dark:!text-white">
                No SLO, can't open severity 2 cases
              </td>
              <td className="dark:!text-white">Within 2 hours</td>
              <td className="dark:!text-white">Within 1 hour</td>
            </tr>
            <tr style={{ backgroundColor: "white" }}>
              <td className="dark:!text-white dark:!bg-[#161616]">1</td>
              <td className="dark:!text-white dark:!bg-[#161616]">Critical</td>
              <td className="dark:!text-white dark:!bg-[#161616]">
                System or Service DownBusiness-critical functions are inoperable
                or a <br />
                critical interface has failed. This usually applies to a
                production
                <br /> environment and indicates an inability to access products
                or services
                <br /> that results in a critical impact on operations. This
                condition requires an
                <br /> immediate solution.
              </td>
              <td className="dark:!text-white dark:!bg-[#161616]">
                No SLO, can't open severity 1 cases
              </td>
              <td className="dark:!text-white dark:!bg-[#161616]">
                Within 1 hour
              </td>
              <td className="dark:!text-white dark:!bg-[#161616]">
                Less than 15 minutes
              </td>
            </tr>
          </table>
          {/* </Column> */}
          {/* </Row> */}
        </section>
        <section className="plans-terms-and-conditions">
          <Row>
            <Column xlg={8}>
              <div className="terms-and-condition-wrapper">
                <div>
                  <h5>Our Service Agreement</h5>
                  <p>
                    Click here to access{" "}
                    <a
                      href="https://uvation.com/sla-and-terms"
                      target="_blank"
                      style={{
                        color:
                          context.globalTheme == "white"
                            ? "#2570FE"
                            : "#77A6FF",
                      }}
                    >
                      Uvation's Service Level Agreement (SLA).
                    </a>
                    Our SLA serves as a detailed guideline outlining the
                    standards, commitments, and expectations for the services we
                    provide.
                  </p>
                </div>
                <p>
                  We take pride in delivering exceptional services that go
                  beyond your expectations. Our Service Level Agreement is
                  designed to ensure transparency, clarity, and accountability
                  between Uvation and our valued clients.
                </p>
              </div>
            </Column>
          </Row>
        </section>
      </FlexGrid>
    </PlanWrapper>
  );
};

export default Plan;
