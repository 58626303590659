import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { Text } from "@twilio-paste/core/text";
import { useDispatch } from "react-redux";
import {
  changeEngagementPhase,
  updatePreEngagementData,
} from "redux/reducers/chat.reducer";
import { EngagementPhase } from "redux/reducers/definitions";
import { sessionDataHandler } from "utils/sessionDataHandler";
import {
  containerStyles,
  textStyles,
  titleStyles,
} from "./styles/ConversationEnded.styles";

export const ConversationEnded = () => {
  const dispatch = useDispatch();
  const handleStartNewChat = () => {
    sessionDataHandler.clear();
    dispatch(updatePreEngagementData({ email: "", name: "", query: "" }));
    dispatch(
      changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm })
    );
  };

  return (
    <Box {...containerStyles}>
      <Text as="h3" {...titleStyles}>
        Thanks for chatting with us!
      </Text>
      <Text as="p" {...textStyles}>
        If you have any more questions, feel free to reach out again.
      </Text>
      <Button
        variant="primary"
        data-test="start-new-chat-button"
        onClick={handleStartNewChat}
      >
        Start new chat
      </Button>
    </Box>
  );
};
