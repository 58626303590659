import { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "./Header";
import { MessageList } from "./MessageList";
import { MessageInput } from "./MessageInput";
import { ConversationEnded } from "./ConversationEnded";
import { NotificationBar } from "./NotificationBar";
import { removeNotification } from "redux/reducers/notification.reducer";
import { notifications } from "utils/notifications";
import { AttachFileDropArea } from "./AttachFileDropArea";

export const MessagingCanvasPhase = () => {
  const dispatch = useDispatch();
  const conversationState = useSelector(
    (state) => state.chat.conversationState
  );

  useEffect(() => {
    dispatch(
      removeNotification(notifications.failedToInitSessionNotification("ds").id)
    );
  }, [dispatch]);

  const Wrapper =
    conversationState === "active" ? AttachFileDropArea : Fragment;

  return (
    <Wrapper>
      <Header />
      <NotificationBar />
      <MessageList />
      {conversationState === "active" ? (
        <MessageInput />
      ) : (
        <ConversationEnded />
      )}
    </Wrapper>
  );
};
