import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SupportService from "./SupportService";

export const getSupportTickets = createAsyncThunk(
  "support/getSupportTickets",
  async (payload, thunkAPI) => {
    try {
      return await SupportService.getSupportTickets(payload);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  tickets: {
    error: null,
    status: "idle",
    data: [],
    count: 0,
    message: "",
  },
};
export const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSupportTickets.fulfilled, (state, { payload }) => {
      state.tickets.data = payload.tickets;
      state.tickets.count = payload.totalRows;
      state.tickets.error = null;
      state.tickets.status = "succeeded";
    });
    builder.addCase(getSupportTickets.pending, (state, payload) => {
      state.tickets.status = "loading";
      state.tickets.data = initialState.tickets.data;
      state.tickets.count = 0;
      state.tickets.error = null;
    });
    builder.addCase(getSupportTickets.rejected, (state, { payload }) => {
      state.tickets.status = "failed";
      state.tickets.data = initialState.tickets.data;
      state.tickets.error = payload;
      state.tickets.count = 0;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = supportSlice.actions;

export default supportSlice.reducer;
