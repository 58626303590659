export const formStyles = {
  padding: "space40",
  paddingTop: "space80",
  overflow: "auto",
};

export const titleStyles = {
  fontSize: "fontSize70",
  marginBottom: "space60",
};

export const introStyles = {
  marginBottom: "space70",
};

export const fieldStyles = {
  marginBottom: "space70",
};
