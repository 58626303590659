
export const notificationBarContainerStyles = {
    position: "relative"
};

export const notificationBarStyles = {
    position: "absolute",
    width: "100%",
    overflow: "hidden",
    zIndex: "zIndex10"
};
