import React from "react";
import { Button, Loading } from "@carbon/react";
import ButtonWrapper from "./style";
import Icon from "components/icon/icon";
import * as AllIcons from "@carbon/icons-react";

const CustomButton = ({
  fullWidth,
  icon,
  text,
  type,
  kind,
  iconSize,
  iconFirst,
  as,
  disabled,
  className,
  href,
  center,
  handleClick,
  size,
  isLoading = false,
  style = {},
  ...props
}) => {
  return (
    <ButtonWrapper
      $fullWidth={fullWidth}
      $center={center}
      className={className || null}
    >
      <Button
        size={size}
        onClick={handleClick}
        renderIcon={icon}
        kind={kind || "primary"}
        type={type || "button"}
        as={as || null}
        disabled={isLoading || disabled || false}
        href={href || null}
        style={{
          ...style,
          backgroundColor: style?.backgroundColor
            ? style.backgroundColor
            : "#2570FE",
        }}
        {...props}
      >
        {!isLoading && icon && iconFirst && (
          <>
            <Icon iconName={icon} size={iconSize || 16} />
            &nbsp;
          </>
        )}

        {isLoading && <Loading small />}

        {text}
      </Button>
    </ButtonWrapper>
  );
};

export default CustomButton;
