import axiosInstance from "axiosintance";

const fetch_all_active_services = async (azureId) => {
  const response = await axiosInstance.post(
    `${process.env.REACT_APP_USPURL}/subscription/api/list_subscription`,
    {
      customer_id: azureId,
    }
  );
  return response?.data?.data?.list || [];
};

export default {
  fetch_all_active_services,
};
