export const WILDCARD = "*";
export const ROOT = "/";
export const LOGIN = "/login";
export const REQUEST = "/request";
export const STATUS = "/status";
export const PLAN = "/plan";
export const TRAINING = "/training";
export const TRAINING_INSIDER = "/singletraining/:slug";
export const DETAILS = "/ticketdetails/:ticketId";
export const NOTIFICATION = "/notification";
export const CREATTICKET = "/TicketCreate";
export const TRAININFORADMINS = "/training/Training-For-admins";
export const FAQGUIDE = "/training/Faq-Guide";
export const TRAINING_KNOWLEDGE_BASE = "/training/knowledge-base";
