import styled from "styled-components";
import { blue60 } from "@carbon/colors";
const NotificationWrapper = styled.div`
  .account_panel {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    max-height: 289px;
    width: 312px;
    border: unset !important;
    background-color: #393939;
    color: #fff;
    /* position: absolute;
    right: 0; */
    .header {
      padding: 7px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h6 {
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.1599999964237213px;
        text-align: left;
        color: #fff;
      }
      .switch {
        display: flex;
        align-items: center;
        gap: 10px;
        .cds--toggle__switch--checked {
          background-color: ${blue60};
        }
        span {
          font-family: IBM Plex Sans;
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0.10956522077322006px;
          text-align: left;
        }
      }
    }
    .box {
      max-height: 250px;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      ul {
        li {
          padding: 10px 10px 10px 28px;
          position: relative;
          display: flex;
          gap: 8px;
          flex-direction: column;
          &::before {
            content: "";
            left: 12px;
            top: 15px;
            border-radius: 50%;
            position: absolute;
            width: 7px;
            height: 7px;
            background-color: #7ca9fe;
            display: block;
          }
          &:hover {
            background-color: rgba(141, 141, 141, 0.32);
          }
          .message {
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.1599999964237213px;
            text-align: left;
          }
          .date {
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            letter-spacing: 0.32px;
          }
        }
      }
    }
  }
  .cds--btn-set .cds--btn {
    inline-size: 100%;
    max-inline-size: 10.5rem;
  }
  .viewAll_btn {
    position: absolute;
    bottom: 0%;
    height: 52px;
    box-shadow: unset !important;
    .cds--btn--secondary {
      background-color: #474747;
      box-shadow: unset !important;
      border: unset !important;
    }
    .cds--btn--primary {
      box-shadow: unset !important;
      border: unset !important;
    }
  }
`;

export default NotificationWrapper;
