const { default: styled } = require("styled-components");

const LayoutWrapper = styled.main`
  .cds--content {
    padding: 0;
    margin-top: 3rem;
    min-height: calc(100vh - 3rem);
  }
`;
export default LayoutWrapper;
