import { createSlice } from "@reduxjs/toolkit";
// import { ACTION_LOAD_CONFIG } from "./actions/actionTypes";

const initialState = {
  deploymentKey: "",
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    loadConfig: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { loadConfig } = configSlice.actions;

export default configSlice.reducer;
