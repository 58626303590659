import { Box } from "@twilio-paste/core/box";
import { useSelector } from "react-redux";
import { NotificationBarItem } from "./NotificationBarItem";
import {
  notificationBarContainerStyles,
  notificationBarStyles,
} from "./styles/NotificationBar.styles";

export const NotificationBar = () => {
  const notifications = useSelector((store) => store.chatNotifications);
  return (
    <Box {...notificationBarContainerStyles}>
      <Box {...notificationBarStyles}>
        {notifications.filter(Boolean).map((notification) => (
          <NotificationBarItem key={notification?.id} {...notification} />
        ))}
      </Box>
    </Box>
  );
};
