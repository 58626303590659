import log from "loglevel";

const VALID_LOGLEVELS = ["info", "warn", "error"];

class WebChatLogger {
    constructor(className) {
        this.className = className;
        this.logger = log.getLogger(this.className);
    }

    info(message) {
        this.logger.info(`[${this.className}]: ${message}`);
    }

    warn(message) {
        this.logger.warn(`[${this.className}]: ${message}`);
    }

    error(message) {
        this.logger.error(`[${this.className}]: ${message}`);
    }
}

const initialize = (level = "info") => {
    if (!VALID_LOGLEVELS.includes(level)) {
        console.error(
            `Invalid Log Level -> ${level}. Select level higher than INFO or more. Valid levels are INFO, WARN, and ERROR.`
        );
        return;
    }

    log.setLevel(level);
    log.info("Logger has been initialized.");
};

const logDump = new Map();

const getWebChatLogger = (className) => {
    if (!logDump.has(className)) {
        logDump.set(className, new WebChatLogger(className));
    }

    return logDump.get(className);
};

export { initialize as initLogger, getWebChatLogger as getLogger };
