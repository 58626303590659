import {
  Close,
  Help,
  Notification as NotificationIcon,
  Switcher,
  User,
  Menu,
  Headset,
} from "@carbon/icons-react";
import {
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderNavigation,
  Search,
  Theme,
  Header as UvationHeader,
} from "@carbon/react";
import useWindowSize from "hooks/useWindowSize";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  handleAccountTab,
  handleMobileSideNav,
  handleNotificationTab,
  handleSearch,
  handleSideNav,
  handleSupportTab,
  handleSwitcher,
  headerState,
  reset,
} from "redux/slices/headerSlice";
import { handleGlobalNav } from "./../../redux/slices/headerSlice";
import HeaderWrapper from "./headerStyles";
import Account from "./tabs/account";
import Notification from "./tabs/notification";
import Support from "./tabs/support";
import { Contextprovider } from "App";

const SearchableLinks = [
  { name: "Support Dashboard", link: "/" },
  { name: "Home", link: "/" },
  { name: "Support Request", link: "/request" },
  { name: "Support Status", link: "/status" },
  { name: "Support Plan", link: "/plan" },
  { name: "Training For Admins", link: "/training/Training-For-admins" },
  { name: "Knowledge base", link: "/training/knowledge-base" },
  { name: "FAQ & Guides", link: "/training/Faq-Guide" },
];

const Header = () => {
  const { notificationTab, accountTab, supportTab, search } =
    useSelector(headerState);
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  function scrollTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  const { useralldata } = useContext(Contextprovider);
  const { switcher, sideNav, mobileNav } = useSelector(headerState);
  const { unseenNotifications, Notificationstop } = useSelector(
    (state) => state.notifications
  );
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    dispatch(reset());
  }, [location.pathname]);

  const headerLinks = useMemo(
    () => [
      { link: "https://portal.uvation.com/", title: "USP" },
      { link: "https://marketplace.uvation.com/account", title: "Marketplace" },
      { link: "https://rewards.uvation.com/", title: "Rewards" },
      { link: "https://identity.uvation.com/", title: "Identity" },
    ],
    []
  );

  const SearchListItems = useMemo(() => {
    if (!search || !search?.length) return [];

    return SearchableLinks.filter((l) =>
      l.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [search]);

  return (
    <>
      {width >= 1025 ? (
        <Theme theme={"g100"}>
          <HeaderWrapper>
            <UvationHeader
              aria-label="Uvation"
              className="dark:!border-[#161616]"
              style={{ backgroundColor: "#000" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "14px",
                }}
              >
                <Menu
                  size={20}
                  color="white"
                  className="icon"
                  onClick={() => {
                    dispatch(handleSideNav());
                  }}
                />
              </div>

              <div
                style={{
                  minWidth: "160px",
                  height: "3rem",
                  padding: "14px 32px 14px 8px",
                }}
              >
                <Link to="/" className="header_button">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {" "}
                    <img
                      className="invert-0"
                      width={20}
                      height={20}
                      src={
                        "https://cdn.uvation.com/identity/image/uvationlogo.png"
                      }
                      alt="logo"
                    />
                    <p
                      className="white"
                      style={{
                        fontSize: "14px",
                        fontFamily: "IBM Plex Sans",
                        letterSpacing: "0.16px",
                        fontWeight: "600",
                      }}
                    >
                      SUPPORT
                    </p>
                  </div>
                </Link>
              </div>

              <HeaderNavigation className="navbaridentity">
                {headerLinks.map((headerLink) => (
                  <HeaderMenuItem
                    href={headerLink.link}
                    target="_blank"
                    style={{ backgroundColor: "#000" }}
                  >
                    {headerLink.title}
                  </HeaderMenuItem>
                ))}
              </HeaderNavigation>

              <HeaderGlobalBar>
                <div
                  className="search_item"
                  style={{
                    width: "100%",
                    maxWidth: "360px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    marginRight: "10px",
                  }}
                >
                  <Search
                    placeholder="Search input text"
                    value={search}
                    style={{ border: "none" }}
                    size="lg"
                    className="search"
                    closeButtonLabelText="Clear search input"
                    onChange={(e) => {
                      dispatch(handleSearch(e.target.value));
                    }}
                    onKeyDown={() => {}}
                  />

                  {search && search?.length && (
                    <div className="serach_list">
                      <ul id="myUL">
                        {SearchListItems.map((link) => (
                          <li
                            className="dark:!bg-[#262626] dark:!text-white dark:!border-[#474747]"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              dispatch(handleSearch(""));

                              navigate(link?.link);
                            }}
                          >
                            <div className="product-detail-box">
                              <h5>{link?.name}</h5>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <HeaderGlobalAction
                  isActive={supportTab}
                  aria-label="Support"
                  onClick={() => {
                    dispatch(handleSupportTab(!supportTab));
                  }}
                >
                  <Headset size={20} className="!fill-white" />
                </HeaderGlobalAction>

                <div className="relative header_noti">
                  <HeaderGlobalAction
                    aria-label="Notification"
                    isActive={notificationTab}
                    onClick={() => {
                      dispatch(handleNotificationTab());
                    }}
                  >
                    <NotificationIcon
                      size={20}
                      className={
                        Notificationstop?.data?.status ||
                        unseenNotifications?.data?.length >= 1
                          ? "!fill-white iconmotion"
                          : "!fill-white"
                      }
                    />

                    {Notificationstop?.data?.status ||
                      (unseenNotifications?.data?.length >= 1 && (
                        <span className={"notification_count"}>
                          {unseenNotifications?.data.length > 99
                            ? "99+"
                            : unseenNotifications?.data.length}
                        </span>
                      ))}
                  </HeaderGlobalAction>
                </div>

                <div className="relative">
                  <HeaderGlobalAction
                    className="user"
                    aria-label="User Account"
                    isActive={accountTab}
                    onClick={() => {
                      dispatch(handleAccountTab());
                    }}
                  >
                    <User size={20} className="!fill-white" />

                    <span className="text-white">
                      {useralldata?.userinfo?.fullname}
                    </span>
                  </HeaderGlobalAction>
                </div>
              </HeaderGlobalBar>
            </UvationHeader>
          </HeaderWrapper>
        </Theme>
      ) : (
        <HeaderWrapper>
          <UvationHeader
            aria-label="Uvation"
            style={{ backgroundColor: "#000" }}
            className="mobile_class"
          >
            {!mobileNav && !switcher ? (
              <HeaderMenuButton
                onClick={() => {
                  dispatch(handleMobileSideNav());
                }}
                renderMenuIcon={<Menu size={20} color="white" />}
              />
            ) : (
              <HeaderMenuButton
                onClick={() => {
                  dispatch(handleSwitcher());
                }}
                renderMenuIcon={
                  switcher ? (
                    <Close size={20} color="white" />
                  ) : (
                    <Menu size={20} color="white" />
                  )
                }
              />
            )}

            <div className="logo">
              <Link to="/">
                <img
                  width={24}
                  height={24}
                  src={"https://cdn.uvation.com/identity/image/uvationlogo.png"}
                  alt="logo"
                />
                <p className="text-[#ffffff]">SUPPORT</p>
              </Link>
            </div>

            <HeaderGlobalBar>
              <HeaderGlobalAction
                className="mobile_class"
                isActive={supportTab}
                aria-label="Support"
                onClick={() => {
                  dispatch(handleSupportTab(!supportTab));
                }}
              >
                <Headset size={20} color="white" />
              </HeaderGlobalAction>
              <div className="relative">
                <HeaderGlobalAction
                  aria-label="Notification"
                  isActive={notificationTab}
                  onClick={() => {
                    dispatch(handleNotificationTab());
                  }}
                >
                  <>
                    <NotificationIcon
                      size={20}
                      className={
                        Notificationstop?.data?.status ||
                        unseenNotifications?.data?.length >= 1
                          ? "!fill-white iconmotion"
                          : "!fill-white"
                      }
                    />
                    {Notificationstop?.data?.status ||
                      (unseenNotifications?.data?.length >= 1 && (
                        <span className={"notification_count"}>
                          {unseenNotifications?.data.length > 99
                            ? "99+"
                            : unseenNotifications?.data.length}
                        </span>
                      ))}
                  </>
                </HeaderGlobalAction>
              </div>

              <div className="relative">
                <HeaderGlobalAction
                  className="user"
                  aria-label="User Account"
                  isActive={accountTab}
                  onClick={() => {
                    dispatch(handleAccountTab());
                  }}
                >
                  <User size={20} color="white" />
                </HeaderGlobalAction>
              </div>
            </HeaderGlobalBar>
          </UvationHeader>
        </HeaderWrapper>
      )}
      <Account open={accountTab} />
      <Notification open={notificationTab} />
      <Support open={supportTab} />
    </>
  );
};

export default Header;
