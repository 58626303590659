import React, { useContext, useState, useEffect } from "react";
import {
  ChevronRight,
  SettingsAdjust,
  Launch,
  Add,
  Grid,
  List,
  Chat,
  FilterReset,
} from "@carbon/icons-react";
import { Link } from "react-router-dom";
import {
  Button,
  Tabs,
  Tab,
  ButtonSet,
  DatePickerInput,
  DatePicker,
  SelectItem,
  Select,
  Form,
  Search,
} from "@carbon/react";
import GridModeTab from "./SupportTab/GridModeTab";
import ListViewTab from "./SupportTab/ListViewTab";
import { GlobalContext } from "../../ContextApi/GlobalContext";
import { Contextprovider } from "../../App";

export const SupportServiceCard = ({
  id,
  servicename,
  serviceabout,
  created,
  onClick,
}) => (
  <Link to={`/ticketdetail/` + id} onClick={onClick}>
    <div className="inside-box">
      <div className="support-inside-box-title">
        <h4>
          #{id} | {servicename}
        </h4>
        <Launch fill="#fff" />
      </div>
      <h3>{serviceabout}</h3>
      <div className="bx--row ">
        <div className="circle-SV">
          <h5>SV</h5>
        </div>
        <div className="bx--col" style={{ paddingRight: "4rem" }}>
          <p>{created}</p>
        </div>
      </div>
    </div>
  </Link>
);

const SupportCenter = () => {
  const { toggle, settoggle } = useContext(GlobalContext);
  const { useralldata, dispatch } = useContext(Contextprovider);

  function openticket() {
    settoggle({
      openShow: false,
      closedShow: true,
      pendingShow: true,
      newShow: true,
      applyFilter: true,
    });
  }
  function closedticket() {
    settoggle({
      closedShow: false,
      openShow: true,
      pendingShow: true,
      newShow: true,
    });
  }
  function pendingticket() {
    settoggle({
      pendingShow: false,
      openShow: true,
      closedShow: true,
      newShow: true,
    });
  }
  function newticket() {
    settoggle({
      newShow: false,
      openShow: true,
      closedShow: true,
      pendingShow: true,
    });
  }
  function newticket2() {
    settoggle({
      openShow: window.innerWidth < 900 ? false : null,
      closedShow: window.innerWidth < 900 ? true : null,
      pendingShow: window.innerWidth < 900 ? true : null,
      newShow: window.innerWidth < 900 ? true : null,
    });
  }

  const Show = () => {
    settoggle({ ...toggle, FillterToggle: !toggle.FillterToggle });
  };

  const ResetFilter = () => {
    settoggle({ ...toggle, FillterToggle: !toggle.FillterToggle });

    dispatch({
      type: "FILLTER_DATA",
      payload: {
        applyFilter: false,
        startDate: "",
        endDate: "",
      },
    });
  };
  const Setfilter = () => {
    dispatch({
      type: "FILLTER_DATA",
      payload: {
        applyFilter: true,
      },
    });
    settoggle({ ...toggle, FillterToggle: !toggle.FillterToggle });
  };

  const [loading, setloading] = useState(false);

  useEffect(() => {
    newticket2();
  }, []);

  return (
    <>
      {window.scrollTo(0, 0)}
      <div className="bx--grid--full-width TicketInfo">
        <div className="bx--grid bx--no-gutter allticket-page">
          <div className="">
            <div className="bx--row bx--row-padding">
              <div className="bx--col allticket-info">
                <h3>Welcome to</h3>
                <h1>
                  All Tickets
                  <span className="alltickets_svg">
                    <ChevronRight />
                  </span>
                  <Link to="/">Home</Link>
                </h1>
                {/* {toggle.openShow ||
toggle.closedShow ||
toggle.pendingShow ||
toggle.newShow ||
Fillter.applyFilter == true ? (
<div className='fillterbtn'>
<Button
kind='ghost'
size='sm'
onClick={() => {
settoggle({
...toggle,
openShow: '',
closedShow: '',
pendingShow: '',
newShow: '',
});

setFillter({
...Fillter,
applyFilter: false,
startDate: '',
endDate: '',
});
}}
>
Clear Fillter
</Button>
</div>
) : (
''
)} */}
              </div>
            </div>

            <div className="support-filter-section">
              <div className="bx--row">
                <div className="filter-add">
                  <ButtonSet>
                    <Button
                      hasIconOnly
                      kind="secondary"
                      tooltipPosition="bottom"
                      renderIcon={SettingsAdjust}
                      iconDescription="Filter"
                      onClick={Show}
                    />
                    <Button
                      onClick={() =>
                        settoggle({
                          ...toggle,
                          Support: !toggle.Support,
                          tabselect: 0,
                        })
                      }
                      hasIconOnly
                      kind="primary"
                      tooltipPosition="bottom"
                      renderIcon={Add}
                      iconDescription="Add new"
                    />
                  </ButtonSet>
                </div>
                <div className="filterBlock summer">
                  <div
                    className="bx--col section-column"
                    onClick={openticket}
                    style={{
                      border:
                        toggle.openShow === false ? "2px solid #0f62fe " : "",
                      pointerEvents: toggle.ListActive ? "none" : "",
                    }}
                  >
                    <p>OPEN</p>
                    <h2>
                      {Number(useralldata?.GlobalState.newCount) < 10
                        ? `0${useralldata?.GlobalState.newCount}`
                        : useralldata?.GlobalState.newCount}
                    </h2>
                  </div>
                  <div
                    className="bx--col section-column"
                    onClick={newticket}
                    style={{
                      border:
                        toggle.newShow === false ? "2px solid #0f62fe" : "",
                      pointerEvents: toggle.ListActive ? "none" : "",
                    }}
                  >
                    <p>ASSIGNED</p>
                    <h2>
                      {Number(useralldata?.GlobalState.openCount) < 10
                        ? `0${useralldata?.GlobalState.openCount}`
                        : useralldata?.GlobalState.openCount}
                    </h2>
                  </div>
                  <div
                    className="bx--col section-column"
                    onClick={pendingticket}
                    style={{
                      border:
                        toggle.pendingShow === false ? "2px solid #0f62fe" : "",
                      pointerEvents: toggle.ListActive ? "none" : "",
                    }}
                  >
                    <p>IN PROGRESS</p>
                    <h2>
                      {Number(useralldata?.GlobalState.pendingCount) < 10
                        ? `0${useralldata?.GlobalState.pendingCount}`
                        : useralldata?.GlobalState.pendingCount}
                    </h2>
                  </div>

                  <div
                    className="bx--col section-column"
                    onClick={closedticket}
                    style={{
                      border:
                        toggle.closedShow === false ? "2px solid #0f62fe" : "",
                      pointerEvents: toggle.ListActive ? "none" : "",
                    }}
                  >
                    <p>CLOSED</p>
                    <h2>
                      {Number(useralldata?.GlobalState.closedCount) < 10
                        ? `0${useralldata?.GlobalState.closedCount}`
                        : useralldata?.GlobalState.closedCount}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="mobFilterBlock">
                <div className="filters">
                  <div
                    className="bx--col-sm-1 section-column"
                    onClick={openticket}
                    style={{
                      borderBottom:
                        toggle.openShow === false ? "1.5px solid #0f62fe" : "",
                      pointerEvents: toggle.ListActive ? "none" : "",
                    }}
                  >
                    <p>OPEN</p>
                    <h2>
                      {Number(useralldata?.GlobalState.newCount) < 10
                        ? `0${useralldata?.GlobalState.newCount}`
                        : useralldata?.GlobalState.newCount}
                    </h2>
                  </div>
                  <div
                    className="bx--col-sm-1 section-column"
                    onClick={pendingticket}
                    style={{
                      borderBottom:
                        toggle.pendingShow === false
                          ? "1.5px solid #0f62fe "
                          : "",
                      pointerEvents: toggle.ListActive ? "none" : "",
                    }}
                  >
                    <p>IN PROGRESS</p>
                    <h2>
                      {Number(useralldata?.GlobalState.pendingCount) < 10
                        ? `0${useralldata?.GlobalState.pendingCount}`
                        : useralldata?.GlobalState.pendingCount}
                    </h2>
                  </div>
                  <div
                    className="bx--col-sm-1 section-column"
                    onClick={newticket}
                    style={{
                      borderBottom:
                        toggle.newShow === false ? "1.5px solid #0f62fe " : "",
                      pointerEvents: toggle.ListActive ? "none" : "",
                    }}
                  >
                    <p>ASSIGNED</p>
                    <h2>
                      {Number(useralldata?.GlobalState.newCount) < 10
                        ? `0${useralldata?.GlobalState.newCount}`
                        : useralldata?.GlobalState.newCount}
                    </h2>
                  </div>
                  <div
                    className="bx--col-sm-1 section-column"
                    onClick={closedticket}
                    style={{
                      borderBottom:
                        toggle.closedShow === false
                          ? "1.5px solid #0f62fe "
                          : "",
                      pointerEvents: toggle.ListActive ? "none" : "",
                    }}
                  >
                    <p>CLOSED</p>
                    <h2>
                      {Number(useralldata?.GlobalState.closedCount) < 10
                        ? `0${useralldata?.GlobalState.closedCount}`
                        : useralldata?.GlobalState.closedCount}
                    </h2>
                  </div>
                </div>
              </div>

              <div>
                {toggle.FillterToggle ? (
                  <Form className="form">
                    <div style={{ padding: "1rem" }}>
                      <Select
                        defaultValue="placeholder-item"
                        id="select-1"
                        invalidText="This is an invalid error message."
                        labelText="Support type"
                      >
                        <SelectItem
                          text="Technical Service Request"
                          value="option-1"
                        />
                        <SelectItem
                          text="Customer Service Request"
                          value="option-2"
                        />
                      </Select>
                    </div>
                    <div style={{ marginBottom: "2rem", padding: "0 1rem" }}>
                      <DatePicker
                        dateFormat="m/d/Y"
                        datePickerType="single"
                        onInput={(e) =>
                          dispatch({
                            type: "FILLTER_DATA",
                            payload: {
                              ...useralldata?.Fillter,
                              startDate: e.target.value,
                            },
                          })
                        }
                      >
                        <DatePickerInput
                          id="date-picker-default-id"
                          placeholder="mm/dd/yyyy"
                          labelText="Start date"
                          value={useralldata?.Fillter.startDate}
                          type="text"
                        />
                      </DatePicker>
                      <DatePicker
                        dateFormat="m/d/Y"
                        datePickerType="single"
                        onInput={(e) =>
                          dispatch({
                            type: "FILLTER_DATA",
                            payload: {
                              ...useralldata?.Fillter,
                              endDate: e.target.value,
                            },
                          })
                        }
                      >
                        <DatePickerInput
                          id="date-picker-default-id2"
                          placeholder="mm/dd/yyyy"
                          labelText="End date"
                          value={useralldata?.Fillter.endDate}
                          type="text"
                        />
                      </DatePicker>
                    </div>
                    <ButtonSet>
                      <Button kind="secondary" onClick={ResetFilter}>
                        Reset filters
                      </Button>
                      <Button kind="primary" onClick={Setfilter}>
                        Apply filters
                      </Button>
                    </ButtonSet>
                  </Form>
                ) : null}
              </div>
              <div className="bx--row bx--no-gutter">
                <div className="bx--col">
                  <Search
                    labelText=""
                    light
                    placeHolderText="Search ticket by keyword or ID"
                    onChange={(e) =>
                      dispatch({
                        type: "FILLTER_DATA",
                        payload: {
                          ...useralldata?.Fillter,
                          search: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                {!toggle.ListActive ? (
                  <div>
                    <Button
                      type="reset"
                      disabled={
                        !toggle.openShow &&
                        !toggle.closedShow &&
                        !toggle.pendingShow &&
                        !toggle.newShow
                      }
                      onClick={() => {
                        settoggle({
                          ...toggle,
                          openShow: "",
                          closedShow: "",
                          pendingShow: "",
                          newShow: "",
                        });
                        dispatch({
                          type: "FILLTER_DATA",
                          payload: {
                            ...useralldata?.Fillter,
                            applyFilter: false,
                            startDate: "",
                            endDate: "",
                          },
                        });
                      }}
                      renderIcon={FilterReset}
                    >
                      Reset filters
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="bx--row">
                <Tabs light selectionMode="manual" className="hed">
                  <Tab
                    label={<Grid />}
                    onClick={() => settoggle({ ...toggle, ListActive: false })}
                  >
                    <div className="support-grid-page">
                      <GridModeTab />
                    </div>
                  </Tab>
                  <Tab
                    label={<List />}
                    onClick={() =>
                      settoggle({
                        ...toggle,
                        openShow: "",
                        closedShow: "",
                        newShow: "",
                        pendingShow: "",
                        ListActive: true,
                      })
                    }
                  >
                    <div className="support-list-page">
                      <ListViewTab />
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div
              className="chat-bot-icon"
              onClick={() =>
                settoggle({
                  ...toggle,
                  Support: !toggle.Support,
                  tabselect: 1,
                })
              }
            >
              <Chat />
            </div>
            <div
              className="chat-bot-icon2"
              onClick={() =>
                settoggle({
                  ...toggle,
                  Support: !toggle.Support,
                  tabselect: 1,
                })
              }
            >
              <Chat />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportCenter;
