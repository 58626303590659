import FaqAccordionRenderer from "./FaqAccordionRenderer";
import MarkdownRenderer from "./MarkdownRenderer";
import ProductCardList from "./ProductCardList";
import { useDispatch, useSelector } from "react-redux";

function CopilotSidebarContent() {
  const sidebarContent = useSelector((state) => state.chatbot.sidebarContent);

  switch (sidebarContent?.type) {
    case "faq":
      return <FaqAccordionRenderer />;

    case "markdown":
      return <MarkdownRenderer />;

    default:
      return <ProductCardList />;
  }
}

export default CopilotSidebarContent;
