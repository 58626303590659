import { ArrowRight, ArrowLeft } from "@carbon/icons-react";
import {
  Accordion,
  AccordionItem,
  Column,
  InlineLoading,
  Row,
} from "@carbon/react";
import { OtherresourceData } from "Component/Data/DataJson";
import axiosInstance from "axiosintance";
import CustomButton from "components/button/customButton";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Icon from "components/icon/icon";
import {
  AiTrust,
  Airplane,
  MachineLearning_03,
  MachineLearning_05,
} from "@carbon/pictograms-react";

import {
  getChat,
  setSelectedService,
  setSidebarcontent,
} from "redux/slices/Chatbot/ChatbotSlice";
import useWindowSize from "hooks/useWindowSize";
import { addMessage } from "redux/slices/Chatbot/ChatbotSlice";
import { GlobalContext } from "ContextApi/GlobalContext";

function FaqAccordionRenderer() {
  const sidebarContent = useSelector((state) => state.chatbot.sidebarContent);
  // const otherService = useSelector((state) => state.chatbot.otherServices);
  // const otherProduct = useSelector((state) => state.chatbot.otherProducts);
  const otherResources = useSelector((state) => state.chatbot.otherResources);
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const context = useContext(GlobalContext);
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const getStyles = (index) => {
    return index == activeCardIndex ? { ...activeRowStyles } : {};
  };
  const activeRowStyles = {
    backgroundColor: context.globalTheme == "white" ? "#e0e0e0" : "#474747", // Light grey background for the active row
    border: "2px solid #0F62FE ",
  };

  function handleServiceClick(value) {
    dispatch(
      getChat({
        text: value.resources_heading.trim(),
        original_text:
          value.resources_heading.trim().replace("?", "") +
          " " +
          sidebarContent?.service,
      })
    );

    dispatch(setSelectedService(value));
  }

  const renderPictogram = (pictogramName) => {
    switch (pictogramName) {
      case "MachineLearning_03":
        return (
          <MachineLearning_03
            aria-label={pictogramName}
            className="pictograms"
          />
        );

      case "AiTrust":
        return <AiTrust aria-label={pictogramName} className="pictograms" />;

      case "MachineLearning_05":
        return (
          <MachineLearning_05
            aria-label={pictogramName}
            className="pictograms"
          />
        );
      default:
        return null; // Return null for unrecognized pictograms
    }
  };

  return (
    <div className="single_faqPage">
      <div className="main_header flex justify-between">
        <a
          href="#"
          className="back w-max flex gap-3"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setSidebarcontent(null));
            dispatch(setSelectedService(null));
          }}
        >
          <ArrowLeft size={20} />
          Back
        </a>
        {width > 768 && (
          <Link
            to="/training/Training-For-admins"
            className="back w-max flex gap-3"
          >
            View Training For Admins
            <ArrowRight size={20} />
          </Link>
        )}
      </div>

      {width > 768 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "16px",
          }}
        >
          <div className="faq_mainheading">
            <div className="faq_singleicon">
              <img
                style={{ width: "20px", height: "20px" }}
                src={sidebarContent?.serviceImage}
                alt="google"
              />
            </div>
            <h5 className="dark:!text-white">{sidebarContent?.service} FAQ</h5>
          </div>
        </div>
      )}

      {width <= 768 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "flex-start",
            paddingBottom: "16px",
          }}
        >
          <div className="faq_mainheading">
            <div className="faq_singleicon">
              <img src={sidebarContent?.serviceImage} alt="google" />
            </div>
            <div>
              <h5 className="dark:!text-white" style={{ paddingBottom: "4px" }}>
                {sidebarContent?.service || ""} FAQ
              </h5>
              <Link
                to="/training/Training-For-admins"
                className="back w-max flex gap-3"
              >
                View Training For Admins
                <ArrowRight size={20} />
              </Link>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          maxHeight: "464px",
          overflowY: "scroll",
          backgroundColor: context.globalTheme == "white" ? "white" : "#262626",
        }}
      >
        <Accordion>
          {sidebarContent?.content.map((faq) => (
            <CustomAccordionItem key={faq.key} data={faq} />
          ))}
        </Accordion>
      </div>

      <div className="other_resources">
        <div className="aidata_faq">
          <div className="Product_section">
            <div style={{ marginBottom: "16px", paddingTop: "80px" }}>
              <h6 className="dark:!text-white">
                {sidebarContent?.service} Other Resources
              </h6>
            </div>

            <div className="card-container">
              {otherResources?.length > 0
                ? otherResources?.map((value, index) => {
                    return (
                      // <button
                      //   style={getStyles(index)}
                      //   className="single_produccolumn"
                      //   role="button"
                      //   onClick={(e) => {
                      //     handleServiceClick(value);
                      //     setActiveCardIndex(index);
                      //   }}
                      // >
                      //   <Column>
                      //     <div className="single_produccolumn">
                      //       <div className="single_producticon">
                      //         <div className="single_icon_others">
                      //           {renderPictogram(value?.resources_icon)}
                      //         </div>
                      //         <div
                      //           style={{
                      //             display: "flex",
                      //             flexDirection: "column",
                      //             gap: "8px",
                      //           }}
                      //         >
                      //           <h5 className="dark:!text-white">
                      //             {value?.resources_heading}
                      //           </h5>
                      //           <p className="dark:!text-white">
                      //             {value?.resources_desc.split(" ").length > 15
                      //               ? value?.resources_desc
                      //                   ?.split(" ")
                      //                   .slice(0, 13)
                      //                   .join(" ") + "..."
                      //               : value?.resources_desc}
                      //           </p>
                      //         </div>
                      //       </div>
                      //     </div>
                      //   </Column>
                      // </button>

                      <button
                        className={
                          index == activeCardIndex
                            ? " active"
                            : "single_produccolumn"
                        }
                        role="button"
                        onClick={(e) => {
                          handleServiceClick(value);
                          setActiveCardIndex(index);
                        }}
                      >
                        <div className="single_producticon dark:!border-[#474747]">
                          <div className="single_icon_others">
                            {renderPictogram(value?.resources_icon)}
                          </div>
                          <Column
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                              alignItems: "start",
                            }}
                          >
                            <h5 className="dark:!text-white !text-left">
                              {value?.resources_heading} FAQ
                            </h5>
                            <p className="dark:!text-[#525252]">
                              {value?.resources_desc.split(" ").length > 15
                                ? value?.resources_desc
                                    ?.split(" ")
                                    .slice(0, 13)
                                    .join(" ") + "..."
                                : value?.resources_desc}
                            </p>
                          </Column>
                        </div>
                      </button>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CustomAccordionItem({ data, key }) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState({ loading: false, data: null });

  // useEffect(() => {
  //   if (!open || query.loading || query.data) return;

  //   setQuery({ loading: true, data: null });

  //   axiosInstance
  //     .post("https://devapi.uvation.com/chatbot/chatbot/chat", {
  //       message: data.original_text,
  //     })
  //     .then((res) => {
  //       setQuery({ loading: false, data: res.data });
  //     })
  //     .catch((err) => {
  //       setQuery({ loading: false, data: "something went wrong" });
  //     });
  // }, [open, query]);

  // const key = useMemo(() => {
  //   if (query.loading) return "loading";
  //   return "with-data";
  // }, [query]);

  return (
    <AccordionItem
      title={data.original_text.question}
      open={open}
      key={key}
      onHeadingClick={() => setOpen(!open)}
    >
      <div
        className="dark:!text-white"
        dangerouslySetInnerHTML={{
          __html: data?.original_text?.answer.replace(/\n/g, "<br>") || "",
        }}
      />

      {/* <p className="dark:!text-white">
        {query.loading && (
          <InlineLoading
            status="active"
            iconDescription="Loading"
            description="Getting data from AI..."
          />
        )}

        {query.data && (
          <span
            dangerouslySetInnerHTML={{
              __html: `<span>${query.data.botReply
                .replaceAll("\\n", "<br>")
                .trim()}</span>`,
            }}
          />
        )}
      </p> */}
    </AccordionItem>
  );
}

export default FaqAccordionRenderer;
