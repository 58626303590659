import React from "react";
import { CheckmarkFilled, Close } from "@carbon/icons-react";
import { Modal } from "@carbon/react";
import { FullViewModalWrapper } from "./style.js";
import { useMemo, useReducer } from "react";
import useWindowSize from "hooks/useWindowSize.js";
import { useSelector } from "react-redux";
import {
  handleCreateTicketTab,
  headerState,
  handleSupportTabIndex,
} from "redux/slices/headerSlice/index.js";
import { useDispatch } from "react-redux";

const TicketCreateModalForFullView = ({ data, isOpen, onClose }) => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  if (!isOpen || !data) return null;

  const handleClose = () => {
    onClose(false);
    dispatch(handleCreateTicketTab(false));
    dispatch(handleSupportTabIndex(1));
  };

  return (
    <FullViewModalWrapper>
      {/* <Modal className="successmodal" open={isOpen} passiveModal size="sm">
      <div className="close_icon" onClick={() => handleClose()}>
          <Close size={20} color="black" />
        </div>

        <div className="header_modal">
          <CheckmarkFilled size={32} />
          <h4>Request Completed!</h4>
        </div>

        <div>
          <div className="sub_heading">
            {width <= 425 ? (
              <h4>
                Your <b className="font-semibold">Request # {data.id}</b> has
                been forwarded to <br />
                our customer services team and has been added to the list of
                open requests.
              </h4>
            ) : (
              <h4>
                Your <b className="font-semibold">Request # {data.id}</b> has
                been forwarded to our customer services team and has been added
                to <br /> the list of open requests.
              </h4>
            )}
          </div>
        </div>
      </Modal> */}

      <Modal className="successmodal" open={isOpen} passiveModal size="sm">
        <div className="close_icon" onClick={() => handleClose()}>
          <Close size={20} color="black" />
        </div>

        <div className="header_modal">
          <CheckmarkFilled size={32} />
          <h4>Request Completed!</h4>
        </div>

        <div style={{ padding: "16px" }}>
          <div className="sub_heading">
            {width <= 425 ? (
              <h4>
                Your <b className="font-semibold ">Request # {data.id}</b>
                {"  "}
                has been forwarded to <br />
                our customer services team and has been added to the list of
                open requests.
              </h4>
            ) : (
              <h4>
                Your <b className="font-semibold">Request # {data.id}</b> has
                been forwarded to our customer services team and has been added
                to <br /> the list of open requests.
              </h4>
            )}
          </div>
        </div>
      </Modal>
    </FullViewModalWrapper>
  );
};

export default TicketCreateModalForFullView;
