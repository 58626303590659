import styled from "styled-components";

const HeaderWrapper = styled.section`
  .cds--header__action--active {
    background-color: #000;
  }
  .cds--header__menu-bar {
    display: flex;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  svg {
    fill: white !important;
  }

  .mani_div {
    width: 100%;

    @media screen and (max-width: 1920px) {
      .main_grid {
        padding-left: 16px;
      }
    }
  }
  header {
    @media screen and (max-width: 767px) {
      padding: 0;
      .logo {
        margin-left: 1rem;
      }
    }
    .logo {
      display: flex;
      align-items: center;
      gap: 20px;
      a {
        display: flex;
        align-items: center;
        color: unset;
        gap: 8px;
      }
      .cds--header__menu-bar {
        gap: 2rem;
        @media screen and (max-width: 1699px) {
          gap: 0;
        }
        a {
          font-family: IBM Plex Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.1599999964237213px;
          text-align: left;
        }
      }
    }
    .user {
      width: 100%;
      gap: 8px;
      padding: 0 1rem;
      span {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.1599999964237213px;
        text-align: left;
      }
    }
  }
  span.notification_count {
    background: #e5141e;
    border-radius: 100%;
    height: 19px;
    width: 19px;
    padding: 2px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    margin-bottom: 1rem;
  }

  .navbaridentity {
    display: block;
    height: 3rem;

    .cds--header__menu-item {
      border: none !important;
    }

    .cds--header__menu-item:focus,
    .cds--header__menu-item:focus-visible {
      border-color: none !important;
    }

    a {
      color: #fff;
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
    }
  }

  .serach_list {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        border: 1px solid #ddd;
        margin-top: -1px; /* Prevent double borders */
        background-color: #fff;
        padding: 12px;
        text-decoration: none;
        font-size: 18px;
        color: black;
        display: block;

        &:hover {
          background-color: #e4e4e4;
        }
        .product-detail-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  .header_button {
    text-decoration: none !important;
    color: unset;
  }

  .relative {
    .iconmotion {
      animation: ring 4s 0.7s ease-in-out infinite;
      transform-origin: 50% 4px;
    }

    @keyframes ring {
      0% {
        transform: rotate(0);
      }
      1% {
        .relative {
          .iconmotion {
            animation: ring 4s 0.7s ease-in-out infinite;
            transform-origin: 50% 4px;
          }

          @keyframes ring {
            0% {
              transform: rotate(0);
            }
            1% {
              transform: rotate(30deg);
            }
            3% {
              transform: rotate(-28deg);
            }
            5% {
              transform: rotate(34deg);
            }
            7% {
              transform: rotate(-32deg);
            }
            9% {
              transform: rotate(30deg);
            }
            11% {
              transform: rotate(-28deg);
            }
            13% {
              transform: rotate(26deg);
            }
            15% {
              transform: rotate(-24deg);
            }
            17% {
              transform: rotate(22deg);
            }
            19% {
              transform: rotate(-20deg);
            }
            21% {
              transform: rotate(18deg);
            }
            23% {
              transform: rotate(-16deg);
            }
            25% {
              transform: rotate(14deg);
            }
            27% {
              transform: rotate(-12deg);
            }
            29% {
              transform: rotate(10deg);
            }
            31% {
              transform: rotate(-8deg);
            }
            33% {
              transform: rotate(6deg);
            }
            35% {
              transform: rotate(-4deg);
            }
            37% {
              transform: rotate(2deg);
            }
            39% {
              transform: rotate(-1deg);
            }
            41% {
              transform: rotate(1deg);
            }

            43% {
              transform: rotate(0);
            }
            100% {
              transform: rotate(0);
            }
          }
        }
        transform: rotate(30deg);
      }
      3% {
        transform: rotate(-28deg);
      }
      5% {
        transform: rotate(34deg);
      }
      7% {
        transform: rotate(-32deg);
      }
      9% {
        transform: rotate(30deg);
      }
      11% {
        transform: rotate(-28deg);
      }
      13% {
        transform: rotate(26deg);
      }
      15% {
        transform: rotate(-24deg);
      }
      17% {
        transform: rotate(22deg);
      }
      19% {
        transform: rotate(-20deg);
      }
      21% {
        transform: rotate(18deg);
      }
      23% {
        transform: rotate(-16deg);
      }
      25% {
        transform: rotate(14deg);
      }
      27% {
        transform: rotate(-12deg);
      }
      29% {
        transform: rotate(10deg);
      }
      31% {
        transform: rotate(-8deg);
      }
      33% {
        transform: rotate(6deg);
      }
      35% {
        transform: rotate(-4deg);
      }
      37% {
        transform: rotate(2deg);
      }
      39% {
        transform: rotate(-1deg);
      }
      41% {
        transform: rotate(1deg);
      }

      43% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(0);
      }
    }
  }
`;

export default HeaderWrapper;
