import { Box } from "@twilio-paste/core/box";
// import { Button } from "@twilio-paste/core/button";
import { InputBox } from "@twilio-paste/core/input-box";
import { TextArea } from "@twilio-paste/core/textarea";
import { SendIcon } from "@twilio-paste/icons/esm/SendIcon";
import throttle from "lodash.throttle";
import { memoize } from "proxy-memoize";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detachFiles } from "redux/reducers/chat.reducer";
import { CHAR_LIMIT } from "utils/constants";
import { useSanitizer } from "utils/useSanitizer";
import { AttachFileButton } from "./AttachFileButton";
import { FilePreview } from "./FilePreview";
import {
  filePreviewContainerStyles,
  formStyles,
  innerInputStyles,
  messageOptionContainerStyles,
  textAreaContainerStyles,
} from "./styles/MessageInput.styles";
import { IconButton } from "@carbon/react";

const selector = memoize((state) => ({
  conversation: state.chat.conversation,
  attachedFiles: state.chat.attachedFiles || [],
  fileAttachmentConfig: state.config.fileAttachment,
}));

export const MessageInput = () => {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { conversation, attachedFiles, fileAttachmentConfig } =
    useSelector(selector);
  const oldAttachmentsLength = useRef((attachedFiles || []).length);
  const textAreaRef = useRef(null);
  const attachmentsBoxRef = useRef(null);

  const throttleChange = useMemo(
    () =>
      throttle(() => {
        conversation?.typing();

        // in case the input was already focused, let's make sure to send the `read` status if the customer is typing
        if (
          conversation?.lastReadMessageIndex !==
          conversation?.lastMessage?.index
        ) {
          conversation?.setAllMessagesRead();
        }
      }, 500),
    [conversation]
  );
  const { onUserInputSubmit } = useSanitizer();

  const logger = window.Twilio.getLogger("MessageInput");
  const isSubmitDisabled =
    (!text.trim() && !attachedFiles?.length) || isSending;

  const send = async () => {
    if (isSubmitDisabled) {
      return;
    }
    if (!conversation) {
      logger.error("Failed sending message: no conversation found");
      return;
    }
    setIsSending(true);

    let preparedMessage = conversation.prepareMessage();
    preparedMessage = preparedMessage.setBody(onUserInputSubmit(text));
    attachedFiles.forEach((file) => {
      const formData = new FormData();
      formData.append(file.name, file);
      preparedMessage.addMedia(formData);
    });
    await preparedMessage.build().send();
    setText("");
    dispatch(detachFiles(attachedFiles));
    setIsSending(false);
    textAreaRef.current?.focus();
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      e.target.form?.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  const onChange = (val) => {
    setText(val.target.value);

    throttleChange();
  };

  const onFocus = () => {
    conversation?.setAllMessagesRead();
  };

  useEffect(() => {
    textAreaRef.current?.setAttribute("rows", "1");
    textAreaRef.current?.focus();
  }, [textAreaRef]);

  // Ensuring attached files are automatically scrolled to
  useEffect(() => {
    if (!attachmentsBoxRef.current) {
      return;
    }

    if (attachedFiles.length > oldAttachmentsLength.current) {
      attachmentsBoxRef.current.lastChild?.scrollIntoView();
    }

    oldAttachmentsLength.current = attachedFiles.length;
  }, [attachedFiles]);

  return (
    <Box
      as="form"
      {...formStyles}
      onSubmit={(e) => {
        e.preventDefault();
        send();
      }}
    >
      <InputBox element="MESSAGE_INPUT_BOX" disabled={isSending}>
        <Box as="div" {...innerInputStyles}>
          <Box {...textAreaContainerStyles}>
            <TextArea
              ref={textAreaRef}
              data-test="message-input-textarea"
              placeholder="Type your message"
              value={text}
              element="MESSAGE_INPUT"
              onChange={onChange}
              onFocus={onFocus}
              readOnly={isSending}
              onKeyPress={onKeyPress}
              maxLength={CHAR_LIMIT}
            />
          </Box>

          <Box {...messageOptionContainerStyles}>
            {fileAttachmentConfig?.enabled && (
              <AttachFileButton textAreaRef={textAreaRef} />
            )}
          </Box>

          <Box {...messageOptionContainerStyles}>
            <IconButton
              className="flex justify-center items-center !p-0"
              label="Send"
              size="sm"
              type="submit"
              aria-disabled={isSubmitDisabled}
            >
              <SendIcon
                decorative={false}
                title="Send message"
                size="sizeIcon30"
              />
            </IconButton>
          </Box>
        </Box>

        {attachedFiles && (
          <Box
            data-test="message-attachments"
            {...filePreviewContainerStyles}
            ref={attachmentsBoxRef}
          >
            {attachedFiles.map((file, index) => (
              <FilePreview
                focusable={true}
                key={index}
                file={file}
                isBubble={false}
                disabled={isSending}
              />
            ))}
          </Box>
        )}
      </InputBox>
    </Box>
  );
};
