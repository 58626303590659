import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import axiosInstance from "axiosintance";
import { getErrorMessageAndCode } from "getErrorFromResponse";

export const getAllticket = async () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  let email = msalInstance.getAllAccounts()[0]?.idTokenClaims?.email;
  try {
    const Alltickets = new FormData();
    Alltickets.append("email", email);

    let { data } = await axiosInstance.post(
      `${process.env.REACT_APP_BACKENDURL_SUPPORT}/ticket_status`,
      Alltickets
    );
    return data;
  } catch (err) {
    let error = getErrorMessageAndCode(err);
    throw error;
  }
};
