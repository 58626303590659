import axiosInstance from "axiosintance";

const fetch_All_Data = async (azureId) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_BACKEND_URL}/getuser_info?azureId=${azureId}`
  );
  return response?.data;
};

export default {
  fetch_All_Data,
};
