const { default: styled } = require("styled-components");

const SwitcherWrapper = styled.div`
  background-color: #000;
  margin-top: 16px;

  a.cds--side-nav__link {
    height: 32px !important;
  }

  .cds--side-nav__item {
    color: black;
    display: flex;
    flex-direction: column;

    a {
      span {
        color: white;
      }
    }
  }

  .cds--side-nav__submenu-title {
    color: white;
  }

  .cds--side-nav__submenu {
    color: white;
    .cds--side-nav__icon {
      svg {
        width: 20px;
        height: 20px;
        fill: white;
        margin-right: -8px;
      }
    }
  }

  .cds--side-nav__menu {
    .cds--side-nav__item {
      .cds--side-nav__link {
        .cds--side-nav__link-text {
          margin-left: -16px;
          color: var(--Text-text-inverse, #fff);
          font-family: IBM Plex Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0.16px;
        }
      }
    }
  }

  .heading {
    padding: 0.8rem 1rem 0rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon_box {
      width: 50px;
      height: 50px;
      background-color: #c4c4c4;
      display: grid;
      place-items: center;
      border-radius: 50%;
      color: white;

      svg {
        color: #474747;
      }
    }
    .middle {
      padding: 0rem;
      h3 {
        color: var(--white, #fff);
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
        margin-bottom: 10px;
      }

      .account_Id {
        color: white;
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.16px;
      }

      .copy,
      svg {
        display: flex;
        align-items: center;
        gap: 1rem;
        fill: rgb(119, 166, 255);
        color: rgb(119, 166, 255);
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background-color: #000;
        /* margin-top: 5px; */
        button {
          width: max-content;
          height: max-content;
          max-width: max-content;
          max-height: max-content;
          min-height: max-content;
          min-width: max-content;
        }
      }
    }
    .icon_btn {
      svg {
        color: #fff;
      }
    }
  }
`;
export default SwitcherWrapper;
