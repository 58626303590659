import { IconButton } from "@carbon/react";
import { AttachIcon } from "@twilio-paste/icons/esm/AttachIcon";
import { memoize } from "proxy-memoize";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { attachFiles } from "redux/reducers/chat.reducer";
import { validateFiles } from "utils/validateFiles";
import { hiddenInputStyles } from "./styles/AttachFileButton.styles";

const selector = memoize((state) => ({
  attachedFiles: state.chat.attachedFiles || [],
  fileAttachmentConfig: state.config.fileAttachment,
}));

export const AttachFileButton = ({ textAreaRef }) => {
  const fileInputRef = useRef(null);
  const { attachedFiles, fileAttachmentConfig } = useSelector(selector);

  const dispatch = useDispatch();

  const onFileChange = (event) => {
    const selectedFiles = event.target.files && Array.from(event.target.files);
    if (selectedFiles) {
      const validFiles = validateFiles(
        selectedFiles,
        dispatch,
        attachedFiles,
        fileAttachmentConfig
      );
      dispatch(attachFiles(validFiles));
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    textAreaRef?.current?.focus();
  };

  return (
    <IconButton
      className="!p-0 flex justify-center items-center"
      kind="ghost"
      label="Add Attachment"
      size="sm"
      onClick={() => fileInputRef.current?.click()}
    >
      <input
        style={hiddenInputStyles}
        onChange={onFileChange}
        type="file"
        accept={
          fileAttachmentConfig?.acceptedExtensions &&
          fileAttachmentConfig.acceptedExtensions.map((e) => `.${e}`).join(",")
        }
        ref={fileInputRef}
        multiple
      />
      <AttachIcon decorative={false} title="Add file attachment" />
    </IconButton>
  );
};
