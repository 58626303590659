import { ArrowLeft } from "@carbon/icons-react";
import { Column } from "@carbon/react";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedService,
  setSidebarcontent,
} from "redux/slices/Chatbot/ChatbotSlice";

function MarkdownRenderer() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.chatbot.sidebarContent);

  const service = useSelector((state) => state.chatbot.selectedService);

  let contentTemp = state.content;

  let content = { __html: contentTemp };

  return (
    <div className="help_data">
      <div className="main_header">
        <a
          href="#"
          className="back w-max"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setSidebarcontent(null));
            dispatch(setSelectedService(null));
          }}
        >
          <ArrowLeft size={20} />
          Back
        </a>

        <h5>{service?.heading || ""}</h5>
      </div>
      <hr />
      <div dangerouslySetInnerHTML={content} className="html_markdown" />
    </div>
  );
}

export default MarkdownRenderer;
