import Home from "pages/home";
import Request from "pages/request";
import Status from "pages/status";
import Training from "pages/training";
import TrainingSingle from "pages/training/trainingSingle";
import TicketInfo from "Component/TicketDetails/TicketInfo";
import Plan from "pages/plans";
import NotificationMessage from "Component/Notification&Message/Notificatio&Message";
import CreteTicket from "components/RequestTicket";
import TrainingForAdmins from "pages/training/TrainingForAdmins";

import {
  CREATTICKET,
  DETAILS,
  FAQGUIDE,
  TRAINING_KNOWLEDGE_BASE,
  NOTIFICATION,
  PLAN,
  REQUEST,
  ROOT,
  STATUS,
  TRAININFORADMINS,
  TRAINING,
  TRAINING_INSIDER,
} from "./pathnames";
import Faqguide from "pages/training/KnowledgBase";

export const privateRoutes = [
  {
    id: 1,
    path: ROOT,
    component: Home,
  },
  {
    id: 2,
    path: REQUEST,
    component: Request,
  },
  {
    id: 3,
    path: STATUS,
    component: Status,
  },
  {
    id: 4,
    path: TRAINING,
    component: Training,
  },
  {
    id: 5,
    path: TRAINING_INSIDER,
    component: TrainingSingle,
  },
  {
    id: 6,
    path: PLAN,
    component: Plan,
  },
  {
    id: 7,
    path: DETAILS,
    component: TicketInfo,
  },
  {
    id: 8,
    path: NOTIFICATION,
    component: NotificationMessage,
  },
  {
    id: 9,
    path: CREATTICKET,
    component: CreteTicket,
  },
  {
    id: 10,
    path: TRAININFORADMINS,
    component: TrainingForAdmins,
  },
  {
    id: 11,
    path: FAQGUIDE,
    component: Faqguide,
  },
  {
    id: 12,
    path: TRAINING_KNOWLEDGE_BASE,
    component: TrainingForAdmins,
  },
];
