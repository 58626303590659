import { Client } from "@twilio/conversations";
import { MESSAGES_LOAD_COUNT } from "utils/constants";
import { notifications } from "utils/notifications";
import {
  changeEngagementPhase,
  initializeClientListeners,
  initializeConversationListener,
  initializeMessagesListener,
  initializeParticipantsListener,
  startSession,
} from "../chat.reducer";
import { EngagementPhase } from "../definitions";
import { addNotification } from "../notification.reducer";

export function initSession({ token, conversationSid }) {
  const logger = window.Twilio.getLogger("initSession");
  return async (dispatch) => {
    let conversationsClient;
    let conversation;
    let participants;
    let users;
    let messages;

    try {
      conversationsClient = new Client(token);
      try {
        conversation = await conversationsClient.getConversationBySid(
          conversationSid
        );
      } catch (e) {
        dispatch(
          addNotification(
            notifications.failedToInitSessionNotification(
              "Couldn't load conversation"
            )
          )
        );
        dispatch(
          changeEngagementPhase({
            phase: EngagementPhase.PreEngagementForm,
          })
        );
        return;
      }

      participants = await conversation.getParticipants();
      users = await Promise.all(participants.map(async (p) => p.getUser()));
      messages = (await conversation.getMessages(MESSAGES_LOAD_COUNT)).items;
    } catch (e) {
      logger.error("Something went wrong when initializing session", e);
      throw e;
    }
    dispatch(
      startSession({
        token,
        conversationSid,
        conversationsClient,
        conversation,
        users,
        participants,
        messages,
        conversationState: conversation.state?.current,
        currentPhase: EngagementPhase.MessagingCanvas,
      })
    );

    dispatch(initializeClientListeners(conversationsClient));
    dispatch(initializeConversationListener(conversation));
    dispatch(initializeMessagesListener(conversation));
    dispatch(initializeParticipantsListener(conversation));
  };
}
